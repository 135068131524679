import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, useTheme, useMediaQuery, Typography } from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useDispatch, useSelector } from 'react-redux'

import BackDropLoader from '../../components/ui/BackdropLoader'
import PageHeading from '../../components/ui/PageHeading'
import ChartHeading from '../../components/ui/ChartHeading'
import ContentContainer from '../../components/ui/ContentContainer'
import CustomButton from '../../components/ui/CustomButton'
import DataWidget from '../../components/ui/DataWidget'

import { LineChart, PieChart, Toggle, Dropdown, PatientList, InvoiceDetail } from '../../features/admin'

import { 
  fetchAdminDashboardGraphsData, 
  fetchAdminDashboardStatsData, 
  getAdminDashboardDataStatus, 
  getGraphDataStatus, 
  getStatusDataStatus, 
  selectAdminDashboardGraphsData, 
  selectAdminDashboardStatsData 
} from '../../redux/slices/adminDashboardSlice'
import { getUserStatus } from '../../redux/slices/authSlice'
import YearSelect from '../../components/ui/YearSelect';

const COLORS = ["#2B7F75", "#191F2F", "#ACB5BB"];

const Dashboard = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('down'))
  const dispatch = useDispatch()

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  
  const userStatus = useSelector(getUserStatus)

  const dashboardStatsData = useSelector(selectAdminDashboardStatsData)
  const dashboardStatsDataStatus = useSelector(getStatusDataStatus)

  const dashboardGraphData = useSelector(selectAdminDashboardGraphsData)
  const dashboardGraphDataStatus = useSelector(getGraphDataStatus)

  useEffect(() => {
    if (dashboardStatsDataStatus !== "loading") 
        dispatch(fetchAdminDashboardStatsData())
    if (dashboardGraphDataStatus !== "loading")
        dispatch(fetchAdminDashboardGraphsData({selectedYear}))
  }, [dispatch, selectedYear])

  const handleYearChange = (year) => {
    setSelectedYear(year);
    // dispatch(fetchAdminDashboardGraphsData({selectedYear}))
  };

  return (
    <>
      { (userStatus === 'loading')
        ? <BackDropLoader /> 
        : (
          <Container maxWidth="xl">
            {/* Top Header */}
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <PageHeading heading="Overview" text="Overall details of the platform" />
            </Box>

            {/* Export and Dropdown */}
            {/* <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
              <Dropdown />
              <CustomButton title="Export" />
            </Box> */}

            {/* Cards */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={3}>
                <DataWidget 
                title="Total Patients" 
                value1={dashboardStatsData?.totalPatients || 0}
                value2={dashboardStatsData?.totalPatientsSinceLastWeek || 0}
                value3='Added last week' 
                icon={<GroupIcon />} 
                color={theme.palette.primary.light} />
              </Grid>
              <Grid item xs={12} md={3}>
                <DataWidget 
                title="Total Doctors" 
                value1={dashboardStatsData?.totalDoctors || 0}
                value2={dashboardStatsData?.totalActiveDoctors || 0}
                value3='Active' 
                icon={<MedicalInformationIcon />} 
                color={theme.palette.custom.lightBlue.main}/>
              </Grid>
              <Grid item xs={12} md={3}>
                <DataWidget 
                title="Total Coordinators" 
                value1={dashboardStatsData?.totalCoordinators || 0}
                value2={dashboardStatsData?.totalActiveCoordinators || 0}
                value3='Active' 
                icon={<ContactMailIcon />} 
                color={theme.palette.custom.yellow.main}/>
              </Grid>
              <Grid item xs={12} md={3}>
                <DataWidget 
                title="Total Hospitals" 
                value1={dashboardStatsData?.totalHospitals || 0}
                value2={dashboardStatsData?.totalClinics || 0}
                value3='Total Clinics' 
                icon={<ApartmentIcon />} 
                color={theme.palette.custom.lightBlue.main}/>
              </Grid>
            </Grid>


          {/* Charts */}
            <Box sx={{marginTop: 5}}>
              <ContentContainer padding={2}>
              <Grid container spacing={isMd ? 2 : 8}>
                <Grid item xs={12} md={6}>
                  <Box sx={{display: 'flex', flexDirection: 'column', height: 300}}>
                    <Box sx={{ mb: 3 }} display="flex">
                      <ChartHeading text='Patients Added per Month' />
                      {isMd
                        ?
                          <Box display="flex" justifyContent="flex-end">
                              <YearSelect 
                                selectedYear={selectedYear} 
                                onYearChange={handleYearChange}
                                disabled={(dashboardGraphDataStatus === "loading")}
                              />
                          </Box>
                        : null
                      }
                    </Box>
                    <LineChart data={dashboardGraphData?.patientsVisitData} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                      <Box sx={{display: 'flex', flexDirection: 'column', height: 300}}>
                          <Box 
                            sx={{ mb:2, ml: isMd ? 1.5 : 6, mt: isMd ? 2 : 0 }}
                            display="flex" 
                            justifyContent="space-between"
                          > 
                            <ChartHeading text='Total Patients in Clinics' />
                          </Box>
                          {dashboardGraphData?.patientsInClinic?.length === 0
                            ? (
                              <Box mt={4}>
                                <Typography textAlign="end">No data available.</Typography>
                              </Box>
                            ): (
                                  <PieChart data={dashboardGraphData?.patientsInClinic} COLORS={COLORS} />
                                )
                          }
                      </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  {!isMd
                    ?
                      <Box display="flex" justifyContent="flex-end">
                          <YearSelect 
                            selectedYear={selectedYear} 
                            onYearChange={handleYearChange}
                            disabled={(dashboardGraphDataStatus === "loading")}
                          />
                      </Box>
                    : null
                  }
                  <Box sx={{ml: 0}} >
                    <InvoiceDetail data={dashboardGraphData?.patientsInClinic} COLORS={COLORS} />
                  </Box>
                </Grid>
              </Grid>
              </ContentContainer>
            </Box>
          </Container>
        )
      }
    </>
  )
}

export default Dashboard
