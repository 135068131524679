import { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'

import FadeInAnimation from '../../components/layout/FadeInAnimation'
import FormContainer from '../../components/ui/FormContainer'
import Password from '../../components/ui/Password'
import { ICONS, IMAGES } from '../../assets'
import BannerBackground from '../../components/layout/BannerBackground'
import Footer from '../../components/layout/Footer'

import getRoleBasedRedirect from '../../libs/getRoleBasedRedirect'
import { getUserError, getUserStatus, loginUser, selectUser } from '../../redux/slices/authSlice';
import { fetchHospitals, selectAllHospitals } from '../../redux/slices/hospitalSlice';
import { fetchClinics, selectAllClinics } from '../../redux/slices/clinicSlice';

const initialValues = {
    username: '',
    password: '',
    hospital: '',
    clinic: '',

    // Dummy data
    // username: 'coordinator',
    // password: '123456',
    // hospital: '',
    // clinic: '',
}

const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    name: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    hospital: Yup.string().required('Hospital is required'),
    clinic: Yup.string().required('Clinic is required'),
})

const UserLogin = () => {
    const theme = useTheme() 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector(selectUser)
    const userStatus = useSelector(getUserStatus)
    const userError = useSelector(getUserError)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    
     useEffect(() => {
        if(user) navigate(getRoleBasedRedirect(user?.roles))
    }, [user])

    useEffect(() => {
        if(userError)
            toast.error(userError);
    }, [userError])

    const submitHandler = async (e) => {
        e.preventDefault()
        dispatch(loginUser({ username, password }))

    }

    return (
        <>
            <BannerBackground>
                <FormContainer>
                    <Box display="flex">
                        <img 
                            style={{
                                height: 42,
                                width: 42
                            }}
                            alt="Logo"
                            src={ICONS.icon}
                            loading='lazy'
                        />
                    </Box>
                    <Typography 
                        color="secondary"
                        variant="h5"
                        fontWeight="bold"
                        mt={2}
                        mb={1} 
                    >
                        Welcome Back
                    </Typography>
                    <Typography color="secondary.light">
                        Please login using your credentials
                    </Typography>

                    <Box component="form" onSubmit={submitHandler} mt={1}>
                        <TextField
                            sx={{
                                marginTop: 2.5,
                                marginBottom: 2.5
                            }}
                            label="Username"
                            placeholder="johndoe"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Password password={password} setPassword={setPassword} />
                        <Button
                            sx={{
                                marginTop: 4,
                                height: "48px",
                                textTransform: "capitalize",
                                fontSize: "16px",
                                borderRadius: 2,
                                boxShadow: 0
                            }}
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={userStatus === 'loading'}
                        >
                            { userStatus === 'loading' ? "Loading..." : "Login" }
                        </Button>
                    </Box>
                </FormContainer>
            </BannerBackground>
            <ToastContainer />
            <Footer />
        </>
    )
}

export default UserLogin