import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Box, Button, Container, IconButton, Typography, useTheme  } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { ToastContainer } from 'react-toastify'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'

import { fetchHospitals, getHospitalsError, getHospitalsStatus, selectAllHospitals } from '../../../redux/slices/hospitalSlice'
import { DeleteHospitalModal } from '../../../features/admin'
import { fetchClinics } from '../../../redux/slices/clinicSlice'


const HospitalsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState([])
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)


   const hospitals = useSelector(selectAllHospitals)
   const hospitalsStatus = useSelector(getHospitalsStatus)
   const hospitalsError = useSelector(getHospitalsError)
  
  useEffect(() => {
    if(hospitalsStatus !== "loading" && hospitals?.length === 0) {
      dispatch(fetchHospitals())
      dispatch(fetchClinics())
    }
  }, [])

  const onDeleteHandler = (id) => {
    setDeleteItemId(id)
    setOpenDeleteModal(true)
 }

 const closeModal = () => {
    setOpenDeleteModal(false)
    setDeleteItemId(null)
  }

  const getStatusType = (hospitalStatus) => {
      return hospitalStatus === "active" ? "success" : "disabled"
  }


  const columns = useMemo(
    () => [
    {
      field: 'no',
      headerName: 'No',
      maxWidth: 80,
      valueGetter: (params) => params.row.id
    },
    {
      field: 'hospitalId',
      headerName: 'ID',
      maxWidth: 100,
      valueGetter: (params) => "H-" + params.row.id
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap={1.5}>
            <Avatar 
              sx={{ width: 28, height: 28}}
             src={params.row.avatar}
             alt=""
            />
            <Typography fontWeight="bold">
              {params.row.name}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'clinicCount',
      headerName: 'Clinics',
      minWidth: 20,
      valueGetter: (params) => params.row.clinicCount
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) =>  {
        return (
          <Box display="flex" gap={0.4}>
            <Typography variant="body2" fontWeight="bold">{params.row?.address[0]?.street ? (params.row.address[0]?.street + ",") :  ""}</Typography>
            <Typography variant="body2" fontWeight="bold">{params.row?.address[0]?.city}</Typography>
          </Box>
        )
      }
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: false,
      flex: 1,
      minWidth: 140,
      valueGetter: (params) => params.row.phone
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 140,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
      },       
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return (
            <TableButton 
              type={getStatusType(params.row.status)}
            >
                {params.row.status}
            </TableButton>
          )
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        minWidth: 160,
        type: 'actions',
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
              {/* <TableIconButton type="view" onClick={() => navigate(`/admin/hospitals/profile/${params.row._id}`)} /> */}
              <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
          </Box>
        ),
      },
    ],
    [rowId]
  )

  return (
    <>
      { hospitalsStatus === 'loading' 
        ? <BackdropLoader /> 
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
              <PageHeader>
                <PageHeading heading="Hospitals List" text="List of all Hospitals" />
                <CustomButton title="Add Hospital" onClickHandler={() => navigate("/admin/hospitals/registration") } />
              </PageHeader>
              
            <ContentContainer>
              {/* Patients table */}
              <Table
                title="Hospitals List"
                icon={<ApartmentIcon sx={{ color: theme.palette.primary.light }} />} 
                heightPrecentage="40vh"
                columns={columns} 
                rows={hospitals}
                setRowId={setRowId}
                basePathForRedirect={"/admin/hospitals/profile"}
              />
            </ContentContainer>
          </Container>
        )
      } 
      <DeleteHospitalModal 
        hospitalId={deleteItemId}
        open={openDeleteModal}
        closeModal={closeModal}
      />
      <ToastContainer />
    </>
   
  )
}

export default HospitalsList
