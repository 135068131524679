import React from 'react'
import { Bar, CartesianGrid, Tooltip, BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const ReponsiveBarChart = ({data }) => {
  return (
    <ResponsiveContainer>
      <BarChart 
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: -14,
          bottom: 5
        }}
      >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="clinicPatients" name="Total Patients" fill="#ACB5BB"/>
          <Bar dataKey="userPatients" name="Your Patients" fill="#2B7F75" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ReponsiveBarChart