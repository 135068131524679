import React, { useEffect, useState } from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    OutlinedInput, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, FieldArray  } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import BasicModal from '../../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../../components/ui/CustomButton';
import { getAllLists, getListsError, getListsStatus } from '../../../../../redux/slices/listSlice';

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  choices: Yup.array().of(Yup.string().required("Item name is required.")),
  listType: Yup.string(),
  newChoiceText: Yup.string(),
  defaultValue: Yup.string(),
  showNoneItem: Yup.boolean(),
  showOtherItem: Yup.boolean(),
})

const SingleSelect = ({ openModal, setOpenModal, data, updateFieldData }) => {
  const dispatch = useDispatch()
    const theme = useTheme()
    const [newChoiceError, setNewChoiceError] = useState(false)
    
    const initialValues = {
      choices: data?.choices || [],
      listType: data?.type || "dropdown",
      newChoiceText: '',
      defaultValue: data?.defaultValue || "",
      showNoneItem: data?.showNoneItem || false,
      showOtherItem: data?.showOtherItem || false
    }

    // const [items, setItems] = useState([])
    const [selectedListType, setSelectedListType] = useState("")

    const lists = useSelector(getAllLists)
    const listsStatus = useSelector(getListsStatus)
    const listsError = useSelector(getListsError)

    const handleSelectListChange = (e, setFieldValue) => {
      setFieldValue("defaultValue", "")
      if (e.target.value == "new") {
        setSelectedListType(e.target.value)
        setFieldValue("choices", [])
      } else {
        setSelectedListType(e.target.value)
        const selectedList = lists?.find(item => item._id === e.target.value) 
        if(selectedList) {
          let itemsArray = []
          selectedList?.items?.map((item) => itemsArray.push(item?.name))
          setFieldValue("choices", itemsArray)
        }
      }
    }


    const submitHandler = async (values, { resetForm }) => {
      if(values?.choices?.length === 0) {
        setNewChoiceError(true)
        return
      }

      updateFieldData({
          "name": data?.name,
          "type": values?.listType,
          "choices": values?.choices,
          "defaultValue": values?.defaultValue,
          "showNoneItem": values?.showNoneItem,
          "showOtherItem": values?.showOtherItem,
          "startWithNewLine": false,
          "isRequired": true,
          "minWidth": "320px",
          "maxWidth": "max-content",
        })
      resetForm({ values: '' })
      setSelectedListType("new")
    }

    const handleAddChoice = (values, push, setFieldValue) => {
      setFieldValue('newChoiceText', values.newChoiceText.trim());
      if(values?.newChoiceText?.trim() !== "") {
          push(values.newChoiceText.trim());
          setNewChoiceError(false)
          setFieldValue('newChoiceText', '');
      }
    };
  
    const handleRemoveChoice = (index, remove) => {
      remove(index);
    };

    const SelectContentForm = () => (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, handleChange, handleBlur, errors, touched, setFieldValue, validateForm }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Select option */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="listType">List Type</InputLabel>
                        <Field
                          name="listType"
                          as={Select}
                          input={<OutlinedInput label="List Type" />} 
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="dropdown">Single Select</MenuItem>
                          <MenuItem value="tagbox">Multi Select</MenuItem>
                        </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="list">List</InputLabel>
                        <Field
                          as={Select}
                          input={<OutlinedInput label="List" />} 
                          size="small"
                          name="type"
                          variant="outlined"
                          value={selectedListType}
                          onChange={(e) => handleSelectListChange(e, setFieldValue)}
                          fullWidth
                        >
                          <MenuItem key="new" value="new">New</MenuItem>
                          {lists?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                          ))}
                        </Field>
                    </FormControl>
                  </Grid>


                      {/* Items */}
                      <Grid item xs={12}>
                      <FieldArray name="choices">
                        {({ push, remove }) => (
                          <>
                          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={1}>
                            <FormControl fullWidth>
                              <TextField
                                label="New item"
                                name="newChoiceText"
                                value={values.newChoiceText}
                                onChange={handleChange}
                                // onBlur={validateForm}
                                size="small"
                                // error={touched.newChoiceText && !!errors.newChoiceText}
                                // helperText={touched.newChoiceText && errors.newChoiceText}
                              />
                              {newChoiceError && <FormHelperText error>Item name is required</FormHelperText>}
                            </FormControl>
                              <Button
                                size="small"
                                onClick={() => {
                                  if(!values?.newChoiceText) {
                                    setNewChoiceError(true)
                                  } else {
                                    handleAddChoice(values, push, setFieldValue) 
                                  }
                                }}
                              >
                                Add Item
                              </Button>
                            </Box>
                            {values.choices.map((choice, index) => (
                              <Box key={index} display="flex" alignItems="center" mb={2} gap={1}>
                                <TextField
                                  label={`Choice ${index + 1}`}
                                  fullWidth
                                  name={`items[${index}]`}
                                  value={choice}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  size="small"
                                  error={touched.choices && touched.choices[index] && !!errors.choices?.[index]}
                                  helperText={touched.choices && touched.choices[index] && errors.choices?.[index]}
                                />
                                <Button
                                  onClick={() => handleRemoveChoice(index, remove)}
                                  size="small"
                                  sx={{
                                    color: theme.palette.custom.red.light
                                  }}
                                >
                                  Remove
                                </Button>
                              </Box>
                            ))}
                          </>
                        )}
                      </FieldArray>
                      </Grid>
                    
              {/* Default Value */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="type">Default</InputLabel>
                    <Field
                      as={Select}
                      input={<OutlinedInput label="Default" />} 
                      name="defaultValue"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={errors.defaultValue && touched.defaultValue}
                      helperText={touched.defaultValue && errors.defaultValue}
                    >
                      {values?.choices?.map((choice, index) => (
                        <MenuItem ket={index} value={choice}>{choice}</MenuItem>
                      ))}
                    </Field>
                </FormControl>
              </Grid>

              {/* Show None Item */}
              <Grid item xs={12}>
                <Field name="showNoneItem">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Include None Item"
                      />
                      {form.errors.showNoneItem && form.touched.showNoneItem && (
                        <FormHelperText error>{form.errors.showNoneItem}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

              {/* Show Other item */}
              <Grid item xs={12} my={0} py={0}>
                <Field name="showOtherItem">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Include Other Item"
                      />
                      {form.errors.showOtherItem && form.touched.showOtherItem && (
                        <FormHelperText error>{form.errors.showOtherItem}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>
              {/* </>
              : null
            } */}
              </Grid>

              <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
               <CustomButton 
                  title="Cancel"
                  variant='outlined'
                  onClickHandler={() => setOpenModal(false)}
                />
               <CustomSubmitButton 
                  title="Update"
                />
              </Box>
            </Form>
          )}
        </Formik>
    )

    return (
      <>
        <BasicModal 
            title="Update Select/Mulit-Select Properties"
            open={openModal}
            onClose={() => setOpenModal(false)}
            content={SelectContentForm}
        />
      </>
    )

}

export default SingleSelect