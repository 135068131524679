import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getToken } from "./authSlice";
import { USERS_URL } from "../../constants/apis";

const initialState = {
    users: [],
    clinics: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}


export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await axios.get(USERS_URL)
    return response.data
})

export const fetchUserClinics = createAsyncThunk('clinics/fetchUserClinics', async (userId, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${USERS_URL}/clinics/${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
})

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Fetch Users  
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.users = action.payload
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.users = action.error.message
            })
            // Fetch User Clinics  
            .addCase(fetchUserClinics.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUserClinics.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.clinics = action.payload
            })
            .addCase(fetchUserClinics.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllUsers = (state) => state.users.users
export const selectUserClinics = (state) => state.users.clinics
export const getUsersStatus = (state) => state.users.status
export const getUsersError = (state) => state.users.error

export const selectUserClinicById = (state, clinicId) => 
        state.users.clinics.find(clinic => clinic._id == clinicId)

export default userSlice.reducer