import React, { useEffect, useMemo, useState } from 'react'
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../../redux/slices/authSlice'
import { fetchHospitals, selectAllHospitals } from '../../../../redux/slices/hospitalSlice'
import { fetchClinics, selectAllClinics } from '../../../../redux/slices/clinicSlice'
import { USERS_URL } from '../../../../constants/apis'
import { fetchUserClinics } from '../../../../redux/slices/userSlice'
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton'
import CustomButton from '../../../../components/ui/CustomButton'
import BasicModal from '../../../../components/ui/modal/BasicModal'
import ROLES from '../../../../constants/roles';

const DeleteFromClinicModal = ({ userId, userType, clinicId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle')
    const accessToken = useSelector(getToken)

    const canDelete = [userId, clinicId].every(Boolean) && deleteRequestStatus === 'idle'

    const onDeleteClinicClicked = async () => {

        if(!canDelete) {
          toast.info("Please wait, another request is in progress.")
          return
        }

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        }
        try {
          setDeleteRequestStatus('pending')
          const res = await axios.delete(`${USERS_URL}/${userId}/clinics/${clinicId}`, config) 
          if(res.status === 200) {
            if(userType === ROLES.DOCTOR) {
              toast.success("Doctor removed from clinic successfully.")
          } else {
            toast.success("Coordinator removed from clinic successfully.")
          }
            closeModal()
            dispatch(fetchUserClinics(userId))
            dispatch(fetchClinics())
          } else {
            toast.error("Something went wrong, please try again later.")
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setDeleteRequestStatus('idle')
        }
      }

    const DeleteModalContent = () => (
        <Box>
          <Typography variant='subtitle1' fontWeight="bold"  mb={3}>
            Are you sure you want to remove this clinic?
          </Typography>
          <Typography variant='subtitle2' mb={3}>
            This will remove doctor's access from this clinic. You cannot undo this action.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
           <CustomButton 
            title="Cancel" 
            onClickHandler={closeModal}
            variant="outlined"  
            color={theme.palette.custom.ashWhite.light}
            disabled={deleteRequestStatus === "pending"}
          />
           <CustomButton 
            title="Delete" 
            onClickHandler={onDeleteClinicClicked}
            color={theme.palette.custom.red.light}
            disabled={deleteRequestStatus === "pending"}
          />
    
          </Box>
        </Box>
      )


    return (
        <BasicModal 
            // title="Remove doctor access from clini"
            open={open}
            onClose={closeModal}
            content={DeleteModalContent}
    />
    )
}

export default DeleteFromClinicModal