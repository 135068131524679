import React from 'react'
import { Box, Container } from '@mui/material'
import { useParams } from 'react-router-dom'

import PageHeader from '../../../components/ui/PageHeader'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import { HospitalProfileData, HospitalClinicsTable } from '../../../features/admin'
import { useSelector } from 'react-redux';
import { selectDoctorById } from '../../../redux/slices/doctorSlice';
import { selectHospitalById } from '../../../redux/slices/hospitalSlice';

const ManageHospital = () => {
  const { hospitalId } = useParams()
  const hospital = useSelector((state) => selectHospitalById(state, hospitalId))

  return (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            <PageHeader>
              <PageHeading heading="Hospital Management" text="Update hospital's details and clinics." />
            </PageHeader>

            <Box display="flex" flexDirection="column" gap={3}>
              <ContentContainer padding={4}>
                <HospitalProfileData hospital={hospital} />
              </ContentContainer>
              
              <ContentContainer padding={2}>
                <HospitalClinicsTable hospitalId={hospitalId} />
              </ContentContainer>
            </Box>
          </Container>
  )
}

export default ManageHospital