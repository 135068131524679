import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import CustomSubmitButton from '../../../components/ui/CustomSubmitButton'
import CustomButton from '../../../components/ui/CustomButton'
import BasicModal from '../../../components/ui/modal/BasicModal'

import { HOSPITALS_URL } from '../../../constants/apis'

import { getToken } from '../../../redux/slices/authSlice'
import { fetchHospitals } from '../../../redux/slices/hospitalSlice'
import { fetchClinics } from '../../../redux/slices/clinicSlice'

const DeleteHospitalModal = ({ hospitalId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle')
    const accessToken = useSelector(getToken)

    const canDelete = [hospitalId].every(Boolean) && deleteRequestStatus === 'idle'

    const onDeleteHospitalClicked = async () => {

        if(!canDelete) {
          toast.info("Please wait, another request is in progress.")
          return
        }

        try {
          setDeleteRequestStatus('pending')
          const res = await axios.delete(`${HOSPITALS_URL}/${hospitalId}`, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
          }) 
          if(res.status === 200) {
            toast.success("Hospital deleted successfully.")
            closeModal()
            dispatch(fetchHospitals())
            dispatch(fetchClinics())
          } else {
            toast.error("Something went wrong, please try again later.")
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setDeleteRequestStatus('idle')
        }
      }

    const DeleteModalContent = () => (
        <Box>
          <Typography variant='subtitle1' fontWeight="bold"  mb={3}>
            Are you sure you want to remove this hospital?
          </Typography>
          <Typography variant='subtitle2' mb={3}>
            This will remove all data of hospital. You cannot undo this action.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
           <CustomButton 
            title="Cancel" 
            onClickHandler={closeModal}
            variant="outlined"  
            color={theme.palette.custom.ashWhite.light}
            disabled={deleteRequestStatus === "pending"}
          />
           <CustomButton 
            title="Delete" 
            onClickHandler={onDeleteHospitalClicked}
            color={theme.palette.custom.red.light}
            disabled={deleteRequestStatus === "pending"}
          />
          </Box>
          <ToastContainer />
        </Box>
      )


    return (
        <BasicModal 
            open={open}
            onClose={closeModal}
            content={DeleteModalContent}
        />
    )
}

export default DeleteHospitalModal