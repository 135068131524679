import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import { getToken, selectUser } from '../../../redux/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHospitals, getHospitalsStatus, selectAllHospitals } from '../../../redux/slices/hospitalSlice';
import PageHeader from '../../../components/ui/PageHeader'
import { addClinic, fetchClinics, selectAllClinics } from '../../../redux/slices/clinicSlice';
import { CLINICS_URL } from '../../../constants/apis';
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton';
import { fetchAdminDashboardStatsData } from '../../../redux/slices/adminDashboardSlice';

const initialValues = {
  name: '',
  hospital: '',
  phone: "",
  email: ""
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  name: Yup.string().required('Name is required'),
  hospital: Yup.string().required('Hospital is required'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number')
})


const AddClinic = () => {
  const dispatch = useDispatch()

  const hospitals = useSelector(selectAllHospitals)
  const hospitalsStatus = useSelector(getHospitalsStatus)
  
  const accessToken = useSelector(getToken)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const submitHandler = async (values, { resetForm }) => {
    try {
      setAddRequestStatus('pending')
      const res = await axios.post(CLINICS_URL, values, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      }) 
      if(res.status === 201) {
        dispatch(fetchHospitals())
        dispatch(fetchClinics())
        dispatch(fetchAdminDashboardStatsData())
        toast.success("Clinic added successfully.")
        resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
    } finally {
      setAddRequestStatus('idle')
    }
  }

  useEffect(() => {
    if(hospitalsStatus !== 'loading') {
      dispatch(fetchHospitals())
    }
  }, [])

  return (
    <Container sx={{ mt: 1 }} maxWidth="xl">
        <PageHeader>
            <PageHeading heading="Clinic Registration" text="Clinic registration form" />
        </PageHeader>
      <ContentContainer padding={4}>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="hospital">Hospital</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Hospital" />} 
                  name="hospital"
                  variant="outlined"
                  fullWidth
                  error={errors.hospital && touched.hospital}
                  helperText={touched.hospital && errors.hospital}
                >
                  {
                    hospitals?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                    ))
                  }
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>            
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Add Clinic"
                    disabled={addRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default AddClinic