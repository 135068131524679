import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import { getToken, getUserActiveClinic, getUserActiveRole } from "./authSlice"
import { DOCTORS_URL } from "../../constants/apis"
import ROLES from "../../constants/roles";

const initialState = {
    doctors: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

// export const fetchDoctors = createAsyncThunk('doctors/fetchDoctors', async (_, { getState }) => {
//     const authToken = getToken(getState())
        
//     const response = await axios.get(DOCTORS_URL, {
//         headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json", // Add other headers if needed
//           },
//     })
//         const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
//         return dataWithId
// })

export const fetchDoctors = createAsyncThunk('hospitals/fetchDoctors', async (_, { getState }) => {
    const userActiveRole = getUserActiveRole(getState())
    const authToken = getToken(getState())
    const activeClinicId = getUserActiveClinic(getState())

    let URL = userActiveRole === ROLES.ADMIN
                ? DOCTORS_URL
                : activeClinicId 
                    ? `${DOCTORS_URL}/clinics/${activeClinicId}`
                    : null

    if(URL) {
        const response = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json", // Add other headers if needed
            }
        })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
    } else {
        return []
    }
})

const doctorSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        addDoctor(state, action) {
            state.clinics.push(action.payload)
        },
    },
    extraReducers(builder) {
        builder
            // Fetch Clinics  
            .addCase(fetchDoctors.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchDoctors.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.doctors = action.payload
            })
            .addCase(fetchDoctors.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllDoctors = (state) => state.doctors.doctors
export const getDoctorsStatus = (state) => state.doctors.status
export const getDoctorsError = (state) => state.doctors.error

export const selectDoctorById = (state, doctorId) =>
    state.doctors.doctors.find(doctor => doctor._id == doctorId)

export const { addDoctor } = doctorSlice.actions
export default doctorSlice.reducer