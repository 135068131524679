import React, { useState } from 'react'
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'


import { getFormDetails, updateForm } from '../../../../redux/slices/formSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';

const defaultMin = -99999
const defaultMax = 99999

const initialValues = {
  label: '',
  type: 'text',
  rows: "",
  defaultValue: "",
  startWithNewLine: true,
  isRequired: false
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  label: Yup.string().required("Label is required."),
  rows: Yup.number(),
  defaultValue: Yup.string(),
  startWithNewLine: Yup.boolean(),
  isRequired: Yup.boolean(),
})

const TextArea = ({ addTextAreaField }) => {
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)

    const submitHandler = async (values, { resetForm }) => {
      addTextAreaField([{
        "name": uuidv4(),
        "type": "comment",
        "title": values?.label,
        "rows": values?.rows ? values?.rows : null,
        "defaultValue": values?.defaultValue,
        "startWithNewLine": values?.startWithNewLine,
        "isRequired": values?.isRequired,
      }])
      resetForm({ values: '' })
      setOpenModal(false)
    }

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    error={errors.label && touched.label}
                    helperText={touched.label && errors.label}
                  />
                </Grid>
                
                 {/* Min Value */}
                 <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="rows"
                      label="Rows"
                      type="number"
                      inputProps={{
                        min: 1
                      }}
                      variant="outlined"
                      fullWidth
                      error={errors.rows && touched.rows}
                      helperText={touched.rows && errors.rows}
                    />
                  </Grid>

               {/* Default Value */}
               <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="defaultValue"
                    label="Default Value"
                    variant="outlined"
                    fullWidth
                    error={errors.defaultValue && touched.defaultValue}
                    helperText={touched.defaultValue && errors.defaultValue}
                  />
                </Grid>
                
                {/* New line */}
               <Grid item xs={12} md={6}>
                <Field name="startWithNewLine">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox {...field} checked={field?.value} />}
                          label="New Line"
                        />
                        {form.errors.startWithNewLine && form.touched.startWithNewLine && (
                          <FormHelperText error>{form.errors.startWithNewLine}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

                {/* Required */}
               <Grid item xs={12} md={6}>
                <Field name="isRequired">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Required"
                        />
                        {form.errors.isRequired && form.touched.isRequired && (
                          <FormHelperText error>{form.errors.isRequired}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

              </Grid>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Add"
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <TextFormatIcon color="custom.gray" />
              <Typography>TextArea</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Add TextArea Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default TextArea