import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

import ROLES from '../../constants/roles'
import { getToken, getUserActiveClinic, selectUser } from "../../redux/slices/authSlice"
import SelectClinicModal from "../common/SelectClinicModal"

const RequireAuth = ({ allowedRoles }) => {
    const user = useSelector(selectUser)
    const accessToken = useSelector(getToken)
    const userActiveClinic = useSelector(getUserActiveClinic)
    const location = useLocation()

    if(user && accessToken && !userActiveClinic && !user?.roles?.includes(ROLES.ADMIN))
        return ( 
            <SelectClinicModal />
    )

    return (
        user?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    )
}

export default RequireAuth

