import React, { memo, useEffect, useState } from 'react'
import { Autocomplete, Box, Checkbox, FormHelperText, Grid, TextField, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { getToken } from '../../../../../redux/slices/authSlice'

import CustomButton from '../../../../../components/ui/CustomButton'
import BasicModal from '../../../../../components/ui/modal/BasicModal'
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton'
import { PATIENTS_URL } from '../../../../../constants/apis'
import { fetchPatientFormReports } from '../../../../../redux/slices/patientSlice'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  title: Yup.string().required('Title is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid title')
    .min(2, 'Title should be min 2 characters long')
    .max(100, 'Title should be max 100 characters long'),
  description: Yup.string()
  .matches(/^[a-zA-Z0-9\s]*$/, 'Invalid description')
  .min(6, 'Description should be min 6 characters long')
  .max(120, 'Description should be max 120 characters long'),
})

const CopyFormReportModal = ({ form, patientId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    
      const [pages, setPages] = useState(form?.pages || [])
      const [addRequestStatus, setAddRequestStatus] = useState('idle')
      const [pageError, setPageError] = useState(false)
      const accessToken = useSelector(getToken)

      const initialValues = {
        title: form?.title || "",
        description: form?.description || "",
      }

    const submitHandler = async (values, { resetForm }) => {
      if(pages?.length === 0) {
        setPageError(true)
        return
      }

      try {
        setAddRequestStatus('pending')
        const res = await axios.post(`${PATIENTS_URL}/form-reports/replicate`, 
          {
            title: values?.title,
            description: values?.description || "",
            category: form?.category || "",
            pages,
            patientId
           }, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        }) 
        if(res.status === 201) {
          resetForm({ values: '' })
          const { _id } = res?.data
          toast.success("Form copied successfully.")
          dispatch(fetchPatientFormReports(patientId))
          closeModal()
          // setTimeout(() => 
          //   navigate(`/doctor/form-templates/${_id}`)
          // , 1500)
        } else {
          toast.error("Something went wrong, please try again later.")
        }
      } catch (err) {
        console.error({err})
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
      } finally {
        setAddRequestStatus('idle')
      }
    }


    const AddFormContent = () => {
      return (
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="title"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    error={errors.title && touched.title}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="description"
                    label="Description"
                    variant="outlined"
                    inputProps={{
                      maxLength: 24
                    }}
                    fullWidth
                    error={errors.description && touched.description}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="pages"
                    options={form?.pages}
                    defaultValue={form?.pages}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps} >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Pages" 
                        placeholder="Page name"
                        fullWidth
                      />
                    )}
                    onChange={(e, option) => setPages(option)}
                  />
                    {pageError 
                        ? <FormHelperText error>Please select at least 1 page.</FormHelperText>
                        : null                      
                    }
                </Grid>
                
                  {/* Actions */}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                      <CustomButton 
                          title="Cancel" 
                          onClickHandler={() => closeModal("add_modal")}
                          variant="outlined"  
                          color={theme.palette.custom.ashWhite.light}
                          disabled={addRequestStatus === "pending"}
                        />
                        <CustomSubmitButton 
                          title="Add Form"
                          disabled={addRequestStatus === "pending"}
                        />
                    </Box>
                  </Grid>
                </ Grid>
              </Form>
            )}
          </Formik>
        </Box>
      )}

    return (
        <BasicModal 
            title="Copy Form Report"
            open={open}
            onClose={closeModal}
            content={AddFormContent}
        />
    )
}

export default CopyFormReportModal