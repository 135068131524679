import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { getFormDetails, updateForm } from '../../../../../redux/slices/formSlice';
import BasicModal from '../../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../../components/ui/CustomButton';


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  text: Yup.string().required("Text is required.")
})

const Text = ({ openModal, setOpenModal, data, updateTextField }) => {
    const theme = useTheme()

    const initialValues = {
      text: data?.html.substring(3, data.html.length - 4) || ''
    }

    const submitHandler = async (values, { resetForm }) => {
      updateTextField({
        name: data?.name,
        "type": "text",
        "title": values?.text,
        "width": "max-content",
        "minWidth": "max-content",
        "maxWidth": "max-content",
        "html": `<p>${values?.text}</p>`,
        "startWithNewLine": false
      })
      resetForm({ values: '' })
    }
    
  const TextFormContent = () => (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Field
                as={TextField}
                name="text"
                label="Text"
                variant="outlined"
                fullWidth
                error={errors.text && touched.text}
                helperText={touched.text && errors.text}
              />
              <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
               <CustomButton 
                  title="Cancel"
                  variant='outlined'
                  onClickHandler={() => setOpenModal(false)}
                />
               <CustomSubmitButton 
                  title="Update"
                />
              </Box>
            </Form>
          )}
        </Formik>
  )

  return (
    <>
      <BasicModal 
          title="Update Text Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={TextFormContent}
      />
    </>

  )
}

export default Text