import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, FormControl,
   InputLabel, Select, MenuItem, OutlinedInput, FormHelperText, 
   Autocomplete
} from '@mui/material'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import PageHeader from '../../../components/ui/PageHeader'
import { UploadImage } from '../../../features/common'
import calculateAge from '../../../libs/calcAgeFromDOB'

import { PATIENTS_URL, UPLOADS_URL } from '../../../constants/apis'
import { getToken, getUserActiveClinic } from '../../../redux/slices/authSlice'
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton'
import { fetchPatients } from '../../../redux/slices/patientSlice'
import { cities, genders } from '../../../constants/data';
import { fetchDoctors, getDoctorsError, getDoctorsStatus, selectAllDoctors } from '../../../redux/slices/doctorSlice';

const today = dayjs();

const initialValues = {
  firstName: '',
  lastName: '',
  gender: '',
  dob: '',
  age: '',
  email: '',
  street: '',
  city: '',
  otherCity: '',
  phone: '',
  nationalId: '',
  mrn: '',
  doctor: '',
  diagnosis: ''
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  firstName: Yup.string().required('First Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid first name'),
  lastName: Yup.string().required('Last Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid last name'),
  nationalId: Yup.string()
  .matches(/^[a-zA-Z0-9\s]*$/, 'Invalid national Id')
  .min(10, 'Invalid National ID')
  .max(10, 'Invalid National ID'),
  mrn: Yup.string()
    .matches(/^[0-9-]+$/, 'Invalid MRN')
    .min(6, 'Invalid MRN')
    .max(20, 'Invalid MRN')
    .required('MRN is required'),
  gender: Yup.string().required('Gender is required'),
  dob: Yup.string(),
  age: Yup.mixed()
    .required("Age is required")
    .test('isNumber', 'Age must be a number', (value) => !isNaN(value))
    .test('isPositive', 'Age must be a positive number and greater than 0', (value) => value > 0)
    .test('max', 'Age must below 120', (value) => value < 120),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
  street: Yup.string()
    .max(100, 'Max 100 characters are allowed'),
  city: Yup.string(),
  otherCity: Yup.string(),
  doctor: Yup.string(),
  diagnosis: Yup.string()
  .matches(/^[a-zA-Z,\s]*$/, 'Invalid Diagnosis')
  .max(64, 'Invalid Diagnosis')
})

const AddPatient = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [image, setImage] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const accessToken = useSelector(getToken) 
  const userActiveClinic = useSelector(getUserActiveClinic) 

  const doctors = useSelector(selectAllDoctors)
  const doctorsStatus = useSelector(getDoctorsStatus)
  const doctorsError = useSelector(getDoctorsError)

  useEffect(() => {
    if (doctorsStatus === "idle" && doctors.length === 0)
        dispatch(fetchDoctors())
}, [])

  const submitHandler = async (values, { resetForm }) => {
    try {
      setAddRequestStatus('pending')
      const res = await axios.post(PATIENTS_URL, 
        {
          ...values,
          clinic: userActiveClinic
         }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      }) 
      if(res.status === 201) {
        const patientId = res?.data?._id
        // Upload Image
        if(image) {
          const formData = new FormData()
          formData.append('image', image)

          try {
            const config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`
              }
            }
            const res = await axios.post(`${UPLOADS_URL}/patient/picture/${patientId}`, formData, config)
            if(res.status === 200) {
              setImage(null)
              // toast.success("Picture upload successfully.")
            } else {
              toast.error("Something went wrong, please try again later.")
              }
          } catch (err) {
            toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
          } 
        }
        toast.success("Patient added successfully.")
        // navigate(`/patients/profile/${patientId}`)
        dispatch(fetchPatients())
        resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
    } finally {
      setAddRequestStatus('idle')
    }
  }

  return (
    <Container sx={{ mt: 1 }} maxWidth="xl" >
      <PageHeader>
        <PageHeading heading="Patient Registration" text="Patient registration form" />
      </PageHeader>
      <ContentContainer padding={4}>
        <UploadImage image={image} onImageChange={setImage} />
        {/* Form Content */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
        {({ values, errors, touched, setFieldValue, setTouched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First name"
                  variant="outlined"
                  inputProps={{
                    maxLength: 24
                  }}
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last name"
                  variant="outlined"
                  inputProps={{
                    maxLength: 24
                  }}
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="mrn"
                  label="MRN (Medical Record No.)"
                  variant="outlined"
                  inputProps={{
                    minLength: 6,
                    maxLength: 20
                  }}
                  fullWidth
                  error={errors.mrn && touched.mrn}
                  helperText={touched.mrn && errors.mrn}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="nationalId"
                  label="National ID"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.nationalId && touched.nationalId}
                  helperText={touched.nationalId && errors.nationalId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}  >
                    <DatePicker
                      sx={{ width: "100%"}}
                      label="Date of Birth"
                      value={selectedDate ? dayjs(selectedDate) : null}
                      maxDate={today}
                      onChange={(value) => {
                        console.log({value})
                        const date = `${value?.$y}-${Number(value?.$M+1)}-${value?.$D}`
                        setSelectedDate(value?.$d)
                        setFieldValue("dob", date)
                        setFieldValue("age", calculateAge(date))
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.dob && errors.dob)}
                          helperText={touched.dob && errors.dob}
                        />
                      )}
                    />
                  </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="age"
                  label="Age"
                  variant="outlined"
                  type="number"
                  // inputProps={{
                  //   min: 0
                  // }}
                  onChange={(e) => {
                    setSelectedDate(null)
                    setFieldValue("dob", "")
                    setFieldValue("age", e.target.value)
                  }}
                  fullWidth
                  error={errors.age && touched.age}
                  helperText={touched.age && errors.age}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  name="gender"
                  options={genders}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => setFieldValue('gender', value ? value.value : '')}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender"
                    variant="outlined"
                    error={errors.gender && touched.gender}
                    helperText={touched.gender && errors.gender}
                  />
                )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{
                    maxLength: 30
                  }}
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Field
                  as={TextField}
                  name="street"
                  label="Address"
                  variant="outlined"
                  inputProps={{
                    maxLength: 100
                  }}
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>

              {(values?.city !== "Other") 
                ?
                  <Grid item xs={12} md={4}>
                   <Autocomplete
                      name="city"
                      options={cities}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => setFieldValue('city', value ? value : '')}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label="City"
                        variant="outlined"
                        error={errors.city && touched.city}
                        helperText={touched.city && errors.city}
                      />
                    )}
                  />
                  </Grid> 
                : 
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="City"
                      variant="outlined"
                      inputProps={{
                        maxLength: 30
                      }}
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid>
              }

              <Grid item xs={12} md={4}>
               <FormControl fullWidth>
                <InputLabel htmlFor="doctor">Doctor</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Doctor" />} 
                  name="doctor"
                  variant="outlined"
                  fullWidth
                  error={errors.doctor && touched.doctor}
                  helperText={touched.doctor && errors.doctor}
                >
                    {doctors?.map((doctor) => (
                      <MenuItem key={doctor?._id} value={doctor?._id}>{doctor?.firstName} {doctor?.lastName}</MenuItem>
                    ))}
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={8}>
                <Field
                  as={TextField}
                  name="diagnosis"
                  label="Diagnosis"
                  variant="outlined"
                  inputProps={{
                    maxLength: 64
                  }}
                  fullWidth
                  error={errors.diagnosis && touched.diagnosis}
                  helperText={touched.diagnosis && errors.diagnosis}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Add Patient"
                    disabled={addRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default AddPatient