import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'

import ContentContainer from '../../../components/ui/ContentContainer'
import ProfileField from '../../../components/ui/ProfileField'
import { IMAGES } from '../../../assets'
import TableButton from '../../../components/ui/table/TableButton'

const DoctorProfileOverview = ({ user }) => {
  const getStatusType = (userStatus) => {
    return userStatus === "active" ? "success" : "disabled"
  }

  return (
    <ContentContainer>
        <Box px={1}>
          <Box display="flex" justifyContent="center" my={2}>
            <Avatar 
              sx={{ width: 92, height: 92 }} 
              src={user?.picture} 
              // src={IMAGES?.user} 
            />
          </Box>
          <Typography fontWeight="bold" variant='h6' textAlign="center" gutterBottom>
            {user?.firstName} {user?.lastName}
          </Typography>
          <ProfileField label="Gender" value={user?.gender || "-"} />
          <ProfileField label="Email" value={user?.emailDetails?.email} />
          <ProfileField label="Phone" value={user?.phone} />
          <ProfileField label="Joining Date" value={user?.createdAt.substring(0, 10)} />
          <Box display="flex" justifyContent="space-between" my={1.5}>
            <Typography>
                Status
            </Typography>
            <TableButton 
                type={getStatusType(user?.status)}
            >
              {user?.status}
            </TableButton>
          </Box>
        </Box>
    </ContentContainer>
  )
}

export default DoctorProfileOverview