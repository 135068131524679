import React, { useState } from 'react'
import SelectAllIcon from '@mui/icons-material/SelectAll';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray  } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';

const initialValues = {
  label: '',
  choices: [],
  newChoiceText: '',
  startWithNewLine: true,
  showOtherItem: true,
  isRequired: false
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  label: Yup.string().required("Label is required."),
  choices: Yup.array().of(Yup.string().required("Item name is required.")),
  newChoiceText: Yup.string(),
  titleLocation: Yup.string(),
  showOtherItem: Yup.boolean(),
  isRequired: Yup.boolean(),
})

const DynamicSelect = ({ addDynamicSelectField }) => {
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [newChoiceError, setNewChoiceError] = useState(false)

    const submitHandler = async (values, { resetForm }) => {
      if(values?.choices?.length === 0) {
        setNewChoiceError(true)
        return
      }
      const dropdownId = uuidv4()

      addDynamicSelectField([{
        "type": "matrixdynamic",
        "title": values?.label,
        "name": dropdownId,
        "rowsCount": 2,
        "columns": [
          {
            "name": uuidv4(),
            "title": "Items",
            "choices": values?.choices,
            "showOtherItem": values?.showOtherItem,
             "isRequired": values?.isRequired,
            "cellType": "dropdown",
          },
          {
            "name": uuidv4(),
            "title": "Describe",
            "cellType": "comment",
            "rows": 1,
            "visible": true,
            "visibleIf": `{row.${dropdownId}} != 'other'`,
          }
        ],
        "choices": [ 1, 2, 3, 4, 5 ],
        "rowCount": 1,
        "addRowText": "Add Item",
      }])
      resetForm({ values: '' })
      setOpenModal(false)
    }

    const handleAddChoice = (values, push, setFieldValue) => {
      setFieldValue('newChoiceText', values.newChoiceText.trim());
      if(values?.newChoiceText?.trim() !== "") {
          push(values.newChoiceText.trim());
          setNewChoiceError(false)
          setFieldValue('newChoiceText', '');
      }
    };
  
    const handleRemoveChoice = (index, remove) => {
      remove(index);
    };

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, handleChange, handleBlur, errors, touched, setFieldValue, validateForm }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    error={errors.label && touched.label}
                    helperText={touched.label && errors.label}
                  />
                </Grid>

                {/* Type */}
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Typography variant="display2" fontWeight="bold">
                      Add Items
                    </Typography>
                  </Box>
                <FieldArray name="choices">
                  {({ push, remove }) => (
                    <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="New Choice"
                          // fullWidth
                          name="newChoiceText"
                          value={values.newChoiceText}
                          onChange={handleChange}
                          // onBlur={validateForm}
                          size="small"
                          // error={touched.newChoiceText && !!errors.newChoiceText}
                          // helperText={touched.newChoiceText && errors.newChoiceText}
                        />
                        {newChoiceError && <FormHelperText error>Item name is required</FormHelperText>}
                      </FormControl>
                        <Button
                          size="small"
                          onClick={() => {
                            if(!values?.newChoiceText) {
                              setNewChoiceError(true)
                            } else {
                              handleAddChoice(values, push, setFieldValue) 
                            }
                          }}
                        >
                          Add Item
                        </Button>
                      </Box>
                      {values.choices.map((choice, index) => (
                        <Box key={index} display="flex" alignItems="center" mb={2} gap={1}>
                          <TextField
                            label={`Choice ${index + 1}`}
                            fullWidth
                            name={`choices[${index}]`}
                            value={choice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            error={touched.choices && touched.choices[index] && !!errors.choices?.[index]}
                            helperText={touched.choices && touched.choices[index] && errors.choices?.[index]}
                          />
                          <Button
                            onClick={() => handleRemoveChoice(index, remove)}
                            size="small"
                            sx={{
                              color: theme.palette.custom.red.light
                            }}
                          >
                            Remove
                          </Button>
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
                </Grid>

              {/* Show Other item */}
              <Grid item xs={12} md={6}>
                <Field name="showOtherItem">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Include Other Item"
                      />
                      {form.errors.showOtherItem && form.touched.showOtherItem && (
                        <FormHelperText error>{form.errors.showOtherItem}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

                {/* Required */}
               <Grid item xs={12} md={6}>
                <Field name="isRequired">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Required"
                        />
                        {form.errors.isRequired && form.touched.isRequired && (
                          <FormHelperText error>{form.errors.isRequired}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

              </Grid>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Add"
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <SelectAllIcon color="custom.gray" />
              <Typography>Dynamic Select</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Add Dynamic Select Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default DynamicSelect