import React, { Children } from 'react'

import { IMAGES } from '../../assets'
import { Box } from '@mui/material';

const BannerBackground = ({ children }) => {
  return (
    <Box
        sx={{
            backgroundImage: `url(${IMAGES.bannerBg})`, // Replace with your image URL
            backgroundSize: 'cover',
            // backgroundPosition: 'center',
            height: '48vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        {children}
    </Box>
  )
}

export default BannerBackground