import React, { useState } from 'react'

import ContentContainer from '../../../../components/ui/ContentContainer'
import TabsNavigation from '../../../../components/ui/TabsNavigation'

import UpdatePatientProfile from './UpdateProfileDetails'
import SystemReports from './SystemReports';
import PersonalReports from './PersonalReports';
import FormReports from './formReports/FormReports';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserActiveRole } from '../../../../redux/slices/authSlice';
import ROLES from '../../../../constants/roles';

const getTabs = (patient, userActiveRole) => {
    if (userActiveRole === ROLES.DOCTOR) {
        return ([
            {
                label: "Profile Details",
                content: <UpdatePatientProfile patient={patient} />
            },
            {
                label: "Form Reports",
                content: <FormReports patientId={patient?._id} />
            },
            {
                label: "PDF Reports",
                content: <SystemReports />
            },
            {
                label: "Uploaded Reports",
                content: <PersonalReports patientId={patient?._id}  />
            },
        ])
    } else {
        return ([
            {
                label: "Profile Details",
                content: <UpdatePatientProfile patient={patient} />
            },
            {
                label: "PDF Reports",
                content: <SystemReports />
            },
            {
                label: "Uploaded Reports",
                content: <PersonalReports patientId={patient?._id}  />
            }
        ])
    }
} 

const ProfileDetails = ({ patient }) => {
    const userActiveRole = useSelector(getUserActiveRole)

    return (
        <ContentContainer>
            <TabsNavigation 
                // activeTab={currentTab}
                // setActiveTab={setCurrentTab} 
                tabs={getTabs(patient, userActiveRole)} 
            />
        </ContentContainer>
    )
}

export default ProfileDetails