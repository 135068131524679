import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import { getToken, getUserActiveClinic, getUserActiveRole } from "./authSlice"
import { PATIENTS_URL } from "../../constants/apis"
import ROLES from "../../constants/roles";

const initialState = {
    patients: [],
    forms: [],
    systemReports: [],
    personalReports: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchPatients = createAsyncThunk('patients/fetchPatients', async (values, { getState }) => {
    const userActiveRole = getUserActiveRole(getState())
    const authToken = getToken(getState())
    const activeClinicId = getUserActiveClinic(getState())

    const name = values?.name || ""
    const mrn = values?.mrn || ""
    const nationalId = values?.nationalId || ""
    const phone = values?.phone || ""
    const hospital = values?.hospital || ""
    const clinic = values?.clinic || ""
    const diagnosis = values?.diagnosis || ""
    const fromDate = values?.fromDate || ""
    const toDate = values?.toDate || ""

    let URL = userActiveRole === ROLES.ADMIN
                ? `${PATIENTS_URL}?name=${name}&mrn=${mrn}&nationalId=${nationalId}&phone=${phone}&diagnosis=${diagnosis}&hospital=${hospital}&clinic=${clinic}&fromDate=${fromDate}&toDate=${toDate}`
                : activeClinicId
                    ? `${PATIENTS_URL}/clinics/${activeClinicId}?name=${name}&mrn=${mrn}&nationalId=${nationalId}&phone=${phone}&diagnosis=${diagnosis}&fromDate=${fromDate}&toDate=${toDate}`
                    : null
    if(URL) {
        const response = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json", // Add other headers if needed
            }
        })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
    } else {
        return []
    }                
})

export const fetchPatientFormReports = createAsyncThunk('patients/fetchPatientFormReports', async (id, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${PATIENTS_URL}/form-reports/${id}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

export const fetchPatientSystemReports = createAsyncThunk('patients/fetchPatientSystemReports', async (id, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${PATIENTS_URL}/system-reports/${id}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

export const fetchPatientPersonalReports = createAsyncThunk('patients/fetchPatientPersonalReports', async (id, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${PATIENTS_URL}/personal-reports/${id}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    return response.data
})




const patientSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
    removePatientFromList: (state, action) => {
            const filteredPatients = state?.patients?.filter((item) => item?._id !== action?.payload)
            state.patients = filteredPatients
        },
        resetPatientData: (state, action) => {
            state.forms = []
            state.personalReports = []
            state.systemReports = []
        },
    },
    extraReducers(builder) {
        builder
            // Fetch Clinic Patients  
            .addCase(fetchPatients.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPatients.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.patients = action.payload
            })
            .addCase(fetchPatients.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            // Fetch Patient Form Reports  
            .addCase(fetchPatientFormReports.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPatientFormReports.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.forms = action.payload
            })
            .addCase(fetchPatientFormReports.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            // Fetch Patient system Reports  
            .addCase(fetchPatientSystemReports.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPatientSystemReports.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.systemReports = action.payload
            })
            .addCase(fetchPatientSystemReports.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
               // Fetch Patient personal Reports  
               .addCase(fetchPatientPersonalReports.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPatientPersonalReports.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.personalReports = action.payload
            })
            .addCase(fetchPatientPersonalReports.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getPatientForms = (state) => state.patients.forms
export const getPatientSystemReports = (state) => state.patients.systemReports
export const getPatientPersonalReports = (state) => state.patients.personalReports
export const selectAllPatients = (state) => state.patients.patients
export const getPatientsStatus = (state) => state.patients.status
export const getPatientsError = (state) => state.patients.error

export const {  resetPatientData, removePatientFromList } = patientSlice.actions

export const selectPatientById = (state, patientId) =>
    state.patients.patients.find(patient => patient._id == patientId)


export default patientSlice.reducer