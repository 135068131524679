import React, { useEffect, useState } from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';

import { getToken } from '../../../../../redux/slices/authSlice'

import CustomButton from '../../../../../components/ui/CustomButton'
import BasicModal from '../../../../../components/ui/modal/BasicModal'
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton';
import { FORM_TEMPLATES_URL, PATIENTS_URL } from '../../../../../constants/apis'
import { fetchActiveFormTemplates, getAllForms, getFormsError, getFormsStatus } from '../../../../../redux/slices/formSlice';
import { fetchPatientFormReports } from '../../../../../redux/slices/patientSlice';

const initialValues = {
  form: ''
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  form: Yup.string().required("Form is required."),
})


const AddFormReportModal = ({ patientId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const accessToken = useSelector(getToken)

    const forms = useSelector(getAllForms)
    const formsStatus = useSelector(getFormsStatus)
    const formsError = useSelector(getFormsError)
 
    useEffect(() => {
     // if(forms.length === 0) {
       dispatch(fetchActiveFormTemplates())
     // }
 }, [dispatch])

    const submitHandler = async (values, { resetForm }) => {
      try {
        setAddRequestStatus('pending')
        const res = await axios.post(`${PATIENTS_URL}/form-reports`, 
          {
            formId: values?.form,
            patientId: patientId
           }, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        }) 
        if(res.status === 201) {
          resetForm({ values: '' })
          const { _id } = res?.data
          toast.success("New report added successfully.")
          closeModal()
          // dispatch(fetchPatientFormReports(patientId))
          setTimeout(() => 
            navigate(`/doctor/form-templates/${_id}`)
          , 1500)
        } else {
          toast.error("Something went wrong, please try again later.")
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
      } finally {
        setAddRequestStatus('idle')
      }
    }

    const AddFormContent = () => (
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2}>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                      <InputLabel htmlFor="form">Select Template</InputLabel>
                      <Field
                        as={Select}
                        input={<OutlinedInput label="Select Template" />} 
                        name="form"
                        variant="outlined"
                        fullWidth
                        error={errors.form && touched.form}
                        helperText={touched.form && errors.form}
                      >
                        {forms?.map((form, index) => (
                          <MenuItem key={index} value={form?._id}>{form?.title}</MenuItem>
                        ))}
                      </Field>
                  </FormControl>
                </Grid>
                
                  {/* Actions */}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                      <CustomButton 
                          title="Cancel" 
                          onClickHandler={() => closeModal("add_modal")}
                          variant="outlined"  
                          color={theme.palette.custom.ashWhite.light}
                          disabled={addRequestStatus === "pending"}
                        />
                        <CustomSubmitButton 
                          title="Add Form"
                          disabled={addRequestStatus === "pending"}
                        />
                    </Box>
                  </Grid>
                </ Grid>
              </Form>
            )}
          </Formik>
        </Box>
      )

    return (
        <BasicModal 
            title="Add Patient Report"
            open={open}
            onClose={closeModal}
            content={AddFormContent}
        />
    )
}

export default AddFormReportModal