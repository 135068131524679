import React from 'react'
import { Button } from '@mui/material'
import { IMAGES } from '../../../../assets'

const RepButton = ({ title, mimeType="application/pdf" }) => {
  return (
    <Button  sx={{
        borderColor: '#DCE4E8',
        fontWeight: 600,
        fontSize: '14px',
        color: 'black',
        justifyContent: 'left'
      }} 
      startIcon={<img
          alt="Logo"
          src={mimeType === "application/pdf" ? IMAGES.pdf : IMAGES.imageIcon}
          style={{
            height: 20,
            width: 20
          }}
          loading='lazy'
      />}
      variant="outlined">{title}</Button>
  )
}

export default RepButton