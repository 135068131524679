import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    OutlinedInput, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'


import BasicModal from '../../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../../components/ui/CustomButton';


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  type: Yup.string().required("Type is required."),
  min: Yup.number(),
  max: Yup.number(),
  defaultValue: Yup.string(),
  placeholder: Yup.string(),
})

const TextInput = ({ openModal, setOpenModal, data, updateTextInputField }) => {
    const theme = useTheme()

    const initialValues = {
      type: data?.inputType || "text",
      min: data?.min || "",
      max: data?.max || "",
      defaultValue: data?.defaultValue ?? "",
      placeholder: data?.placeholder ?? ""
    }

    const submitHandler = async (values, { resetForm }) => {
      updateTextInputField({
        name: data?.name,
        "type": "text",
        "inputType": values?.type,
        "min": values?.min || null,
        "max": values?.max || null,
        "placeholder": values?.placeholder,
        "defaultValue": values?.defaultValue,
        "startWithNewLine": false,
        "isRequired": true,
        "minWidth": "60px",
        "maxWidth": "max-content",
      })
      resetForm({ values: '' })
    }

    const TextInputFormContent = () => (
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap={2}>
                  <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Field
                        as={Select}
                        input={<OutlinedInput label="Type" />} 
                        name="type"
                        variant="outlined"
                        fullWidth
                        error={errors.type && touched.type}
                        helperText={touched.type && errors.type}
                      >
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="date">Date</MenuItem>
                      </Field>
                  </FormControl>
                
               {/* Placeholder */}
                  <Field
                    as={TextField}
                    name="placeholder"
                    label="Placeholder"
                    variant="outlined"
                    fullWidth
                    error={errors.placeholder && touched.placeholder}
                    helperText={touched.placeholder && errors.placeholder}
                  />

              {values?.type !== "date" 
                ? (
                      <Field
                        as={TextField}
                        name="defaultValue"
                        label="Default Value"
                        variant="outlined"
                        fullWidth
                        error={errors.defaultValue && touched.defaultValue}
                        helperText={touched.defaultValue && errors.defaultValue}
                      />
                  ): null
                }
                
                {/* Show only if selected type is number */}
              {values?.type === "number" 
                ? (
                  <>
                    {/* Min Value */}
                      <Field
                        as={TextField}
                        name="min"
                        label="Min"
                        type="number"
                        variant="outlined"
                        fullWidth
                        error={errors.min && touched.min}
                        helperText={touched.min && errors.min}
                      />
                      
                    {/* Max Value */}
                      <Field
                        as={TextField}
                        name="max"
                        label="Max"
                        type="number"
                        variant="outlined"
                        fullWidth
                        error={errors.max && touched.max}
                        helperText={touched.max && errors.max}
                      />
                  </>
                ) : null
              }
            </Box>

            <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
               <CustomButton 
                  title="Cancel"
                  variant='outlined'
                  onClickHandler={() => setOpenModal(false)}
                />
               <CustomSubmitButton 
                  title="Update"
                />
              </Box>
            </Form>
          )}
        </Formik>
    )
    
    return (
      <>
        <BasicModal 
            title="Update Text Input Properties"
            open={openModal}
            onClose={() => setOpenModal(false)}
            content={TextInputFormContent}
        />
      </>
    )
}

export default TextInput