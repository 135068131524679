import React from 'react'
import { Box } from '@mui/material'

import Field from './Field'

// Fields 
import Text from './fields/Text'
import TextArea from './fields/TextArea';
import RadioGroup from './fields/RadioGroup'
import Page from './fields/Page'
import SingleSelect from './fields/SingleSelect'
import MuliSelect from './fields/MultipleSelect'
import DynamicSelect from './fields/DynamicSelect'
import Sentence from './fields/Sentence'

const FieldsList = ({ disabled=false, handleAddField, handleAddSentenceField, handleAddPage, type="template" }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      { type === "template" 
          ? <Field><Page addNewPageToForm={handleAddPage}  /></Field>
          : null
      }

      <Field disabled={disabled}>
        <Text addTextField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <TextArea addTextAreaField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <RadioGroup addRadioGroupField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <SingleSelect addSingleSelectField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <MuliSelect addMultiSelectField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <DynamicSelect addDynamicSelectField={handleAddField} disabled={disabled} />
      </Field>
      <Field disabled={disabled}>
        <Sentence addSentenceField={handleAddSentenceField} disabled={disabled} />
      </Field>
    </Box>
  )
}

export default FieldsList