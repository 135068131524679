import React, { useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import PageHeader from '../../../../components/ui/PageHeader'
import CustomButton from '../../../../components/ui/CustomButton'
import Table from '../../../../components/ui/table/Table'
import TableButton from '../../../../components/ui/table/TableButton'
import TableIconButton from '../../../../components/ui/table/TableIconButton'
import ContentContainer from '../../../../components/ui/ContentContainer'
import BackDropLoader from '../../../../components/ui/BackdropLoader'
import TableHeading from '../../../../components/ui/table/TableHeading'
import AddToClinicModal from './AddToClinicModal'
import DeleteFromClinicModal from './DeleteFromClinicModal'

import ROLES from '../../../../constants/roles'

import { fetchHospitals, getHospitalsStatus, selectAllHospitals } from '../../../../redux/slices/hospitalSlice'
import { fetchClinics, getClinicsStatus, selectAllClinics } from '../../../../redux/slices/clinicSlice'
import { fetchUserClinics, getUsersStatus, selectUserClinicById, selectUserClinics } from '../../../../redux/slices/userSlice'
import Loader from '../../../../components/ui/Loader';

const ClinicsTable = ({ user }) => {
    const dispatch = useDispatch()
    const [rowId, setRowId] = useState(null)
    const [deleteItemId, setDeleteItemId] = useState(null)
    const [clinicId, setClinicId] = useState(null)
    const clinic = useSelector((state) => selectUserClinicById(state, clinicId))

    const [openAddModal, setOpenAddModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const userClinics = useSelector(selectUserClinics)
    const userClinicsStatus = useSelector(getUsersStatus)

    const hospitals = useSelector(selectAllHospitals)
    const hospitalStatus = useSelector(getHospitalsStatus)

    const clinics = useSelector(selectAllClinics)
    const clinicsStatus = useSelector(getClinicsStatus)

   useEffect(() => {
        dispatch(fetchUserClinics(user?._id))
      
       if (hospitalStatus === "idle" && hospitals.length === 0)
           dispatch(fetchHospitals())
       if (clinicsStatus === "idle" && clinics.length === 0)
           dispatch(fetchClinics())
   }, [])

    const closeModal = (type) => {
      if(type === "add") setOpenAddModal(false)
      if(type === "delete") {
        setOpenDeleteModal(false)
        setDeleteItemId(null)
      }
    }
    
    const onAddHandler = () => {
       setOpenAddModal(true)
    }

    const onDeleteHandler = (id) => {
      setDeleteItemId(id)
      setOpenDeleteModal(true)
   }

    const onEditHandler = (id) => {
        setClinicId(id)
        setOpenAddModal(true)
    }
    
    const getStatusType = (clinicStatus) => {
        return clinicStatus === "active" ? "success" : "disabled"
    }

    const columns = useMemo(
        () => [
          {
            field: 'no',
            headerName: 'No',
            maxWidth: 60,
            valueGetter: (params) => params.row.id,
            sortable: false,
          },
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          minWidth: 140,
          valueGetter: (params) => params.row.clinic?.name
        },
        {
          field: 'hospital',
          headerName: 'Hospital',
          flex: 1,
          minWidth: 160,
          valueGetter: (params) => params.row.clinic?.hospital?.name
        },
        {
          field: 'phone',
          headerName: 'Phone',
          sortable: false,
          flex: 1,
          minWidth: 140,
          valueGetter: (params) => params.row.clinic?.phone
        },
        {
          field: 'email',
          headerName: 'Email',
          sortable: false,
          flex: 1,
          minWidth: 200,
          valueGetter: (params) => params.row.clinic?.email
        },
        {
          field: 'createdAt',
          headerName: 'Added on',
          flex: 1,
          minWidth: 140,
          // sortable: false,
          filterable: false,
          valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
          // moment(params.row.appointmentDate).format('YYYY-MM-DD')
          },       
          {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 100,
            filterable: false,
            renderCell: (params) => {
              return (
                <TableButton 
                  type={getStatusType(params.row.status)}
                >
                    {params.row.status}
                </TableButton>
              )
            }
          },
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1,
              minWidth: 160,
              type: 'actions',
              renderCell: (params) => (
                <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                    <TableIconButton type="edit" onClick={() => onEditHandler(params.row._id)} />
                    <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row.clinic?._id)} />
                </Box>
              ),
            },
        ],
        [rowId]
      )

    return (
        <>
         {userClinicsStatus === "loading" 
            ? <Loader />
          : (
            <>
                <Box px={1}>
                    <PageHeader>
                        <TableHeading heading="Clinic Access" text="Manage Doctor's access to clinics" />
                        <CustomButton title="Add to Clinic" onClickHandler={onAddHandler} />
                    </PageHeader>
                </Box>
                <Divider />
                {/* Clinics table */}
                <Table 
                    heightPercentage="60vh"
                    columns={columns} 
                    rows={userClinics}
                    setRowId={setRowId}
                />
            </>
            )
         }
            <AddToClinicModal
                userId={user?._id} 
                userType={user?.roles.includes(ROLES.DOCTOR) ? ROLES.DOCTOR : ROLES.COORDINATOR}
                open={openAddModal}
                closeModal={() => closeModal("add")}
            />

            <DeleteFromClinicModal
                userId={user?._id}
                userType={user?.roles.includes(ROLES.DOCTOR) ? ROLES.DOCTOR : ROLES.COORDINATOR}
                clinicId={deleteItemId} 
                open={openDeleteModal}
                closeModal={() => closeModal("delete")}
            />

            <ToastContainer />
        </>
    )
}

export default ClinicsTable