import React from 'react'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const getIconButton = (type, onClick, disabled) => {
     if (type === "view") {
        return (
         <Tooltip title="View">
            <IconButton sx={{ background: "#2B7F75", padding: 0.9, borderRadius: 3 }} onClick={onClick} disabled={disabled}>
               <RemoveRedEyeOutlinedIcon sx={{ color: "#FFF", fontSize: 20 }} />
            </IconButton>
         </Tooltip>
        )
     } else if (type === "edit") {
        return (
         <Tooltip title="Edit">
            <IconButton sx={{ background: "#54A6FF", padding: 0.9, borderRadius: 3 }} onClick={onClick} disabled={disabled}>
               <BorderColorOutlinedIcon sx={{ color: "#FFF", fontSize: 20 }} />
            </IconButton>
         </Tooltip>
        )
     } else if (type === "copy") {
        return (
         <Tooltip title="Copy">
            <IconButton sx={{ background: "#BBBBBB", padding: 0.9, borderRadius: 3 }} onClick={onClick} disabled={disabled}>
               <ContentCopyIcon sx={{ color: "#FFF", fontSize: 20 }} />
            </IconButton>
         </Tooltip>
        )
     } else {
        return (
         <Tooltip title="Delete">
            <IconButton sx={{background: "#CE2C60", padding: 0.9, borderRadius: 3 }} onClick={onClick} disabled={disabled}>
               <DeleteForeverOutlinedIcon sx={{ color: "#FFF", fontSize: 20 }} />
            </IconButton>
         </Tooltip>
        )
     }
}

const TableIconButton = ({ type, onClick, disabled=false }) => {
  return (
   <>
        {getIconButton(type, onClick, disabled)}
   </>
  )
}

export default TableIconButton