import React, { useState } from 'react'
import { Container, Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import { getToken, selectUser } from '../../../redux/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton';
import { fetchHospitals } from '../../../redux/slices/hospitalSlice';
import { UploadImage } from '../../../features/common';
import { HOSPITALS_URL, UPLOADS_URL } from '../../../constants/apis';
import { cities } from '../../../constants/data';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  street: '',
  city: 'Riyadh',
  otherCity: ''
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  name: Yup.string().required('Hospital name is required')
  .matches(/^[a-zA-Z0-9\s]*$/, 'Invalid hospital name'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
  street: Yup.string()
    .max(100, 'Max 100 characters are allowed'),
  city: Yup.string().required('City is required'),
  otherCity: Yup.string()
    .when("city", {
      is: (val) => val.includes("Other"),
      then: () => Yup.string()
                  .matches(/^[a-zA-Z\s]*$/, 'Invalid city name')
                  .required("City is required")
    }),
})



const AddHospital = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(getToken)
  const [image, setImage] = useState(null)


  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const submitHandler = async (values, { resetForm }) => {
    try {
      setAddRequestStatus("loading")
      const res = await axios.post(HOSPITALS_URL, values, {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
      }) 
      if(res.status === 201) {
        const hospitalId = res?.data?._id
        // Upload Image
        if(image) {
          const formData = new FormData()
          formData.append('image', image)

          try {
            const config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`
              }
            }
            const imgRes = await axios.post(`${UPLOADS_URL}/hospital/${hospitalId}`, formData, config)
            if(imgRes.status === 200) {
              setImage(null)
              // toast.success("Picture upload successfully.")
            } else {
              toast.error("Something went wrong, please try again later.")
              }
          } catch (err) {
            toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
          } 
        }
        toast.success("Hospital added successfully.")
        resetForm({ values: '' })
        dispatch(fetchHospitals())
      } else {
        toast.error("Something went wrong, please try again later.")
      }
    } catch (err) {
      toast.error(err.response.data.message)
    } finally {
      setAddRequestStatus("idle")

    }
  }

  return (
    <Container sx={{ mt: 1 }} maxWidth="xl" >
        <PageHeader>
            <PageHeading heading="Hospital Registration" text="Hospital registration form" />
        </PageHeader>
      <ContentContainer padding={4}>
        <UploadImage title="Upload Logo" image={image} onImageChange={setImage} />

        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="street"
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="City" />} 
                  name="city"
                  variant="outlined"
                  fullWidth
                  error={errors.city && touched.city}
                  helperText={touched.city && errors.city}
                >
                  {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>{city}</MenuItem>
                        ))}
                </Field>
                </FormControl>
              </Grid>    
              {values?.city === "Other"
                ? (
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="Other City"
                      variant="outlined"
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid> 
                ) : null
              }     
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Add Hospital"
                    disabled={addRequestStatus === "loading"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default AddHospital