import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';

const TabsNavigation = ({ tabs }) => {
    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get("activeTab")

    const [activeTab, setActiveTab] = useState(currentTab ? Number(currentTab) : 0)

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue)
    }
return (
    <Box>
        <Tabs
            sx={{
                marginBottom: 4
            }}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            aria-label="navigation tabs"
        >
            {tabs?.map((tab, index) => (
                <Tab label={tab.label} key={index} />
            ))}
        </Tabs>
        <Box>
            {tabs[activeTab].content}
        </Box>
    </Box>

)}

export default TabsNavigation