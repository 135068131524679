import React from 'react'

import ContentContainer from '../../../../components/ui/ContentContainer'
import TabsNavigation from '../../../../components/ui/TabsNavigation'

import UpdateUserProfile from './UpdateProfileDetails'
import ClinicsTable from './ClinicsTable';

const getTabs = (user) => {

    return ([
        {
            label: "Profile Details",
            content: <UpdateUserProfile user={user} />
        },
        {
            label: "Clinics",
            content: <ClinicsTable user={user} />
        },
    ])
} 

const ProfileDetails = ({ user, type }) => {
    return (
        <ContentContainer>
            <TabsNavigation tabs={getTabs(user)} />
        </ContentContainer>
    )
}

export default ProfileDetails