import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getToken } from "./authSlice"
import { HOSPITALS_URL } from "../../constants/apis"

const initialState = {
    hospitals: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchHospitals = createAsyncThunk('hospitals/fetchHospitals', async (_, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(HOSPITALS_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

const hospitalSlice = createSlice({
    name: 'hospitals',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Fetch Admin Hospitals 
            .addCase(fetchHospitals.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchHospitals.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.hospitals = action.payload
            })
            .addCase(fetchHospitals.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllHospitals = (state) => state.hospitals.hospitals
export const getHospitalsStatus = (state) => state.hospitals.status
export const getHospitalsError = (state) => state.hospitals.error

export const selectHospitalById = (state, hospitalId) =>
    state.hospitals.hospitals.find(hospital => hospital._id == hospitalId)

export default hospitalSlice.reducer