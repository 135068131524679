import React, { useEffect, useState } from 'react'
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Card, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui';

import 'survey-core/defaultV2.min.css'

import { fetchLists, getAllLists, getListsError, getListsStatus } from '../../../../redux/slices/listSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';

import { SentenceText, SentenceTextInput, SentenceSingleSelect } from '../../newForm'

import EditText from './sentence/Text'
import EditTextInput from './sentence/TextInput'
import EditSingleSelect from './sentence/SingleSelect'


function deleteQuestion(element) {
  element.parent.removeQuestion(element);
}

const Sentence = ({ openModal, setOpenModal, data, updateSentenceField }) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editFieldData, setEditFieldData] = useState(null)
    const [json, setJson] = useState(data?.elements || [])
    const [survey, setSurvey] = useState(new Model([]))

    const lists = useSelector(getAllLists)
    const listsStatus = useSelector(getListsStatus)
    const listsError = useSelector(getListsError)

    useEffect(() => {
        if (lists?.length === 0 && listsStatus !== "loading")
            dispatch(fetchLists())
    }, [])

    useEffect(() => {
      const elements = JSON.parse(JSON.stringify(data?.elements))
      if(elements.length > 0) {
        elements?.map(item => {
          if(item?.type === "html") {
            item.type = "text"
            item.title = item.html.substring(3, item.html.length - 4)
          }
        })
      }
      setJson(elements)
    }, [data])

    useEffect(() => {
      setSurvey(new Model({
        pages: [
          {
            elements: [
              {
              type: "panel",
                elements: json
             }
           ]
          }
        ]
      }))
    }, [json])

    // const survey = new Model({
    //   pages: [
    //     {
    //       elements: [
    //         {
    //         type: "panel",
    //           // questionTitleLocation: "hidden",
    //           elements: json
    //        }
    //      ]
    //     }
    //   ]
    // })

    // Add properties
    survey.emptySurveyText = "Please add components to get started."
    survey.validationEnabled = false
    survey.showNavigationButtons = false
    survey.showCompletedPage = false
    survey.showQuestionNumbers = false

    survey.onUpdateQuestionCssClasses.add(function(_, options) {
      const classes = options.cssClasses;
      if (!options.question?.jsonObj?.html) {
        classes.title = "survey-title-hidden";
        classes.requiredText = "survey-title-hidden";
      } else {
        classes.title = "survey-title-font-weight-normal";
        classes.header = "survey-title-margin-top";
        classes.root = "survey-content-hidden"
        classes.titleBar = "survey-html-tag-actions"
      }
  });
  
  survey.onUpdatePanelCssClasses.add(function(_, options) {
    const classes = options.cssClasses;
    // if(options.panel.getQuestionTitleLocation() === "hidden") {
      classes.panel.content = "survey-html-panel";
    // }
  });

     // Survey Buttons
     survey.onGetQuestionTitleActions.add((_, opt) => {

      opt.titleActions = [
          {
              title: 'Edit',
              innerCss: 'btn-more-info',
              action: () => {
                  // showDescription(opt.question);
                  editQuestion(opt.question)
              },
          },
          {
              title: "Delete",
              innerCss: 'btn-more-error',
              action: () => {
                  deleteField(opt.question);
              },
          },
      ];
  });


  const addFieldData = (data) => {
    if(data?.type === "html") {
      data.type = "text"
      data.title = data.html.substring(3, data.html.length - 4)
    }
    setJson((prev) => [...prev, data])
  }

  const deleteField = (field) => {
    const filteredJson = json.filter((item) => item?.name !== field?.name) 
    deleteQuestion(field)
    setJson(filteredJson)
  }

  const editQuestion = (element) => {
    setOpenEditModal(true)
    setEditFieldData(element?.jsonObj)
}

const handleUpdateField = (field) => {
  const sentenceItems = json
  sentenceItems?.map((question) => {

    if(question?.name == field?.name) {

      // Common
      question.type = field?.type ?? question.type
      question.title = field?.title?.trim() ?? question?.title
      question.defaultValue =  field?.defaultValue?.trim() ?? question?.defaultValue
      question.placeholder =  field?.placeholder?.trim() ?? question?.placeholder
      question.startWithNewLine =  field?.startWithNewLine ?? field?.startWithNewLine
      question.isRequired =  field?.isRequired ?? field?.isRequired
      
      // Text
      if(question?.type === "text") {
          question.inputType =  field?.inputType ?? question?.inputType
          question.min =  field?.min ?? question.min
          question.max =  field?.max ?? question.max
          
          if(question?.html) {
            question.html =  field?.html ?? question.html
          }
        }

      // Select, Multi-Select
      if(question?.type === "dropdown" || question?.type === "tagbox") {
        question.choices = field?.choices ?? question.choices
        question.showNoneItem = field?.showNoneItem ?? question.showNoneItem
        question.showOtherItem = field?.showOtherItem ?? question.showOtherItem
      }
    }
    
  })
  setOpenEditModal(false)
  setJson(sentenceItems)
  setSurvey(new Model({
    pages: [
      {
        elements: [
          {
          type: "panel",
            elements: sentenceItems
         }
       ]
      }
    ]
  }))
}

    const submitHandler = async (e) => {
      e.preventDefault()
      const fields = json
      fields.map((item) => {
        if(item?.html) {
          item.type = "html"
          item.html = `<p>${item.title}</p>`
        }
      })
      updateSentenceField(data?.name, fields)
      setOpenModal(false)
    }

    const AddFormContent = () => (
      <Box height={800}>
        <Box mb={4} height={100}>
          {json.length === 0 
            ? 
              <Card sx={{ backgroundColor: theme.palette.custom.gray.light, padding: 4}}>
                <Typography textAlign="center">Please add components to get started</Typography>
              </Card>
            :
              <Survey 
                model={survey}
              />
          }
          <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
            <CustomButton 
              title="Cancel" 
              onClickHandler={() => setOpenModal(false)}
              variant="outlined"  
              color={theme.palette.custom.ashWhite.light}
            />
            <CustomButton 
                title="Update sentence" 
                onClickHandler={submitHandler}
              />
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} my={4}>
              <SentenceText addFieldData={addFieldData} />
            </Grid>
            <Grid item xs={12} md={4} my={4}>
              <SentenceTextInput addFieldData={addFieldData} />
            </Grid>
            <Grid item xs={12} md={4} my={4}>
              <SentenceSingleSelect addFieldData={addFieldData} />
            </Grid>
          </Grid>
          
        </Box>
      </Box>
    )

    const renderEditModal = () => {
      if (editFieldData?.html) {
          return (
               <EditText 
                   openModal={openEditModal}
                   setOpenModal={setOpenEditModal}
                   data={editFieldData}
                   updateTextField={handleUpdateField}
               />
           ) 
      } else if (editFieldData?.type === "text") {
          return (
               <EditTextInput 
                   openModal={openEditModal}
                   setOpenModal={setOpenEditModal}
                   data={editFieldData}
                   updateTextInputField={handleUpdateField}
               />
           ) 
      } else if (editFieldData?.type === "dropdown" || editFieldData?.type === "tagbox") {
          return (
              <EditSingleSelect 
                  openModal={openEditModal}
                  setOpenModal={setOpenEditModal}
                  data={editFieldData}
                  updateFieldData={handleUpdateField}
              />
          )
      // } else if (editFieldData?.type === "tagbox") {
      //     return (
      //         <EditMultiSelect 
      //             openModal={openEditModal}
      //             setOpenModal={setOpenEditModal}
      //             data={editFieldData}
      //             updateMultiSelect={handleUpdateField}
      //         />
      //     )
      } else {
          return null
      }
  }

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <CodeIcon color="custom.gray" />
              <Typography>Sentence</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
       {/* Edit Fields */}

       {openEditModal 
            ? renderEditModal()
            : null   
        }
      <BasicModal 
          title="Update Sentence Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
          maxSize="lg"
      />
    </>

  )
}

export default Sentence