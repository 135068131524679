import React, { useEffect, useMemo, useState } from 'react'
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../../redux/slices/authSlice'
import { fetchHospitals, selectAllHospitals } from '../../../../redux/slices/hospitalSlice'
import { fetchClinics, selectAllClinics } from '../../../../redux/slices/clinicSlice'
import { USERS_URL } from '../../../../constants/apis'
import { fetchUserClinics } from '../../../../redux/slices/userSlice'
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton'
import CustomButton from '../../../../components/ui/CustomButton'
import BasicModal from '../../../../components/ui/modal/BasicModal'
import ROLES from '../../../../constants/roles';

const initialValues = {
    hospital: '', 
    clinic: '',
}

const validationSchema = Yup.object().shape({
    hospital: Yup.string().required('Hospital is required'),
    clinic: Yup.string().required('Clinic is required'),
})


const AddToClinicModal = ({ userId, userType, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [hospitalClinics, setHospitalClinics] = useState([])
    const accessToken = useSelector(getToken)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    
    const hospitals = useSelector(selectAllHospitals)
    const clinics = useSelector(selectAllClinics)
    

    const onHospitalChange = (hospital) => {
        const filteredClinics = clinics?.filter((item) => item.hospital._id == hospital)
        setHospitalClinics((prev) => filteredClinics)
    }

    const submitHandler = async (values, { resetForm }) => {
        setAddRequestStatus('pending')
        try {
            const res = await axios.post(`${USERS_URL}/clinics`, {
                userId,
                clinicId: values.clinic
            }, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
            }) 
            if(res.status === 201) {
                if(userType === ROLES.DOCTOR) {
                    toast.success("Doctor added to clinic successfully.")
                } else {
                    toast.success("Coordinator added to clinic successfully.")
                }
                closeModal()
                dispatch(fetchUserClinics(userId))
                // dispatch(fetchHospitals())
                dispatch(fetchClinics())
            } else {
                toast.error("Something went wrong, please try again later.")
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
            setAddRequestStatus('idle')
        }
    }

    const AddModalContent = () => {
        return (
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                >
                {({ errors, setFieldValue, touched }) => (
                <Form>
                    <Grid container spacing={3}>  
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="hospital">Hospital</InputLabel>
                        <Field
                            as={Select}
                            input={<OutlinedInput label="Hospital" />}
                            name="hospital"
                            variant="outlined"
                            fullWidth
                            error={errors.hospital && touched.hospital}
                            helperText={touched.hospital && errors.hospital}
                            // onChange={(e) => { onHospitalChange(e.target.value) }}
                        >
                        {
                            hospitals?.map((item) => (
                            <MenuItem
                                key={item._id} 
                                value={item._id}
                                onClick={() => {
                                    onHospitalChange(item._id)
                                    setFieldValue("clinic", "")
                                }}
                            >
                                {item.name}
                            </MenuItem>
                            ))
                        }
                        </Field>
                        </FormControl>
                    </Grid>  
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="clinic">Clinic</InputLabel>
                        <Field
                            as={Select}
                            input={<OutlinedInput label="Clinic" />}
                            name="clinic"
                            variant="outlined"
                            fullWidth
                            error={errors.clinic && touched.clinic}
                            helperText={touched.clinic && errors.clinic}
                        >
                        {
                            hospitalClinics?.length === 0
                            ? <MenuItem size="small">No clinic available.</MenuItem>
                            : (
                                hospitalClinics?.map((item) => (
                                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                                ))
                            )
                        }
                        </Field>
                        </FormControl>
                    </Grid>                          
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                        <CustomButton 
                            title="Cancel" 
                            onClickHandler={closeModal}
                            variant="outlined"  
                            color={theme.palette.custom.ashWhite.light}
                            disabled={addRequestStatus === "pending"}
                        />
                        <CustomSubmitButton 
                            title="Add" 
                            disabled={addRequestStatus === "pending"}
                        />
                        </Box>
                    </Grid>
                    </Grid>
                </Form>
                )}
                </Formik>
            </Box>
        )
    }


    return (
        <BasicModal 
            title="Add doctor to clinic"
            open={open}
            onClose={closeModal}
            content={AddModalContent}
    />
    )
}

export default AddToClinicModal