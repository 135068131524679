import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { DoctorProfileOverview } from '../../../features/coordinator'

// import { UserProfileDetails } from '../../../features/admin'
import { selectDoctorById } from '../../../redux/slices/doctorSlice'

const DoctorProfile = () => {
  const { doctorId } = useParams()
  const doctor = useSelector((state) => selectDoctorById(state, doctorId))

  return (
          <Container sx={{ mt: 1 }} maxWidth="xs" >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DoctorProfileOverview user={doctor} />
              </Grid>
            </Grid>
          </Container>
  )
}

export default DoctorProfile