import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Container, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'


import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import { DeletePatientModal, PatientSearchForm } from '../../../features/common'
import { PatientSearchForm as AdminPatientSearchForm } from '../../../features/admin'

import { fetchPatients, getPatientsError, getPatientsStatus, selectAllPatients } from '../../../redux/slices/patientSlice'
import { getUserActiveRole } from '../../../redux/slices/authSlice';
import ROLES from '../../../constants/roles';
import TableIconButton from '../../../components/ui/table/TableIconButton';

const PatientsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [hospitalClinics, setHospitalClinics] = useState([])

   const userActiveRole = useSelector(getUserActiveRole)

   const patients = useSelector(selectAllPatients)
   const patientsStatus = useSelector(getPatientsStatus)
   const patientsError = useSelector(getPatientsError)


   useEffect(() => {
    if(patientsStatus === "idle") {
      dispatch(fetchPatients())
    }
}, [])

const onDeleteHandler = (id) => {
  setDeleteItemId(id)
  setOpenDeleteModal(true)
}

const closeModal = () => {
  setOpenDeleteModal(false)
  setDeleteItemId(null)
}

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 220,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar 
            sx={{ width: 32, height: 32 }}
            src={params.row?.picture} 
          />
          <Typography variant="body2" fontWeight="bold">
            {params.row?.firstName + " " + params.row?.lastName}
          </Typography>
        </Box>
      )
    },
    {
      field: 'mrn',
      headerName: 'MRN',
      minWidth: 140,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row?.mrn
    },
    {
      field: 'age',
      headerName: 'Age',
      minWidth: 16,
      valueGetter: (params) => params.row?.age
    },
    {
      field: 'gender',
      headerName: 'Gender',
      minWidth: 40,
      valueGetter: (params) => params.row?.gender === "n/a" ? "" : params.row?.gender
    },
    {
      field: 'hospital',
      headerName: 'Hospital',
      flex: 1,
      minWidth: 140,
      valueGetter: (params) => params.row?.clinic?.hospital?.name
    },
    {
      field: 'clinic',
      headerName: 'Clinic',
      flex:1,
      minWidth: 140,
      valueGetter: (params) => params.row?.clinic?.name
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 120,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
      },       
      {
        field: 'status',
        headerName: 'Status',
        width: 140,
        filterable: false,
        renderCell: (params) => {
          let type="success"
          if (params.row.status === "new") {
            type = "success"
          }
          else {
            type="info"
          }
          return (
            <TableButton 
              type={type}
            >
                {params.row?.status}
            </TableButton>
          )
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        minWidth: 160,
        type: 'actions',
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
              <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
          </Box>
        ),
      },
    ],
    [rowId]
  )
  return (
    <>
      <Container sx={{ mt: 1 }} maxWidth="xl" >
          {/* Page Heading Bar */}
          <PageHeader>
            <PageHeading heading="Patient List" text="List of all patients" />
            {userActiveRole !== ROLES.ADMIN
              ? <>
                <CustomButton title="Add Patient" onClickHandler={() => navigate("/patients/registration") } />
              
              </>
              : null
            }
          </PageHeader>
          {userActiveRole !== ROLES.ADMIN
            ?
              <PatientSearchForm 
                hospitalClinics={hospitalClinics}
                setHospitalClinics={setHospitalClinics}
              />
            :  <AdminPatientSearchForm 
                hospitalClinics={hospitalClinics}
                setHospitalClinics={setHospitalClinics}
              />
          }


        <ContentContainer>
          <Table 
            heightPrecentage="50vh"
            columns={columns} 
            rows={patients}
            setRowId={setRowId}
            isLoading={patientsStatus === "loading"}
            basePathForRedirect={"/patients/profile"}
          />
        </ContentContainer>
      </Container>
      <DeletePatientModal
          patientId={deleteItemId}
          open={openDeleteModal}
          closeModal={closeModal}
        />
      <ToastContainer />
    </>
  )
}

export default PatientsList
