import { Box, Typography } from "@mui/material";

const ProfileField = ({ label, value }) => {
    return (
      <Box display="flex" justifyContent="space-between" my={2}>
      <Typography>
          {label}
      </Typography>
      <Typography maxWidth="60%" textAlign="end" fontWeight="bold" noWrap>
          {value}
      </Typography>
    </Box>
    )
}

export default ProfileField