import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import { getToken } from '../../../redux/slices/authSlice'
import { fetchHospitals, selectAllHospitals } from '../../../redux/slices/hospitalSlice';
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton'
import PageHeader from '../../../components/ui/PageHeader'

import { addClinic, fetchClinics, selectAllClinics } from '../../../redux/slices/clinicSlice';

import { fetchDoctors } from '../../../redux/slices/doctorSlice'
import { UploadImage } from '../../../features/common'
import { UPLOADS_URL, USERS_URL } from '../../../constants/apis'
import ROLES from '../../../constants/roles'
import { cities } from '../../../constants/data';

const initialValues = {
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  phone: '',
  street: '',
  city: 'Riyadh',
  otherCity: '',
  hospital: '', 
  clinic: '',
  credentials: '',
  username: '',
  password: ''
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  firstName: Yup.string().required('First Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid first name'),
  lastName: Yup.string().required('Last Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid last name'),
  gender: Yup.string().required('Gender is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string()
  .matches(/^[\d+]+$/, 'Invalid phone number')
  .min(10, 'Invalid phone number')
  .max(10, 'Invalid phone number')
  .required('Phone is required'),
  street: Yup.string()
    .max(60, 'Invalid Address'),
  city: Yup.string().required('City is required'),
  otherCity: Yup.string()
    .when("city", {
      is: (val) => val.includes("Other"),
      then: () => Yup.string()
                  .matches(/^[a-zA-Z\s]*$/, 'Invalid city name')
                  .required("City is required")
    }),
  hospital: Yup.string().required('Hospital is required'),
  clinic: Yup.string().required('Clinic is required'),
  username: Yup.string().required('Username is required'),
  credentials: Yup.string()
  .matches(/^[a-zA-Z:.();\s]*$/, 'Invalid credentials'),
  password: Yup.string()
  .min(6, 'Must be at least 6 characters long')
  .required('Password is required'),
})


const AddDoctor = () => {
  const dispatch = useDispatch()

  const hospitals = useSelector(selectAllHospitals)
  const clinics = useSelector(selectAllClinics)
  const accessToken = useSelector(getToken) 
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [hospitalClinics, setHospitalClinics] = useState([])
  const [image, setImage] = useState(null)
  
  const onHospitalChange = (hospital) => {
    const filteredClinics = clinics?.filter((item) => item.hospital._id == hospital)
    setHospitalClinics((prev) => filteredClinics)
  }

  const submitHandler = async (values, { resetForm }) => {
    try {
      setAddRequestStatus("pending")
      const res = await axios.post(USERS_URL, {
        ...values, 
        role: ROLES.DOCTOR,
      }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      }
      )
      if(res.status === 201) {
        const userId = res?.data?._id
        // Upload Image
        if(image) {
          const formData = new FormData()
          formData.append('image', image)

          try {
            const config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`
              }
            }
            const res = await axios.post(`${UPLOADS_URL}/user/${userId}`, formData, config)
            if(res.status === 200) {
              setImage(null)
            } else {
              toast.error("Something went wrong, please try again later.")
              }
          } catch (err) {
            toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
          } 
        }
        dispatch(fetchClinics())
        dispatch(fetchDoctors())
        toast.success("Doctor added successfully.")
        resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      } 
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
    } finally {
      setAddRequestStatus("idle")
    }
  }

  useEffect(() => {
      dispatch(fetchHospitals())
      dispatch(fetchClinics())
  }, [])

  return (
    <Container sx={{ mt: 1 }} maxWidth="xl">
        <PageHeader>
            <PageHeading heading="Doctor Registration" text="Doctor registration form" />
        </PageHeader>
      <ContentContainer padding={4}>
        {/* <UploadImage image={image} onImageChange={setImage} /> */}
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, values, setFieldValue, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Gender" />} 
                  name="gender"
                  variant="outlined"
                  fullWidth
                  error={errors.gender && touched.gender}
                  helperText={touched.gender && errors.gender}
                >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                  
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{
                    maxLength: 30
                  }}
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="street"
                  label="Street Address"
                  variant="outlined"
                  inputProps={{
                    maxLength: 100
                  }}
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="City" />} 
                  name="city"
                  variant="outlined"
                  fullWidth
                  error={errors.city && touched.city}
                  helperText={touched.city && errors.city}
                >
                   {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>{city}</MenuItem>
                        ))}
                </Field>
                </FormControl>
              </Grid> 
              {values?.city === "Other"
                ? (
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="Other City"
                      variant="outlined"
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid> 
                ) : null
              }        
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="hospital">Hospital</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Hospital" />} 
                  name="hospital"
                  variant="outlined"
                  fullWidth
                  error={errors.hospital && touched.hospital}
                  helperText={touched.hospital && errors.hospital}
                  // onChange={(e) => { onHospitalChange(e.target.value) }}
                >
                  {
                    hospitals?.map((item) => (
                      <MenuItem
                       key={item._id} 
                       value={item._id}
                       onClick={() => { 
                        onHospitalChange(item._id)
                        setFieldValue("clinic", "")
                       }}
                       >
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="clinic">Clinic</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Clinic" />} 
                  name="clinic"
                  variant="outlined"
                  fullWidth
                  error={errors.clinic && touched.clinic}
                  helperText={touched.clinic && errors.clinic}
                >
                  {
                    hospitalClinics?.length === 0
                     ? <MenuItem size="small">No clinic available.</MenuItem>
                     :
                        hospitalClinics?.map((item) => (
                          <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                        ))
                  }
                </Field>
                </FormControl>
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="credentials"
                  label="Credentials"
                  placeholder="M.D.: Doctor of medicine"
                  variant="outlined"
                  fullWidth
                  error={errors.credentials && touched.credentials}
                  helperText={touched.credentials && errors.credentials}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  error={errors.username && touched.username}
                  helperText={touched.username && errors.username}
                />
              </Grid>
           
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                />
              </Grid> 
                         
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Add Doctor"
                    disabled={addRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default AddDoctor