import React, { useEffect, useState } from 'react'
import { 
    Container, Box, Grid, TextField, Button, FormControl, 
    InputLabel, Select, MenuItem, OutlinedInput, Typography, 
    useTheme, FormHelperText
  } from '@mui/material'
import { Formik, Form, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import PageHeader from '../../../components/ui/PageHeader'
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton'

import { getToken, selectUser } from '../../../redux/slices/authSlice'
import { fetchLists, fetchListsCategories, getListCategories, selectListById } from '../../../redux/slices/listSlice';
import { LISTS_URL } from '../../../constants/apis';
import { useParams } from 'react-router-dom';


const validationSchema = Yup.object().shape({
   // Define validation rules for each field
   name: Yup.string().required('Name is required')
   .matches(/^[a-zA-Z\s]*$/, 'Invalid name'),
    category: Yup.string().required('Category is required'),
    items: Yup.array().of(Yup.string().required("Item name is required.")),
    newItemText: Yup.string(),
})


const EditList = () => {
    const { id } = useParams()

  const theme = useTheme()
  const dispatch = useDispatch()

  const [newChoiceError, setNewChoiceError] = useState(false)

  const list = useSelector((state) => selectListById(state, id))

  const initialValues = {
    name: list?.name || '',
    category: list?.category || '',
    items: list?.items?.map((item) => item['name']) || [],
    newItemText: '',
  }

  const categories = useSelector(getListCategories)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const accessToken = useSelector(getToken)

  useEffect(() => {
    dispatch(fetchListsCategories())
}, [])

const handleAddChoice = (values, push, setFieldValue) => {
  setFieldValue('newItemText', values.newItemText.trim());
  if(values?.newItemText?.trim() !== "") {
      push(values.newItemText.trim());
      setNewChoiceError(false)
      setFieldValue('newItemText', '');
  }
};

const handleRemoveChoice = (index, remove) => {
  remove(index);
};

  const submitHandler = async (values, { resetForm }) => {
    if(values?.items?.length === 0) {
      setNewChoiceError(true)
      return
    }
    try {
      setAddRequestStatus("pending")
      let itemsArray = []

      if (values?.items?.length > 0) {
        values?.items?.map((item) => {
          itemsArray.push({
            name: item
          })
        })
      }

      const res = await axios.put(LISTS_URL, {
        id,
        name: values?.name,
        category: values?.category,
        items: itemsArray
      }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      })
      if(res.status === 200) {
        dispatch(fetchLists())
        toast.success("List updated successfully.")
      } else {
        toast.error("Something went wrong! Please try again later.")
      } 
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong! Please try again later.")
    } finally {
      setAddRequestStatus("idle")
    }
  }


  return (
    <Container sx={{ mt: 1 }} maxWidth="sm">
        <PageHeader>
            <PageHeading heading="Update List" text="Upate list details" />
        </PageHeader>
      <ContentContainer padding={4}>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, values, handleBlur, handleChange, setFieldValue, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="category">Categoty</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Category" />} 
                  name="category"
                  variant="outlined"
                  fullWidth
                  error={errors.category && touched.category}
                  helperText={touched.category && errors.category}
                >
                  {categories?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>
                </FormControl>
              </Grid> 
                {/* ITems */}
                <Grid item xs={12}>
                      <Box mb={2}>
                        <Typography variant="display2" fontWeight="bold">
                          Add Items
                        </Typography>
                      </Box>
                    <FieldArray name="items">
                      {({ push, remove }) => (
                        <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={1}>
                          <FormControl fullWidth>
                            <TextField
                              label="New item"
                              // fullWidth
                              name="newItemText"
                              value={values.newItemText}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              size="small"
                            />
                            {newChoiceError && <FormHelperText error>Item name is required</FormHelperText>}
                          </FormControl>
                            <Button
                              size="small"
                              onClick={() => {
                                if(!values?.newItemText) {
                                  setNewChoiceError(true)
                                } else {
                                  handleAddChoice(values, push, setFieldValue) 
                                }
                              }}
                            >
                              Add Item
                            </Button>
                          </Box>
                          {values?.items?.map((item, index) => (
                            <Box key={index} display="flex" alignItems="center" mb={2} gap={1}>
                              <TextField
                                label={`Item ${index + 1}`}
                                fullWidth
                                name={`items[${index}]`}
                                value={item}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                error={touched.items && touched.items[index] && !!errors.items?.[index]}
                                helperText={touched.items && touched.items[index] && errors.items?.[index]}
                              />
                              <Button
                                onClick={() => handleRemoveChoice(index, remove)}
                                size="small"
                                sx={{
                                  color: theme.palette.custom.red.light
                                }}
                              >
                                Remove
                              </Button>
                            </Box>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid> 
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <CustomSubmitButton 
                        title="Update"
                        disabled={addRequestStatus === "pending"}
                      />
                    </Box>
                  </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default EditList