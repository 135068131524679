import { Box, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const Loader = () => {
  const theme = useTheme()
  return (
    <Box open={true} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <CircularProgress sx={{ color: theme.palette.primary.light }} />
      <p>Loading...</p>
   </Box>
  )
}

export default Loader