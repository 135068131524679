import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, Container, Divider, Grid, IconButton, Typography, useTheme  } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import CustomButton from '../../../../../components/ui/CustomButton'
import Table from '../../../../../components/ui/table/Table'
import TableButton from '../../../../../components/ui/table/TableButton'
import PageHeader from '../../../../../components/ui/PageHeader'
import { getPatientForms } from '../../../../../redux/slices/patientSlice';
import AddFormReportModal from './AddFormReportModal';
import { ToastContainer } from 'react-toastify';
import TableIconButton from '../../../../../components/ui/table/TableIconButton';
import DeleteFormReportModal from './DeleteFormReportModal';

import convertFirstCharToUpperCase from '../../../../../libs/convertFirstCharToUpperCase'
import CopyFormReportModal from './CopyFormReportModal';
import { fetchFormDetails, getFormDetails, getFormsStatus, resetForm } from '../../../../../redux/slices/formSlice';

const FormReportsTable = ({ patientId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [copyFormId, setCopyFormId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [openAddModal, setOpenAddModal] = useState(false)
   const [openCopyModal, setOpenCopyModal] = useState(false)

   const patientFormReports = useSelector(getPatientForms)
   const form = useSelector(getFormDetails)
   const formStatus = useSelector(getFormsStatus)

   const onAddHandler = () => {
    setOpenAddModal(true)
  }
 
  const onDeleteHandler = (id) => {
    setDeleteItemId(id)
    setOpenDeleteModal(true)
  }

  const onCopyHandler = (id) => {
    // setCopyFormId(id)
    if (id) {
      dispatch(resetForm())
      dispatch(fetchFormDetails(id))
    }
    setOpenCopyModal(true)
  }


  const closeModal = (type) => {
    if (type === "add") {
      setOpenAddModal(false)
    } else if (type === "copy") {
      setOpenCopyModal(false)
      setCopyFormId(null)
    } else {
      setOpenDeleteModal(false)
      setDeleteItemId(null)
    }
  }
  
  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1.5,
      minWidth: 140,
      valueGetter: (params) => params.row?.title
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      minWidth: 160,
      valueGetter: (params) => convertFirstCharToUpperCase(params.row?.category)
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 100,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
      },       
    {
      field: 'updatedAt',
      headerName: 'Updated on',
      flex: 1,
      minWidth: 100,
      filterable: false,
      valueGetter: (params) => params.row.updatedAt?.toString().substring(0, 10)
      },  
      {
        field: 'status',
        headerName: 'Status',
        width: 140,
        filterable: false,
        renderCell: (params) => {
          let status, disabled=false
          if (params.row.status === "active") 
              status = "success"
          else 
              status = "disabled"
          return (
            <TableButton 
              variant="outlined" 
              size="small"
              type={status}
              disabled={disabled}
            >
                {params?.row?.status !== "active" ? params?.row?.status : "Complete"}
            </TableButton>
          )
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 80,
        type: 'actions',
        renderCell: (params) => (
          <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
              <TableIconButton type="copy" onClick={() => onCopyHandler(params.row._id)} disabled={formStatus === "loading"} />
              <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} disabled={formStatus === "loading"}/>
          </Box>
        ),
      }
    ],
    [rowId]
  )

  return (
    <>
     <Box px={1} pt={1}>
        <PageHeader position='flex-end'>
            <CustomButton title="Add Report" onClickHandler={onAddHandler} />
        </PageHeader>
      </Box>
      <Divider />
      {/* Reports table */}
      <Table 
          heightPercentage="80vh"
          columns={columns} 
          rows={patientFormReports}
          setRowId={setRowId}
          basePathForRedirect={"/doctor/form-templates"}
      />
      <AddFormReportModal 
        patientId={patientId}
        open={openAddModal}
        closeModal={() => closeModal("add")}
      /> 
      <DeleteFormReportModal
        patientId={patientId}
        formId={deleteItemId}
        open={openDeleteModal}
        closeModal={() => closeModal("delete")}
      />
      {form
        ? (
          <CopyFormReportModal
            form={form}
            key={form?._id}
            patientId={patientId}
            open={openCopyModal}
            closeModal={() => closeModal("copy")}
          />
        ) : null
      }

      <ToastContainer />
    </>
   
  )
}

export default FormReportsTable
