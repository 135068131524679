import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'


import { getFormDetails, updateForm } from '../../../../redux/slices/formSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';

const defaultMin = -99999
const defaultMax = 99999

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  label: Yup.string().required("Label is required."),
  type: Yup.string().required("Type is required."),
  min: Yup.number(),
  max: Yup.number(),
  defaultValue: Yup.string(),
  // maxLength: Yup.number(),
  // maxLength: Yup.number()
  //   .nullable()
  //   .test('isNumber', 'Max length must be a number', (value) => !isNaN(value))
  //   .test('isPositive', 'Max length must be a positive number and greater than 0', (value) => value > 0),
  startWithNewLine: Yup.boolean(),
  isRequired: Yup.boolean(),
})

const Text = ({ openModal, setOpenModal, data, updateTextField }) => {
    const theme = useTheme()
    // const [openModal, setOpenModal] = useState(false)

    const initialValues = {
        label: data?.title || '',
        type: data?.inputType || 'text',
        min: data?.min || "",
        max: data?.max || "",
        defaultValue: data?.defaultValue ||  "",
        startWithNewLine: data?.startWithNewLine ?? true,
        isRequired: data?.isRequired || false
      }

    const submitHandler = async (values, { resetForm }) => {
      updateTextField([{
        name: data?.name || uuidv4(),
        "type": "text",
        "title": values?.label,
        "inputType": values?.type,
        "min": values?.min || null,
        "max": values?.max || null,
        "defaultValue": values?.defaultValue,
        "startWithNewLine": values?.startWithNewLine,
        "isRequired": values?.isRequired,
      }])
      resetForm({ values: '' })
      setOpenModal(false)
    }

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    error={errors.label && touched.label}
                    helperText={touched.label && errors.label}
                  />
                </Grid>

                {/* Type */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Field
                        as={Select}
                        name="type"
                        variant="outlined"
                        fullWidth
                        error={errors.type && touched.type}
                        helperText={touched.type && errors.type}
                      // onChange={(e) => { onHospitalChange(e.target.value) }}
                      >
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="date">Date</MenuItem>
                      </Field>
                  </FormControl>
                </Grid>
                
               {/* Default Value */}
               <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="defaultValue"
                    label="Default Value"
                    variant="outlined"
                    fullWidth
                    error={errors.defaultValue && touched.defaultValue}
                    helperText={touched.defaultValue && errors.defaultValue}
                  />
                </Grid>
                
                {/* Show only if selected type is number */}
              {values?.type === "number" && (
                <>
                  {/* Min Value */}
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="min"
                      label="Min"
                      type="number"
                      variant="outlined"
                      fullWidth
                      error={errors.min && touched.min}
                      helperText={touched.min && errors.min}
                    />
                  </Grid>
                    
                  {/* Max Value */}
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="max"
                      label="Max"
                      type="number"
                      variant="outlined"
                      fullWidth
                      error={errors.max && touched.max}
                      helperText={touched.max && errors.max}
                    />
                  </Grid>
                </>
                )
              }

                {/* New line */}
               <Grid item xs={12} md={3}>
                <Field name="startWithNewLine">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox checked={values.startWithNewLine} {...field} />}
                          label="New Line"
                        />
                        {form.errors.startWithNewLine && form.touched.startWithNewLine && (
                          <FormHelperText error>{form.errors.startWithNewLine}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

                {/* Required */}
               <Grid item xs={12} md={3}>
                <Field name="isRequired">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox checked={values.isRequired} {...field} />}
                          label="Required"
                        />
                        {form.errors.isRequired && form.touched.isRequired && (
                          <FormHelperText error>{form.errors.isRequired}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

              </Grid>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Update"
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <CreateIcon color="custom.gray" />
              <Typography>Input</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Update Input Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default Text