import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useTheme } from "@mui/material";

const ResponsiveLineChart = ({ data }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer>
    <LineChart
      width={300}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: -14,
        bottom: 5
      }}
    >
      <CartesianGrid 
        horizontal={false}
      />
      <XAxis dataKey="name" />
      <YAxis dataKey="patients" name="Patients" allowDecimals={false} />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="patients"
        label="Patients"
        dot={{ stroke: theme.palette.primary.light, strokeWidth: 2 }}
        activeDot={{ r: 8 }}
      />
    </LineChart>
    </ResponsiveContainer>
  );
}

export default ResponsiveLineChart
