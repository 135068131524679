import React, { useEffect, useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

import CustomButton from '../../components/ui/CustomButton'
import BasicModal from '../../components/ui/modal/BasicModal'
import CustomSubmitButton from '../../components/ui/CustomSubmitButton'

import { HOSPITALS_URL } from '../../constants/apis'
import { updateUserClinic, fetchUserHospitalsAndClinics, getUserClinics, getUserHospitals, logoutUser, getUserActiveRole, updateUserActiveRole, getUserActiveClinic, getUserStatus } from '../../redux/slices/authSlice'
import { fetchUserClinics, selectUserClinics } from '../../redux/slices/userSlice'
import { useNavigate } from 'react-router-dom';
import { fetchPatients, getPatientsStatus } from '../../redux/slices/patientSlice';
import { fetchDoctors } from '../../redux/slices/doctorSlice';
import { doctorNavList } from '../../components/layout/dashboard/navList';
import ROLES from '../../constants/roles';
import BackdropLoader from '../../components/ui/BackdropLoader';

const initialValues = {
  // hospital: '',
  // clinic: '',

  // Dummy data
  hospital: '',
  clinic: '',
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  hospital: Yup.string().required('Hospital is required'),
  clinic: Yup.string().required('Clinic is required'),
})

const UpdateClinicModal = ({ open, closeModal }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [hospitalClinics, setHospitalClinics] = useState([])

    const userDetailsStatus = useSelector(getUserStatus)
    const userHospitals = useSelector(getUserHospitals)
    const userClinics = useSelector(getUserClinics)
    const userActiveRole = useSelector(getUserActiveRole)
    const userActiveClinic = useSelector(getUserActiveClinic)
    
  useEffect(() => {
    if(userHospitals.length === 0 || userClinics.length === 0) {
      dispatch(fetchUserHospitalsAndClinics())
    } 
  }, [])

  
  useEffect(() => {
    dispatch(fetchPatients())
    dispatch(fetchDoctors())
  }, [userActiveClinic, userActiveRole])

  const onHospitalChange = (hospital) => {
    const filteredClinics = userClinics?.filter((item) => item.clinic?.hospital?._id == hospital)
    setHospitalClinics((prev) => filteredClinics)
  }

    // const canDelete = [hospitalId].every(Boolean) && deleteRequestStatus === 'idle'

    const submitHandler = async (values, { resetForm }) => {
      try { 
        dispatch(updateUserClinic(values?.clinic))
        if (userActiveRole === ROLES.ADMIN) {
          dispatch(updateUserActiveRole(ROLES.DOCTOR))
          navigate(doctorNavList?.[0]?.path, { replace: true})
        }
        closeModal()
      } catch (err) {
        toast.error("Something went wrong!")
      } finally {
      }
    }

    const SelectClinicFormContent = () => (
        <Box>
          {userDetailsStatus === "loading" 
            ? <BackdropLoader />
            : null
          }
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ errors, setFieldValue, touched }) => (
              <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="hospital">Hospital</InputLabel>
                        <Field
                          as={Select}
                          input={<OutlinedInput label="Hospital" />} 
                          name="hospital"
                          variant="outlined"
                          fullWidth
                          error={
                            Boolean(touched.hospital && errors.hospital)
                         }
                        // onChange={(e) => { onHospitalChange(e.target.value) }}
                        >
                        {
                            userHospitals?.map((item) => (
                            <MenuItem
                              key={item?._id} 
                              value={item?.name}
                              onClick={() => {
                                onHospitalChange(item?._id)
                                setFieldValue("clinic", "")
                              }}
                            >
                                {item?.name}
                            </MenuItem>
                            ))
                        }
                        </Field>
                        {touched.hospital && errors.hospital ? (
                          <FormHelperText
                              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                          >
                              {touched.hospital && errors.hospital}
                          </FormHelperText>
                          ) : null
                        }
                        </FormControl>
                    </Grid>  
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="clinic">Clinic</InputLabel>
                        <Field
                          as={Select}
                          input={<OutlinedInput label="Clinic" />} 
                          name="clinic"
                          variant="outlined"
                          fullWidth
                          error={
                            Boolean(touched.clinic && errors.clinic)
                          }
                        >
                        {
                            hospitalClinics?.map((item) => (
                              <MenuItem key={item.clinic?._id} value={item.clinic?._id}>{item.clinic?.name}</MenuItem>
                            ))
                        }
                        </Field>
                        {touched.clinic && errors.clinic ? (
                          <FormHelperText
                              sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                          >
                              {touched.clinic && errors.clinic}
                          </FormHelperText>
                          ) : null
                        }
                      </FormControl>
                    </Grid>                                         
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" gap={2}>
                        <CustomButton
                          title="Cancel"
                          variant="outlined"
                          onClickHandler={closeModal}
                        />
                        <CustomSubmitButton 
                            title="Continue"
                            // disabled={userStatus === 'loading'}
                        />
                        </Box>
                    </Grid>
                </Grid>
              </Form>
            )}
          </Formik> 
        </Box>
      )


    return (
        <BasicModal
            title="Change Clinic" 
            open={open}
            onClose={closeModal}
            content={SelectClinicFormContent}
        />
    )
}

export default UpdateClinicModal