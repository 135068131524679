import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import BackdropLoader from '../../../components/ui/BackdropLoader'

import { UserProfileDetails, UserProfileOverview } from '../../../features/admin';
import { selectCoordinatorById } from '../../../redux/slices/coordinatorSlice';
import ROLES from '../../../constants/roles';

const ManageCoordinator = () => {
  const { coordinatorId } = useParams()
  
  const coordinator = useSelector((state) => selectCoordinatorById(state, coordinatorId))
  return (
    <Container sx={{ mt: 1 }} maxWidth="xl" >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <UserProfileOverview user={coordinator} type={ROLES.COORDINATOR} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <UserProfileDetails user={coordinator} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ManageCoordinator