import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 200 },
//   { name: "Group C", value: 300 }
// ];

const ResponsivePieChart = ({ data, COLORS }) => {
  return (
    <ResponsiveContainer>
    <PieChart >
      <Pie
        data={data}
        cx='50%'
        cy='55%'
        innerRadius={65}
        outerRadius={94}
        // fill="#8884d8"
        dataKey="value"
        label
      >
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]} />
        ))}
      </Pie>
    </PieChart>
    </ResponsiveContainer>
  );
}

export default ResponsivePieChart
