import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import { getToken } from "./authSlice"
import { ADMIN_URL } from "../../constants/apis"

const initialState = {
    statsData: {},
    statsDataStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    graphsData: {},
    graphsDataStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchAdminDashboardStatsData = createAsyncThunk('adminDashboard/fetchAdminDashboardStatsData', async (_, { getState }) => {
    const authToken = getToken(getState())
        
    const response = await axios.get(`${ADMIN_URL}/dashboard/stats`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        return response.data
})

export const fetchAdminDashboardGraphsData = createAsyncThunk('adminDashboard/fetchAdminDashboardGraphsData', async (values, { getState }) => {
    const authToken = getToken(getState())

    const { selectedYear } = values
        
    const response = await axios.get(`${ADMIN_URL}/dashboard/graphs-data?year=${selectedYear}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        return response.data
})

const adminDashboardSlice = createSlice({
    name: 'adminDashboardData',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Fetch Dashboard Stats
            .addCase(fetchAdminDashboardStatsData.pending, (state, action) => {
                state.statsDataStatus = 'loading'
                state.error = null
            })
            .addCase(fetchAdminDashboardStatsData.fulfilled, (state, action) => {
                state.statsDataStatus = 'succeeded'
                state.statsData = action.payload
            })
            .addCase(fetchAdminDashboardStatsData.rejected, (state, action) => {
                state.statsDataStatus = 'failed'
                state.error = action.error.message
            })
            // Fetch Dashboard Graphs Data
            .addCase(fetchAdminDashboardGraphsData.pending, (state, action) => {
                state.graphsDataStatus = 'loading'
                state.error = null
            })
            .addCase(fetchAdminDashboardGraphsData.fulfilled, (state, action) => {
                state.graphsDataStatus = 'succeeded'
                state.graphsData = action.payload
            })
            .addCase(fetchAdminDashboardGraphsData.rejected, (state, action) => {
                state.graphsDataStatus = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAdminDashboardStatsData = (state) => state.adminDashboardData.statsData
export const getStatusDataStatus = (state) => state.adminDashboardData.statsDataStatus

export const selectAdminDashboardGraphsData = (state) => state.adminDashboardData.graphsData
export const getGraphDataStatus = (state) => state.adminDashboardData.graphsDataStatus

export const getAdminDashboardDataError = (state) => state.adminDashboardData.error

export default adminDashboardSlice.reducer