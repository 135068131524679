import { Button } from '@mui/material';
import React from 'react'

const CustomSubmitButton = ({
  title, 
  variant="contained",
  color,
  disabled=false,
  ...props
}) => {
  return (
    <Button
      sx={{
        borderRadius: 2,
        padding: "8px 26px",
        fontWeight: 550,
        backgroundColor: color ? color : "",
        opacity: 0.94,
        '&:hover': {
          backgroundColor: color ? color : "",
          opacity: 1
        }
      }}
      type="submit"
      variant={variant}
      disabled={disabled}
      {...props}
    >
        {title}
    </Button>
  )
}

export default CustomSubmitButton