import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import ROLES from "../../constants/roles";
import { USERS_URL } from "../../constants/apis";

const initialState = {
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: JSON.parse(localStorage.getItem("token")) || null,
    activeClinic: JSON.parse(localStorage.getItem("clinic")) || null,
    activeRole: JSON.parse(localStorage.getItem("role")) || null,
    hospitals: [],
    clinics: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
}

export const loginUser = createAsyncThunk('users/loginUser', 
        async (userCredentials, { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${USERS_URL}/login`, userCredentials)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
})

export const fetchUserHospitalsAndClinics = createAsyncThunk('clinics/fetchUserHospitalsAndClinics', async (_, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${USERS_URL}/hospitals-and-clinics`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })

    return response.data
})


const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserClinic: (state, action) => {
            state.activeClinic = action.payload
            localStorage.setItem("clinic", JSON.stringify(action.payload))
        },
        updateUserActiveRole: (state, action) => {
            state.activeRole = action.payload
            localStorage.setItem("role", JSON.stringify(action.payload))
        },
        logoutUser: (state) => {
            state.user = null
            state.token = null
            state.activeClinic = null
            state.activeRole = null
            state.hospitals = []
            state.clinics = []
            localStorage.removeItem("user")
            localStorage.removeItem("token")
            localStorage.removeItem("clinic")
            localStorage.removeItem("role")
        },
    },
    extraReducers(builder) {
        builder
            // Login User 
            .addCase(loginUser.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.user = action.payload.user
                state.token = action.payload.token
                localStorage.setItem("user", JSON.stringify(action.payload.user))
                localStorage.setItem("token", JSON.stringify(action.payload.token))
                const isAdmin = action.payload.user?.roles?.includes(ROLES.ADMIN)
                const isCoordinator = action.payload.user?.roles?.includes(ROLES.COORDINATOR)
                const role = isAdmin 
                                ? ROLES.ADMIN
                                    : isCoordinator 
                                        ? ROLES.COORDINATOR
                                        : ROLES.DOCTOR 

                state.activeRole = role
                localStorage.setItem("role", JSON.stringify(role))
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload.message
            })
            // Hospital and Clinics
            .addCase(fetchUserHospitalsAndClinics.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchUserHospitalsAndClinics.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.hospitals = action.payload.hospitals
                state.clinics = action.payload.clinics
            })
            .addCase(fetchUserHospitalsAndClinics.rejected, (state, action) => {
                state.status = 'failed'
            })
    }
})

export const selectUser = (state) => state.user.user
export const getUserHospitals = (state) => state.user.hospitals
export const getUserClinics = (state) => state.user.clinics
export const getUserActiveClinic = (state) => state.user.activeClinic
export const getUserActiveRole = (state) => state.user.activeRole
export const getToken = (state) => state.user.token
export const getUserStatus = (state) => state.user.status
export const getUserError = (state) => state.user.error

export const selectActiveClinicById = (state, clinicId) =>
    state.user.clinics.find(clinic => clinic?.clinic?._id == clinicId)

export const { updateUserClinic, updateUserActiveRole, logoutUser, resetError } = authSlice.actions

export default authSlice.reducer