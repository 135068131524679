import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
// import 'react-toastify/dist/ReactToastify.css'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import PageHeader from '../../../components/ui/PageHeader'
import CustomSubmitButton from '../../../components/ui/CustomSubmitButton'

import ROLES from '../../../constants/roles'
import { getToken, selectUser } from '../../../redux/slices/authSlice'
import { fetchHospitals, selectAllHospitals } from '../../../redux/slices/hospitalSlice'
import { addClinic, fetchClinics, selectAllClinics } from '../../../redux/slices/clinicSlice'
import { fetchCoordinators } from '../../../redux/slices/coordinatorSlice'
import { cities } from '../../../constants/data';
import { USERS_URL } from '../../../constants/apis';

const initialValues = {
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  phone: '',
  street: '',
  city: 'Riyadh',
  hospital: '', 
  clinic: '',
  username: '',
  password: ''
}

const validationSchema = Yup.object().shape({
   // Define validation rules for each field
   firstName: Yup.string().required('First Name is required')
   .matches(/^[a-zA-Z\s]*$/, 'Invalid first name'),
 lastName: Yup.string().required('Last Name is required')
   .matches(/^[a-zA-Z\s]*$/, 'Invalid last name'),
 gender: Yup.string().required('Gender is required'),
 email: Yup.string().email('Invalid email address').required('Email is required'),
 phone: Yup.string()
 .matches(/^[\d+]+$/, 'Invalid phone number')
 .min(10, 'Invalid phone number')
 .max(10, 'Invalid phone number')
 .required('Phone is required'),
 street: Yup.string()
   .max(60, 'Invalid Address'),
 city: Yup.string().required('City is required'),
 hospital: Yup.string().required('Hospital is required'),
 clinic: Yup.string().required('Clinic is required'),
 username: Yup.string().required('Username is required'),
 password: Yup.string()
 .min(6, 'Must be at least 6 characters')
 .required('Password is required'),
})


const AddCoordinator = () => {
  const dispatch = useDispatch()

  const hospitals = useSelector(selectAllHospitals)
  const clinics = useSelector(selectAllClinics)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [hospitalClinics, setHospitalClinics] = useState([])
  const accessToken = useSelector(getToken)

  const onHospitalChange = (hospital) => {
    const filteredClinics = clinics?.filter((item) => item.hospital._id == hospital)
    setHospitalClinics((prev) => filteredClinics)
  }

  const submitHandler = async (values, { resetForm }) => {
    try {
      setAddRequestStatus("pending")
      const res = await axios.post(USERS_URL, {...values, role: ROLES.COORDINATOR }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      })
      if(res.status === 201) {
        dispatch(fetchClinics())
        dispatch(fetchCoordinators())
        toast.success("Coordinator added successfully.")
        resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      } 
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
    } finally {
      setAddRequestStatus("idle")
    }
  }

  useEffect(() => {
      dispatch(fetchHospitals())
      dispatch(fetchClinics())
  }, [])

  return (
    <Container sx={{ mt: 1 }} maxWidth="xl">
        <PageHeader>
            <PageHeading heading="Coordinator Registration" text="Coordinator registration form" />
        </PageHeader>
      <ContentContainer padding={4}>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, setFieldValue, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Gender" />} 
                  name="gender"
                  variant="outlined"
                  fullWidth
                  error={errors.gender && touched.gender}
                  helperText={touched.gender && errors.gender}
                >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                  
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{
                    maxLength: 30
                  }}
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="street"
                  label="Street Address"
                  variant="outlined"
                  inputProps={{
                    maxLength: 100
                  }}
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="City" />} 
                  name="city"
                  variant="outlined"
                  fullWidth
                  error={errors.city && touched.city}
                  helperText={touched.city && errors.city}
                >
                   {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>{city}</MenuItem>
                        ))}
                </Field>
                </FormControl>
              </Grid>    
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="hospital">Hospital</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Hospital" />} 
                  name="hospital"
                  variant="outlined"
                  fullWidth
                  error={errors.hospital && touched.hospital}
                  helperText={touched.hospital && errors.hospital}
                >
                  {
                    hospitals?.map((item) => (
                      <MenuItem
                       key={item._id} 
                       value={item._id}
                       onClick={() => { 
                        onHospitalChange(item._id)
                        setFieldValue("clinic", "")
                       }}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="clinic">Clinic</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Clinic" />} 
                  name="clinic"
                  variant="outlined"
                  fullWidth
                  error={errors.clinic && touched.clinic}
                  helperText={touched.clinic && errors.clinic}
                >
                  {
                    hospitalClinics?.length === 0
                     ? <MenuItem size="small">No clinic available.</MenuItem>
                     :
                        hospitalClinics?.map((item) => (
                          <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                        ))
                  }
                </Field>
                </FormControl>
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  error={errors.username && touched.username}
                  helperText={touched.username && errors.username}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                />
              </Grid> 
                         
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Add Coordinator"
                    disabled={addRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </Container>
  )
}

export default AddCoordinator