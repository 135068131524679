import React, { useEffect } from 'react'
import { Container, Grid } from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import BackDropLoader from '../../../components/ui/BackdropLoader'

import { PatientProfileDetails, PatientProfileOverview } from '../../../features/common'
import { 
  fetchPatientFormReports, fetchPatientSystemReports, fetchPatientPersonalReports, fetchPatients, 
  getPatientsStatus, selectAllPatients, selectPatientById, 
  resetPatientData
} from '../../../redux/slices/patientSlice'

const ManagePatient = () => {
  const dispatch = useDispatch()

  const { patientId } = useParams()

  const patient = useSelector((state) => selectPatientById(state, patientId))

  const patients = useSelector(selectAllPatients)
  const patientsStatus = useSelector(getPatientsStatus)

  useEffect(() => {
    dispatch(resetPatientData())

    if(patients?.length === 0) {
      dispatch(fetchPatients())
    }
    
      dispatch(fetchPatientFormReports(patientId))
      dispatch(fetchPatientSystemReports(patientId))
      dispatch(fetchPatientPersonalReports(patientId))
    // }
  }, [patientId])

  return (
    <>
      {
        (!patient) 
        ? <BackDropLoader />
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            <Grid container spacing={3}>
            <Grid item xs={12} lg={3.5}>
              <PatientProfileOverview patient={patient} />
            </Grid>
            <Grid item xs={12} lg={8.5}>
              <PatientProfileDetails patient={patient} />
            </Grid>
            </Grid>
          </Container>
          )
      }
    </>
  )
}

export default ManagePatient