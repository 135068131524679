import { useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import Layout from "./components/layout/Layout"
import ScrollToTop from "./components/layout/ScrollToTop"
import RequireAuth from "../src/features/auth/RequireAuth"
import ROLES from "../src/constants/roles"

// COMMON PUBLIC SCREENS
import UserLogin from './screens/common/UserLogin'
import Unauthorized from "./screens/common/Unauthorized"
import Error404 from "./screens/common/Error404"

// COMMON PRIVATE SCREENS
import AddPatient from "./screens/common/patients/AddPatient"
import PatientsList from "./screens/common/patients/PatientsList"
import ManagePatient from './screens/common/patients/ManagePatient'

//ADMIN SCREENS
import AdminLogin from "./screens/admin/AdminLogin"
import AdminDashboard from "./screens/admin/Dashboard"
import HospitalsList from './screens/admin/hospitals/HospitalsList'
import AddHospital from './screens/admin/hospitals/AddHospital'
import ManageHospital from './screens/admin/hospitals/ManageHospital'

import ClinicsList from './screens/admin/clinics/ClinicsList'
import AddClinic from './screens/admin/clinics/AddClinic'
import ManageClinic from './screens/admin/clinics/ManageClinic'

import DoctorsList from './screens/admin/doctors/DoctorsList'
import AddDoctor from './screens/admin/doctors/AddDoctor'
import ManageDoctor from './screens/admin/doctors/ManageDoctor'

import CoordinatorsList from './screens/admin/coordinators/CoordinatorsList'
import AddCoordinator from './screens/admin/coordinators/AddCoordinator'
import ManageCoordinator from './screens/admin/coordinators/ManageCoordinator'

import TemplatesList from './screens/common/forms/TemplatesList'
import Designer from './screens/common/forms/Designer'
import Preview from './screens/common/forms/Preview'

import SubformTemplatesList from './screens/common/subforms/SubformTemplates';


//Doctor SCREENS
import DoctorDashboard from './screens/doctor/Dashboard'
import FormView from './screens/doctor/View'

// COORDINATOR SCREENS
import ClinicDoctorsList from './screens/coordinator/doctors/DoctorsList'
import DoctorProfile from './screens/coordinator/doctors/DoctorProfile'

import ListsList from './screens/admin/lists/ListsList';
import AddList from './screens/admin/lists/AddList';
import EditList from './screens/admin/lists/EditList';
import SubformDesigner from './screens/common/subforms/Designer';

function App() {
  // const location = useLocation()
  const [mode, setMode] = useState("light")
  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        light: '#59B29F',
        main: '#2B7F75',
      },
      secondary: {
        light: '#6C7278',
        main: '#1C2634',
        dark: "#1A1C1E"
      },
      custom: {
        lightBlue:{
          main: '#7EC2FF'
        },
        blue: {
          light: "#54A6FF"
        },
        red: {
          light: "#CE2C60"
        },
        white: {
          main: "#FFF"
        },
        ashWhite: {
          light: "#F9FAFB",
          main: "#F1F6EE",
          dark: "#DCE4E8",
        },
        yellow: {
          main: '#FFD66B'
        },
        gray: {
          light: '#E4E9F1',
          main: '#ACB5BB'
        },
      },
      // Typography colors
      text: {
        disabled: "#A8A8A8"
      }
    },
    typography: {
      fontFamily: ["Manrope", "cursive"].join(","),
      textTransform: "capitalize",
      disabled: {
        color: "#000" 
      },
      button: {
        textTransform: "none",
        fontWeight: 'bold',
        fontSize: "16px",
        letterSpacing: 0
      }     
    } 
  })


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}

              <Route index element={<UserLogin />} />  

              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="admin/login" element={<AdminLogin />} />

              {/* Protected Routes */}

              {/* Admin Routes */}
              <Route path="admin" element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                
                <Route path="hospitals">
                  <Route index element={<HospitalsList />} />
                  <Route path="registration" element={<AddHospital />} />
                  <Route path="profile/:hospitalId" element={<ManageHospital />} />
                </Route>

                <Route path="clinics">
                  <Route index element={<ClinicsList />} />
                  <Route path="registration" element={<AddClinic />} />
                  <Route path="profile/:clinicId" element={<ManageClinic />} />
                </Route>
               
                <Route path="doctors">
                  <Route index element={<DoctorsList />} />
                  <Route path="registration" element={<AddDoctor />} />
                  <Route path="profile/:doctorId" element={<ManageDoctor />} />
                </Route>

                <Route path="coordinators">
                  <Route index element={<CoordinatorsList />} />
                  <Route path="registration" element={<AddCoordinator />} />
                  <Route path="profile/:coordinatorId" element={<ManageCoordinator />} />
                </Route>

                <Route path="form-templates">
                  <Route index element={<TemplatesList />} /> 
                  <Route path="design/:id" element={<Designer />} /> 
                  <Route path="preview/:id" element={<Preview />} />

                  <Route path="subforms">
                    <Route index element={<SubformTemplatesList />} />
                    <Route path="design/:id" element={<SubformDesigner />} /> 
                  </Route> 
                </Route> 

                <Route path="lists">
                  <Route index element={<ListsList />} /> 
                  <Route path="add-list" element={<AddList />} /> 
                  <Route path="edit-list/:id" element={<EditList />} /> 
                </Route>

              </Route>

              {/* Doctor Routes */}
              <Route path="doctor" element={<RequireAuth allowedRoles={[ROLES.DOCTOR]} />}>
                <Route path="dashboard" element={<DoctorDashboard />} />

                <Route path="form-templates">
                  <Route path=":id" element={<FormView />} /> 
                </Route>
                
              </Route>

              {/* Coordinator Routes  */}
              <Route path="coordinator" element={<RequireAuth allowedRoles={[ROLES.COORDINATOR]} />}>
                
                <Route path="doctors">
                  <Route index element={<ClinicDoctorsList />} />
                  <Route path="profile/:doctorId" element={<DoctorProfile />} />
                </Route>

              </Route>
              
              {/* ADMIN + DOCTOR ROUTES */}
              <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.DOCTOR]} />}>

              </Route>

                {/* ADMIN + DOCTOR + COORDINATOR ROUTES  */}
              <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.DOCTOR, ROLES.COORDINATOR]} />}>
               
                <Route path="patients">
                  <Route index element={<PatientsList />} />
                  <Route path="registration" element={<AddPatient />} />
                  <Route path="profile/:patientId" element={<ManagePatient />} />
                </Route>  

              </Route>

              {/* catch all */}
              <Route path="*"element={<Error404 />} />
            </Route>
          </Routes>
        </ScrollToTop>
    </ThemeProvider>
  )
}

export default App
