import React, { useEffect, useState } from 'react'
import ArticleIcon from '@mui/icons-material/Article';
import AddIcon from '@mui/icons-material/Add'
import { 
  Autocomplete,
    Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, 
    OutlinedInput, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import {  getAllSubforms, getFormsError, getFormsStatus } from '../../../../redux/slices/formSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { FORM_TEMPLATES_URL } from '../../../../constants/apis';
import { getToken } from '../../../../redux/slices/authSlice';

const initialValues = {
  title: '',
  type: 'subform',
  subform: ''
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  title: Yup.string().required("Title is required."),
  type: Yup.string().required("Type is required."),
  subform: Yup.string()
})

const Page = ({ addNewPageToForm }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [activeSubforms, setActiveSubforms] = useState([])
    const [fetchFormRequest, setFetchFormRequest] = useState('idle')
    const [subformDetails, setSubformDetails] = useState(null)

    const accessToken = useSelector(getToken)

    const subforms = useSelector(getAllSubforms)
    const subformsStatus = useSelector(getFormsStatus)
    const subformsError = useSelector(getFormsError)
 
    useEffect(() => {
      if(subforms?.length > 0) {
        const filteredActiveSubforms = subforms?.filter((item) => item.status === "active")
        setActiveSubforms(filteredActiveSubforms)
      }
    }, [subforms])

    const fetchSubformDetails = async (id) => {
      try{
        setFetchFormRequest("pending")
          const res = await axios.get(`${FORM_TEMPLATES_URL}/details/${id}`, {
              headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`
              },
          }) 
          if(res.status === 200) {
            setSubformDetails(res?.data)
          } else {
              toast.error("Something went wrong, please try again later.")
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setFetchFormRequest('idle')
        }
    }
    
    const submitHandler = (values, { resetForm }) => {
      addNewPageToForm(values?.title, values?.type, subformDetails?.pages[0]?.elements)
      resetForm({ values: '' })
      setOpenModal(false)
  };

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap={2}>
                {/* Type */}
                <FormControl fullWidth>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Type" />} 
                  name="type"
                  variant="outlined"
                  fullWidth
                  error={
                    Boolean(touched.type && errors.type)
                 }
                >
                  <MenuItem value="subform">Subform</MenuItem>
                  <MenuItem value="new">New</MenuItem>
                </Field>
                  {touched.type && errors.type ? (
                  <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                      {touched.type && errors.type}
                  </FormHelperText>
                  ) : null}
                </FormControl>

                {values?.type === "new"
                  ? (
                       <Field
                          as={TextField}
                          name="title"
                          label="Title"
                          variant="outlined"
                          fullWidth
                          error={errors.title && touched.title}
                          helperText={touched.title && errors.title}
                        />
                  ) : (    
                      <Autocomplete
                            name="subform"
                            options={activeSubforms}
                            getOptionLabel={(item) => item?.title}
                            onChange={(event, item) => {
                              setFieldValue("title", item?.title)
                              setFieldValue("subform", item?._id)
                              fetchSubformDetails(item?._id)
                            }}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Subform Template"
                              variant="outlined"
                              error={errors.subform && touched.subform}
                              helperText={touched.subform && errors.subform}
                            />
                          )}
                        />
                  )
              }
              </Box>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Add"
                    disabled={fetchFormRequest === "pending"}
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <ArticleIcon color="custom.gray" />
              <Typography>Add Page</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <ToastContainer />
      <BasicModal 
          title="Add New Page"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default Page