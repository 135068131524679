import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"

import Footer from "./Footer"
import SideNavbar from "./dashboard/Sidebar"
import Navbar from "./dashboard/Navbar"
import { useDispatch, useSelector } from "react-redux";
import { getUserActiveRole, logoutUser, selectUser, updateUserActiveRole } from "../../redux/slices/authSlice";
import ROLES from "../../constants/roles";
import { adminNavList, coordinatorNavList, doctorNavList } from "./dashboard/navList";

const StyledContentContainer = styled(Box)(({ bgColor }) => ({
    minHeight: "100vh",
    background: bgColor,
    justifyContent: "space-between",
    // alignContent: "center",
    overflow: 'hidden'
}))

const StyledContentBox = styled(Box)(({ theme, open }) => ({
    // maxWidth: open ? "85vw" : "95.5vw",
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    }),
    maxWidth: "100vw",
    [theme.breakpoints.down('md')] : {
        padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')] : {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')] : {
        padding: theme.spacing(3),
    },
}))


const Layout = () => {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [sidebarMenu, setSidebarMenu] = useState([])
    const [activeLink, setActiveLink] = useState("")
    const isDesktop = useMediaQuery('(min-width:600px)')

    const [open, setOpen] = useState(true)
    const user = useSelector(selectUser) 
    const userActiveRole = useSelector(getUserActiveRole) 

    useEffect(() => {
      if(user && userActiveRole === ROLES.DOCTOR && user?.roles?.includes(ROLES.ADMIN)) {
        dispatch(updateUserActiveRole(ROLES.ADMIN))
        navigate(adminNavList[0]?.path)
      }
    }, [])

    useEffect(() => {
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };
    
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        const decodedJwt = parseJwt(token);
  
        if (decodedJwt.exp * 1000 < Date.now()) {
          dispatch(logoutUser())
        }
      }
    }, [location])

    useEffect(() => {
      setSidebarMenu([])
      const getRoleBasedNavbar = () => {
        if(userActiveRole === ROLES.ADMIN) {
          setSidebarMenu(adminNavList)
          setActiveLink(adminNavList[0].path)
          navigate(adminNavList[0].path)
          return 
        } else if (userActiveRole === ROLES.DOCTOR) {
          setSidebarMenu(doctorNavList)
          setActiveLink(doctorNavList[0].path)
          navigate(doctorNavList[0].path)
          return 
        } else {
          setSidebarMenu(coordinatorNavList)
          setActiveLink(coordinatorNavList[0].path)
          navigate(coordinatorNavList[0].path)          
          return
        }
      }

      if(user) {
        getRoleBasedNavbar()
      }
    }, [user, userActiveRole])

    const handleClick = (path) => {
        if (!isDesktop)
          setOpen(prev => !prev) //for mobile 
        // setTimeout(() => {
        //   refFocus.current.focus()
        // }, 500);
        const updatedMenu = sidebarMenu.map((item) => {
          if(item.path == path) {
            return {...item, isActive: true}
          } else if (item.path == activeLink) {
            return {...item, isActive: false}
          } else {
            return item
          }
        })
        navigate(path)
        setSidebarMenu(updatedMenu)
        setActiveLink(path)
        return
      }

      const handleLogout = () => {
        const isAdmin = user?.roles?.includes(ROLES.ADMIN)
        dispatch(logoutUser())

        //Role based logout
        navigate(isAdmin ? "/admin/login" : "/", {replace: true})
    }

    
    return (
        <>
            {
                user ? (
                    <Stack direction="row" justifyContent="space-between">
                        <SideNavbar
                            open={open}
                            setOpen={setOpen}
                            sidebarMenu={sidebarMenu}
                            handleClick={handleClick}
                            handleLogout={handleLogout}
                        />
                        <StyledContentContainer 
                            flex={2}
                            bgColor={location.pathname == "/" ? "" : theme.palette.custom.ashWhite.light}
                        > 
                            <Navbar 
                                open={open} 
                                setOpen={setOpen} 
                                handleClick={handleClick}
                                handleLogout={handleLogout}
                            />
                            <StyledContentBox open={open}>
                                <Outlet />
                            </StyledContentBox>
                        </StyledContentContainer>
                    </Stack>
                ): (
                  <>
                        <Outlet />
                        <Footer />
                    </>
                )
            }
        </>
    )
}

export default Layout