import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { ReportButton } from '../..'
import { fetchPatientPersonalReports, getPatientPersonalReports, getPatientSystemReports, getPatientsStatus } from '../../../../redux/slices/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../../components/ui/Loader';
import PageHeader from '../../../../components/ui/PageHeader';
import CustomButton from '../../../../components/ui/CustomButton';
import UploadReport from '../../upload/UploadReport';
import { getToken, getUserActiveRole } from '../../../../redux/slices/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UPLOADS_URL } from '../../../../constants/apis';
import ROLES from '../../../../constants/roles';

const PersonalReports = ({ patientId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [uploading, setUploading] = useState("idle")
  const [report, setReport] = useState(null)

  const [fileContent, setFileContent] = useState(null);
  const [mimeType, setMimeType] = useState('');

  const patientPersonalReports = useSelector(getPatientPersonalReports)
  const patientPersonalReportsStatus = useSelector(getPatientsStatus)

  const userActiveRole = useSelector(getUserActiveRole) 
  const accessToken = useSelector(getToken) 

  useEffect(() => {
    if(report && patientId) {
      uploadReportHandler()
    }
  }, [report])

    const uploadReportHandler = async () => {
        const formData = new FormData()
        formData.append('patientId', patientId)
        formData.append('file', report)
        const config = {
          headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`
          }
        }
        try {
          setUploading("pending")
          const res = await axios.post(`${UPLOADS_URL}/patient/reports/personal`, formData, config)
          if(res.status === 200) {
            setReport(null)
            toast.success(res?.data?.message)
            setTimeout(() => dispatch(fetchPatientPersonalReports(patientId), 2000))
          } else {
            toast.error("Something went wrong, please try again later.")
            }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setUploading("failed")
        }
    }

    const handleFileClick = async (file) => {
      try {
        const response = await axios.get("/"+file?.urlPath, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
          responseType: 'blob',
        });
  
        const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
        const fileURL = URL.createObjectURL(fileBlob);
  
        // Open the file in a new window
        const newWindow = window.open(fileURL);
        if (newWindow) {
          newWindow.onload = () => {
            URL.revokeObjectURL(fileURL); // Revoke the object URL after the window loads
          };
        }
      } catch (error) {
        navigate("/")
      }
    };

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
        {userActiveRole !== ROLES.ADMIN
          ?
          <Box px={1}>
              <PageHeader position='flex-end' mb={0}>
                  <UploadReport 
                    title={uploading === "pending" ? "Uploading" : "Upload Report"}
                    disabled={uploading === "pending"}
                    onReportChange={setReport}
                  />
              </PageHeader>
            </Box>
          : null
        }
        {patientPersonalReportsStatus === "loading" 
          ? <Loader />
          :
            patientPersonalReports?.length === 0 
              ? <Typography variant="display2" color="text.disabled" textAlign="center">No reports available</Typography>
              : 
              <Grid container spacing={2}>
                {patientPersonalReports?.map((report) => (
                  <Grid item xs={12} md={6}>
                    <button onClick={() => handleFileClick(report)}>
                      <ReportButton title={report?.name} mimeType={report?.mimeType} />
                    </button>
                  </Grid>
                ))}
              </Grid>
            }
          <ToastContainer />
      </Box>
  )
}

export default PersonalReports
