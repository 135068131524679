import React, { useState } from 'react';
import { Box, Typography, Switch, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'

import UpdateClinicModal from '../../common/UpdateClinicModal'

import { adminNavList } from "../../../components/layout/dashboard/navList"
import { getUserActiveRole, updateUserActiveRole } from '../../../redux/slices/authSlice';
import { fetchPatients } from '../../../redux/slices/patientSlice'
import ROLES from '../../../constants/roles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));



const Toggle = ({ sidebarOpen }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const userActiveRole = useSelector(getUserActiveRole)

  const onUserRoleChange = (e) => {
    if(userActiveRole === ROLES.ADMIN) {
      setOpenModal(true)
    } else {
      dispatch(updateUserActiveRole(ROLES.ADMIN))
      // dispatch(fetchAllPatients())
      dispatch(fetchPatients())
      navigate(adminNavList?.[0].path, { replace: true })
    }
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        sx={{
          display: sidebarOpen ? "" : "none",
          color: theme.palette.primary.main,
        }}
        variant="subtitle1"
      >
        Continue as Doctor
      </Typography>
      <IOSSwitch
        sx={{ m: 1 }}
        checked={userActiveRole === ROLES.DOCTOR}
        onChange={onUserRoleChange}
      />
         
      <UpdateClinicModal 
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Box>
  )
}

export default Toggle;

  