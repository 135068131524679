export const ADMIN_URL = '/api/admin'
export const USERS_URL = '/api/users'
export const HOSPITALS_URL = '/api/hospitals'
export const CLINICS_URL = '/api/clinics'
export const DOCTORS_URL = '/api/doctors'
export const COORDINATORS_URL = '/api/coordinators'
export const PATIENTS_URL = '/api/patients'
export const FORM_TEMPLATES_URL = '/api/forms'
export const SUBFORM_TEMPLATES_URL = '/api/forms/subforms'
export const LISTS_URL = '/api/lists'
export const UPLOADS_URL = '/api/upload'
