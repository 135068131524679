import * as React from 'react'
import { Badge, Avatar, Stack, useTheme, IconButton, CircularProgress } from '@mui/material'
import CameraAlt from '@mui/icons-material/CameraAlt'
import { useEffect } from 'react';


const BadgeAvatar = ({ name, imageUrl, onImageUpload, loading }) => {
  const theme = useTheme()
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    onImageUpload(file)
  };

  return (
    <Stack direction="row" spacing={2}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <IconButton onClick={handleButtonClick} disabled={loading}>
            <>
            {loading
              ? 
                <CircularProgress 
                  sx={{
                    color: theme.palette.custom.blue.light,
                    fontSize: 14
                  }}
                /> 
              :
                <CameraAlt 
                  sx={{
                    color: theme.palette.custom.blue.light,
                    fontSize: 28
                  }}
                />
            }
            </>
          </IconButton>
        }
      >
        <Avatar 
          sx={{ width: 140, height: 140 }} 
          alt={name} 
          src={imageUrl}
        />
      </Badge>
    </Stack>
  )
}

export default BadgeAvatar