import React, { useState } from 'react'
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Box, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

import { getToken } from '../../../../redux/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { fetchClinics } from '../../../../redux/slices/clinicSlice'
import ROLES from '../../../../constants/roles'
import { fetchDoctors, selectDoctorById } from '../../../../redux/slices/doctorSlice'
import { USERS_URL } from '../../../../constants/apis'
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton'
import { fetchCoordinators } from '../../../../redux/slices/coordinatorSlice';
import { fetchAdminDashboardStatsData } from '../../../../redux/slices/adminDashboardSlice';
import { cities } from '../../../../constants/data';

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  firstName: Yup.string().required('First Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid first name'),
  lastName: Yup.string().required('Last Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid last name'),
  gender: Yup.string().required('Gender is required'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number')
    .required('Phone is required'),
  street: Yup.string()
    .max(100, 'Max 100 characters are allowed'),
  city: Yup.string().required('City is required'),
  otherCity: Yup.string()
  .when("city", {
    is: (val) => val.includes("Other"),
    then: () => Yup.string()
                .matches(/^[a-zA-Z\s]*$/, 'Invalid city name')
                .required("City is required")
  }),
  credentials: Yup.string()
  .matches(/^[a-zA-Z:.();\s]*$/, 'Invalid credentials'),
  username: Yup.string().required('Username is required'),
  password: Yup.string()
  .min(6, 'Must be at least 6 characters long')
})


const UpdateProfileDetails = ({ user }) => {
  const dispatch = useDispatch()
    const token = useSelector(getToken)
    const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
    
    const initialValues = {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      gender: user?.gender || '',
      email: user?.emailDetails?.email || '',
      phone: user?.phone || '',
      street: user?.address?.street || '',
      city: cities?.includes(user?.address?.city) ? user?.address?.city : "Other",
      otherCity: !cities?.includes(user?.address?.city) ? user?.address?.city : "",
      credentials: user?.credentials || '',
      username: user?.username || '',
      password: '',
      status: user?.status || ''
    }

const submitHandler = async (values, { resetForm }) => {
    try {
      setUpdateRequestStatus("pending")
      const res = await axios.patch(`${USERS_URL}/${user?._id}`, {...values }, {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
      })
      if(res.status === 200) {
        dispatch(fetchClinics())
        dispatch(fetchAdminDashboardStatsData())
        // Is Doctor
        if (user?.roles.includes(ROLES.DOCTOR)) {
          toast.success("Doctor updated successfully.")
          dispatch(fetchDoctors())
        } else {
          toast.success("Coordinator updated successfully.")
          dispatch(fetchCoordinators())
        }
        // resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      } 
    } catch (err) {
      toast.error(err.response.data.message)
    } finally {
      setUpdateRequestStatus("idle")
    }
  }

  return (
    <Box px={2}>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, errors, setFieldValue, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="Fisrt Name"
                  variant="outlined"
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Gender" />} 
                  name="gender"
                  variant="outlined"
                  fullWidth
                  error={errors.gender && touched.gender}
                  helperText={touched.gender && errors.gender}
                >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                  
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="street"
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="City" />} 
                  name="city"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("city", e.target.value)
                    setFieldValue("otherCity", "")
                  }}
                  error={errors.city && touched.city}
                  helperText={touched.city && errors.city}
                >
                  {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>{city}</MenuItem>
                        ))}
                </Field>
                </FormControl>
              </Grid>  
              {values?.city === "Other"
                ? (
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="Other City"
                      variant="outlined"
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid> 
                ) : null
              }    
              {user?.roles?.includes(ROLES.DOCTOR) 
              ? (
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="credentials"
                      label="Credentials"
                      placeholder="M.D.: Doctor of medicine"
                      variant="outlined"
                      fullWidth
                      error={errors.credentials && touched.credentials}
                      helperText={touched.credentials && errors.credentials}
                    />
                  </Grid> 
                ) : null
              }
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="username"
                  label="Username"
                  variant="outlined"
                  disabled
                  fullWidth
                  error={errors.username && touched.username}
                  helperText={touched.username && errors.username}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Status" />} 
                  name="status"
                  variant="outlined"
                  fullWidth
                  error={errors.status && touched.status}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Field>
                </FormControl>
              </Grid> 
                         
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Update"
                    disabled={updateRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  )
}

export default UpdateProfileDetails