import React, { useEffect, useRef, useState } from 'react'
import { Box, Avatar} from '@mui/material'
import CustomButton from '../../../components/ui/CustomButton'

const UploadReport = ({ title="Upload Picture", disabled, onReportChange }) => {
  const fileInputRef = useRef(null)

  const handleButtonClick = () => {
    fileInputRef.current.click();
  }


  const uploadFileHandler = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    // Display the selected file as an Avatar
     if (file) {
      onReportChange(file)
      fileInputRef.current.value = "";
      // const reader = new FileReader()
      // reader.onload = () => {
      //   setSelectedFile(reader.result)
      // }
      // reader.readAsDataURL(file)
    }
  }
  
  return (
    <Box display="flex" alignItems="center" gap={2} mb={4}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="application/pdf, image/png, image/jpeg"
        onChange={uploadFileHandler}
      />
      <CustomButton 
        title={title}
        onClickHandler={handleButtonClick}
        disabled={disabled}
      />
    </Box>
  )
}

export default UploadReport