import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getToken } from "./authSlice";
import { COORDINATORS_URL } from "../../constants/apis";

const initialState = {
    coordinators: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchCoordinators = createAsyncThunk('coordinators/fetchCoordinators', async (_, { getState }) => {
    const authToken = getToken(getState())
        
    const response = await axios.get(COORDINATORS_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
})

const coordinatorSlice = createSlice({
    name: 'coordinators',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Fetch Coo 
            .addCase(fetchCoordinators.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchCoordinators.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.coordinators = action.payload
            })
            .addCase(fetchCoordinators.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllCoordinators = (state) => state.coordinators.coordinators
export const getCoordinatorsStatus = (state) => state.coordinators.status
export const getCoordinatorsError = (state) => state.coordinators.error

export const selectCoordinatorById = (state, coordinatorId) =>
    state.coordinators.coordinators.find(coordinator => coordinator._id == coordinatorId)

export const { } = coordinatorSlice.actions

export default coordinatorSlice.reducer