import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'survey-core/defaultV2.min.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { v4 as uuidv4 } from 'uuid'


import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'

import { FieldsList } from '../../../features/forms/newForm'
import { EditText, EditTextArea, EditRadioGroup, EditSingleSelect, EditMultiSelect, EditDynamicSelect, EditSentence } from '../../../features/forms/editForm'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormDetails, fetchFormTemplates, getFormDetails, getFormsStatus, updateForm } from '../../../redux/slices/formSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { FORM_TEMPLATES_URL } from '../../../constants/apis';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../../redux/slices/authSlice';
import axios from 'axios';
import CustomButton from '../../../components/ui/CustomButton';
import { fetchLists, getAllLists, getListsError, getListsStatus } from '../../../redux/slices/listSlice';


function deleteQuestion(element) {
    element.parent.removeQuestion(element);
  }

  function deletePanel(element) {
    element.parent.removeElement(element);
  }

const Designer = () => {
    const theme = useTheme();
    const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const { id } = useParams()
    const dispatch = useDispatch()

    const [survey, setSurvey] = React.useState(new Model([]));
    const [pageNo, setPageNo] = React.useState(survey?.currentPageNo || 0);
    const [isRunning, setIsRunning] = React.useState(true);

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const [sentenceAdded, setSentenceAdded] = useState(false)
    const [currentPanelNo, setCurrentPanelNo] = useState(0)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editFieldData, setEditFieldData] = useState(null)

    let form = useSelector(getFormDetails)
    const formStatus = useSelector(getFormsStatus) 
      
    const accessToken = useSelector(getToken)

    const lists = useSelector(getAllLists)
    const listsStatus = useSelector(getListsStatus)

    useEffect(() => {
        if (lists?.length === 0 && listsStatus !== "loading")
            dispatch(fetchLists())
    }, [])

    useEffect(() => {
        if(form?._id) {
           setSurvey(new Model(form))
        }
    }, [form])

    useEffect(() => {
        if(survey) {
            updateCurrentPanelNo()
        }
    }, [survey?.currentPage])

    useEffect(() => {
        if(form?._id != id) {
            dispatch(fetchFormDetails(id))
        }
    }, [id])
   
    const updateCurrentPanelNo = () => {
        // Check if last element is sentence
        if(form?._id) {
            const currentPanelLen = form?.pages[survey?.currentPageNo]?.elements?.length
            if(currentPanelLen && currentPanelLen > -1) {
                const lastElementOfPanel = form?.pages[survey.currentPageNo]?.elements?.at(-1)
                if (lastElementOfPanel?.questionTitleLocation === "hidden") {
                    setCurrentPanelNo(form?.pages[survey.currentPageNo]?.elements?.length)
                } else {
                    setCurrentPanelNo(form?.pages[survey.currentPageNo]?.elements?.length - 1)
                }
            }
        }
    }

    const handleAddField = (fieldsData) => {
        let newForm = JSON.parse(JSON.stringify(form));
  
        fieldsData?.map((field) => {
                if (newForm?.pages[survey.currentPageNo]?.elements[0]?.elements[0]?.type === "html") {
                    newForm?.pages[survey.currentPageNo]?.elements[0]?.elements?.shift()
                }
                if (sentenceAdded || !newForm?.pages[survey.currentPageNo]?.elements[currentPanelNo]) {
                    newForm?.pages[survey.currentPageNo]?.elements?.push(
                                            {
                                                "type": "panel",
                                                "questionTitleLocation": "top",
                                                "elements": [
                                                    {
                                                        ...field
                                                    }
                                                ]
                                            }
                                )
                    setCurrentPanelNo(newForm?.pages[survey.currentPageNo]?.elements?.length - 1)

                } else {
                    newForm?.pages[survey.currentPageNo]?.elements[currentPanelNo]?.elements?.push(
                        {
                            ...field
                        }
                    )
                }
            // }
        })
        dispatch(updateForm(newForm))
        setSurvey(new Model(newForm))
        setSentenceAdded(false)
    }

    const handleAddSentenceField = (fieldsData) => {
        let newForm = JSON.parse(JSON.stringify(form));
        if (newForm?.pages[survey.currentPageNo]?.elements[0]?.elements[0]?.type === "html") {
            newForm?.pages[survey.currentPageNo]?.elements[0]?.elements?.shift()
        }
        newForm?.pages[survey.currentPageNo]?.elements.push(fieldsData)
        setSentenceAdded(true)
        dispatch(updateForm(newForm))
        setSurvey(new Model(newForm))
    }

    const handleUpdateField = (fieldsData) => {
        let question = null
        fieldsData?.map((field) => {
            question = survey.getQuestionByName(field?.name)
            if (question) {
                // Common
                question.type = field?.type ?? question.type
                question.title = field?.title?.trim() ?? question?.title
                question.defaultValue =  field?.defaultValue?.trim() ?? question?.defaultValue
                question.startWithNewLine =  field?.startWithNewLine ?? field?.startWithNewLine
                question.isRequired =  field?.isRequired ?? field?.isRequired
                
                // Text
                if(question?.type === "text") {
                    question.inputType =  field?.inputType ?? question?.inputType
                    question.min =  field?.min ?? question.min
                    question.max =  field?.max ?? question.max
                }
                // TextArea
                if(question?.type === "comment") {
                    question.rows = field?.rows ?? question?.rows
                }

                // Radio Group, Select
                if(question?.type === "radiogroup" || question?.type === "dropdown" || question?.type === "tagbox") {
                    question.choices = field?.choices ?? []
                }

                // Select, Multi-Select
                if(question?.type === "dropdown" || question?.type === "tagbox") {
                    question.showNoneItem = field?.showNoneItem ?? question.showNoneItem
                    question.showOtherItem = field?.showOtherItem ?? question.showOtherItem
                }

                // Dynamic Select
                if(question?.type === "matrixdynamic") {
                    question.columns[0] = field?.columns[0] ?? question.columns[0]
                }
            }
        })
        let formJson = JSON.stringify(survey)
        setSurvey(new Model(formJson))
        formJson = JSON.parse(formJson)
        dispatch(updateForm(formJson))

    }

    const handleUpdateSentence = (panelName, fieldsData) => {
        let newForm = JSON.parse(JSON.stringify(form));
        const currentPageElements = newForm.pages[survey.currentPageNo]?.elements
        if(currentPageElements.length > 0) {
            currentPageElements?.map((panel) => {
                if (panel?.name && (panel.name == panelName)) {
                    panel.elements = fieldsData
                }
            })
            dispatch(updateForm(newForm))
            setSurvey(new Model(newForm))
        }
    }
    
    const addPageHandler = (title, type, elements) => {
        console.log("AddPageHandler: ")
        console.log({title, type, elements})
        let newForm = JSON.parse(JSON.stringify(form));

        if (type === "new") {
            newForm.pages.push(
                {
                    "name": uuidv4(),
                    "title": title,
                    "elements": [
                        {
                            "type": "panel",
                            "questionTitleLocation": "top",
                            "elements": [
                                {
                                    "name": uuidv4(),
                                    "type": "html",
                                    "html": "Please add components to get started.",
                                    "startWithNewLine": false,
                                  }
                            ]
                        }
                    ]
                }
            )
        } else {
            newForm.pages.push(
                {
                    "name": uuidv4(),
                    "title": title,
                    "elements": elements
                }
            )
        }
        toast.success("New page added to form.")
        dispatch(updateForm(newForm))
        setSurvey(new Model(newForm))

        // setPageNo((prev) => prev+1)
        // survey.nextPage()
    };

    const editQuestion = (element) => {
        setOpenEditModal(true)
        setEditFieldData(element?.jsonObj)
    }

    const submitHandler = async (status="draft") => {
       
        // Log the updated question to the console
        const pages = survey.toJSON()?.pages
        
        try {
        setAddRequestStatus('pending')
        const res = await axios.patch(`${FORM_TEMPLATES_URL}/update-form`, 
            {
                id,
                pages,
                status
            }, {
            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
            },
        }) 
        if(res.status === 200) {
            toast.success("Form updated successfully.")
            setTimeout(() => {
                dispatch(fetchFormTemplates())
            }, 2000)
        } else {
            toast.error("Something went wrong, please try again later.")
        }
        } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
        setAddRequestStatus('idle')
        }
    }
    

    // Add properties
    survey.emptySurveyText = "Please add components to get started."
    survey.validationEnabled = false
    survey.showNavigationButtons = false
    survey.showCompletedPage = false
    survey.showQuestionNumbers = false

    survey.onUpdateQuestionCssClasses.add(function(_, options) {
        const classes = options.cssClasses;
        if (options.question.getType() === "html") {
            classes.root += " survey-html-question"
        }
    });
    
    survey.onUpdatePanelCssClasses.add(function(_, options) {
      const classes = options.cssClasses;
      if(options.panel.getQuestionTitleLocation() === "hidden") {
        classes.panel.title = "survey-title-hidden";
      }
    });

        // Navigations 
        survey.onCurrentPageChanged.add((_, options) => {
            setPageNo(options.newCurrentPage.visibleIndex);
            updateCurrentPanelNo()
        });
        survey.onStarted.add(() => { setIsRunning(true); } );
        survey.onComplete.add(() => { setIsRunning(false); });
    
        const prevPage = () => { 
            survey.prevPage();
            // updateCurrentPanelNo()
         };

        const nextPage = () => { 
            survey.nextPage();
            // updateCurrentPanelNo()
         };

        const endSurvey = () => { survey.completeLastPage(); };
        const jumpToPage = (pageNo) => {
            // if (survey.currentPage.validate()) {
                setPageNo(pageNo);
            // }
        };
        
        const getPageSelectorOptions = () => {
            const content = [];
            for (let i = 0; i < survey.visiblePages.length; i++) {
                content.push(
                    <option key={i} value={i}>
                        Page {i + 1}
                    </option>
                )
            }
            return content;
        };
        const PageSelector = (
            <select
                className="navigation-page-selector sd-input sd-dropdown"
                value={pageNo}
                onChange={(evt) => { jumpToPage(JSON.parse(evt.target.value)); }}>
                {getPageSelectorOptions()}
            </select>
        );
        const renderButton = (text, func, canRender) => {
            if (!canRender) return undefined;
            return (
                <button className="navigation-button" onClick={func}>
                    {text}
                </button>
            );
        };
        const renderExternalNavigation = () => {
            if (!isRunning) return undefined;
            // const progressText = "Page " + (pageNo + 1) + " of " + survey.visiblePages.length;
            // const progressSpan = <span className="navigation-text">{progressText}</span>;
            return (
                <div className="navigation-block">
                    {/* {PageSelector} */}
                    <div className="navigation-progress-container">
                        <div className="navigation-buttons-container">
                            {renderButton('Previous Page', prevPage, pageNo !== 0 || survey.currentPageNo !== 0)}
                            {renderButton('Next Page', nextPage, pageNo !== survey.visiblePages.length - 1)}
                            {/* {renderButton('Next Page', nextPage, true)} */}
                            {/* {renderButton('Complete', endSurvey, pageNo === survey.visiblePages.length - 1)} */}
                        </div>
                    </div>
                </div>
            );
        }

    // Survey Buttons
    survey.onGetQuestionTitleActions.add((_, opt) => {

        opt.titleActions = [
            {
                title: 'Edit',
                innerCss: 'btn-more-info',
                action: () => {
                    // showDescription(opt.question);
                    editQuestion(opt.question)
                },
            },
            {
                title: "Delete",
                innerCss: 'btn-more-error',
                action: () => {
                    deleteQuestion(opt.question);
                },
            },
        ];
    });

        // Survey Buttons
        survey.onGetPanelTitleActions.add((_, opt) => {
            opt.titleActions = [
                {
                    title: 'Edit',
                    innerCss: 'btn-more-info',
                    action: () => {
                        // showDescription(opt.question);
                        editQuestion(opt?.panel)
                    },
                },
                {
                    title: "Delete",
                    innerCss: 'btn-more-error',
                    action: () => {
                        deletePanel(opt.panel);
                    },
                },
            ];
        });

    survey.onQuestionRemoved.add(function(sender, options) {
        let updatedForm = sender.toJSON() 
        if(!updatedForm?.pages[0]?.elements[0]?.elements) {
            toast.error("Form should contain at least one component.")
            let previousForm = JSON.parse(JSON.stringify(form));
            dispatch(updateForm(previousForm))
            setSurvey(new Model(previousForm))

            return
        } else {
            if(!updatedForm?.pages[survey.currentPageNo]?.elements[currentPanelNo]?.elements) {
                setSentenceAdded(true)
                updatedForm?.pages[survey.currentPageNo]?.elements?.splice(currentPanelNo, 1)
            }
            dispatch(updateForm(updatedForm))
            setSurvey(new Model(updatedForm))
            // updateCurrentPanelNo()
            setCurrentPanelNo(updatedForm?.pages[survey.currentPageNo]?.elements?.length - 1)

        }
    })

    survey.onPanelRemoved.add(function(sender, options) {
        setSentenceAdded(false)
        let updatedForm = sender.toJSON() 
        if(!updatedForm?.pages[0]?.elements[0]?.elements) {
            toast.error("Form should contain at least one component.")
            let previousForm = JSON.parse(JSON.stringify(form));
            dispatch(updateForm(previousForm))
            setSurvey(new Model(previousForm))
            return
        } else {
            updatedForm?.pages[survey.currentPageNo]?.elements?.splice(currentPanelNo, 1)
            dispatch(updateForm(updatedForm))
            setSurvey(new Model(updatedForm))
            // updateCurrentPanelNo()
            // setCurrentPanelNo(updatedForm?.pages[survey.currentPageNo]?.elements?.length - 1)
        }
    })


    const renderEditModal = () => {
        if (editFieldData?.type === "text") {
            return (
                 <EditText 
                     openModal={openEditModal}
                     setOpenModal={setOpenEditModal}
                     data={editFieldData}
                     updateTextField={handleUpdateField}
                 />
             ) 
        } else if (editFieldData?.type === "comment") {
            return (
                <EditTextArea 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateTextAreaField={handleUpdateField}
                />
            )
        } else if (editFieldData?.type === "radiogroup") {
            return (
                <EditRadioGroup 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateRadioGroupField={handleUpdateField}
                />
            )
        } else if (editFieldData?.type === "dropdown") {
            return (
                <EditSingleSelect 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateSingleSelectField={handleUpdateField}
                />
            )
        } else if (editFieldData?.type === "tagbox") {
            return (
                <EditMultiSelect 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateMultiSelect={handleUpdateField}
                />
            )
        } else if (editFieldData?.type === "matrixdynamic") {
            return (
                <EditDynamicSelect 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateDynamicSelectField={handleUpdateField}
                />
            )
        } else if (editFieldData?.type === "panel") {
            return (
                <EditSentence 
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    data={editFieldData}
                    updateSentenceField={handleUpdateSentence}
                />
            )

        } else {
            return null
        }
    }

    if (isLgScreen) {
        return (
            <Box mt={5}>
                <Typography textAlign="center">This feature is not available on small screens, please switch to bigger screen. Thank you.</Typography>
            </Box>
        )
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 1, mb: 24 }}>
                <PageHeader>
                    <PageHeading heading="Form Designer" />
                </PageHeader>
                <Grid container>
                    <Grid item xs={9}>
                            {/* <Preview json={json} /> */}
                            {survey.visiblePages.length > 1 
                                ? renderExternalNavigation()
                                : null
                            }
                            <Survey
                                currentPageNo={pageNo}
                                model={survey} 
                            />
                                {survey.visiblePages.length > 1 
                                ? renderExternalNavigation()
                                : null
                            }
                            
                            {/* Action Buttons */}
                            <Box display="flex" justifyContent="flex-end" gap={2} mt={4} mr={2}>
                                {/*
                                <CustomButton 
                                    title="Preview" 
                                    variant="outlined"
                                    onClickHandler={() => navigate(`/admin/form-templates/preview/${id}`)} 
                                    disabled={addRequestStatus === "pending"}
                                />
                            */}
                                <CustomButton 
                                    title="Save Draft" 
                                    variant="outlined"
                                    onClickHandler={() => submitHandler("draft")} 
                                    disabled={addRequestStatus === "pending"}
                                />
                                <CustomButton 
                                    title="Publish" 
                                    onClickHandler={() => submitHandler("active")} 
                                    disabled={addRequestStatus === "pending"}
                                />
                            </Box>
                    </Grid>
                
                    {/* Components  */}
                    <Grid item xs={3} >
                        <Box component="div" ml={3}>
                            <ContentContainer>
                                <Box mt={1} mb={2}>
                                    <Typography fontWeight="bold" textAlign="center">Components</Typography>
                                </Box>
                                <Box my={2}>
                                    <FieldsList 
                                        disabled={survey.visiblePages.length == 0}
                                        handleAddField={handleAddField}
                                        handleAddSentenceField={handleAddSentenceField}
                                        handleAddPage={addPageHandler}    
                                        />
                                </Box>
                            </ContentContainer>
                        </Box>
                    </Grid>
                </Grid>

                {/* Edit Fields */}

                {openEditModal 
                    ? renderEditModal()
                    : null   
                }
                <ToastContainer />
        </Container>

  )
}

export default Designer

