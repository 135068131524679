import React from 'react'
import { Box } from '@mui/material'

const PageHeader = ({ children, position="space-between", mb=4 }) => {
  return (
    <Box 
      display="flex" 
      flexDirection={{ xs: "column", sm: "row" }} 
      justifyContent={position} 
      alignItems={{ xs: "flex-start", sm: "center"}} 
      mb={mb} 
      gap={{ xs: 3, sm: 0}}
    >
        {children}
    </Box>
  )
}

export default PageHeader