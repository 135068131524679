import React, { useState } from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    OutlinedInput, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray  } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';
import { getAllLists, getListsError, getListsStatus } from '../../../../redux/slices/listSlice';

const initialValues = {
  label: '',
  choices: [],
  newChoiceText: '',
  defaultValue: "",
  startWithNewLine: true,
  showNoneItem: true,
  showOtherItem: true,
  addDescriptionBoxes: false,
  isRequired: false
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  label: Yup.string().required("Label is required."),
  choices: Yup.array().of(Yup.string().required("Item name is required.")),
  newChoiceText: Yup.string(),
  titleLocation: Yup.string(),
  defaultValue: Yup.string(),
  startWithNewLine: Yup.boolean(),
  showNoneItem: Yup.boolean(),
  showOtherItem: Yup.boolean(),
  addDescriptionBoxes: Yup.boolean(),
  isRequired: Yup.boolean(),
})

const MultiSelect = ({ addMultiSelectField }) => {
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [newChoiceError, setNewChoiceError] = useState(false)

    // const [items, setItems] = useState([])
    const [selectedListType, setSelectedListType] = useState("new")

    const lists = useSelector(getAllLists)
    const listsStatus = useSelector(getListsStatus)
    const listsError = useSelector(getListsError)

    const handleSelectListChange = (e, setFieldValue) => {
      setFieldValue("defaultValue", "")
      if (e.target.value == "new") {
        setSelectedListType(e.target.value)
        setFieldValue("choices", [])        
      } else {
        setSelectedListType(e.target.value)
        const selectedList = lists?.find(item => item._id === e.target.value) 
        if(selectedList) {
          let itemsArray = []
          selectedList?.items?.map((item) => itemsArray.push(item?.name))
          setFieldValue("choices", itemsArray)
        }
      }
    }

    const submitHandler = async (values, { resetForm }) => {
      if(values?.choices?.length === 0) {
        setNewChoiceError(true)
        return
      }
      const dropdownId = uuidv4()
      const itemsArray = [
        {
          "name": dropdownId,
          "type": "tagbox",
          "title": values?.label,
          "choices": values?.choices,
          "defaultValue": values?.defaultValue,
          "startWithNewLine": values?.startWithNewLine,
          "showNoneItem": values?.showNoneItem,
          "showOtherItem": values?.showOtherItem,
          "isRequired": values?.isRequired
        }
      ]
      if(values?.addDescriptionBoxes) {
        itemsArray.push({
          name: uuidv4(),
          "type": "comment",
          "name": uuidv4(),
          "title": "Description",
          "startWithNewLine": false,
          "rows": 1,
          "visible": true,
          "visibleIf": `{${dropdownId}} != 'other'`
        })
      }
      addMultiSelectField(itemsArray)
      resetForm({ values: '' })
      setSelectedListType("new")
      setOpenModal(false)
    }

    const handleAddChoice = (values, push, setFieldValue) => {
      setFieldValue('newChoiceText', values.newChoiceText.trim());
      if(values?.newChoiceText?.trim() !== "") {
          push(values.newChoiceText.trim());
          setNewChoiceError(false)
          setFieldValue('newChoiceText', '');
      }
    };
  
    const handleRemoveChoice = (index, remove) => {
      remove(index);
    };

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, handleChange, handleBlur, errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    error={errors.label && touched.label}
                    helperText={touched.label && errors.label}
                  />
                </Grid>

                {/* Select option */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="list">List</InputLabel>
                        <Field
                          as={Select}
                          input={<OutlinedInput label="List" />} 
                          name="type"
                          variant="outlined"
                          value={selectedListType}
                          onChange={(e) => handleSelectListChange(e, setFieldValue)}
                          fullWidth
                        >
                          <MenuItem key="new" value="new">New</MenuItem>
                          {lists?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                          ))}
                        </Field>
                    </FormControl>
                  </Grid>

                  {selectedListType === "new"  
                  ?
                  <>
                    {/* ITems */}
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Typography variant="display2" fontWeight="bold">
                          Add Items
                        </Typography>
                      </Box>
                    <FieldArray name="choices">
                      {({ push, remove }) => (
                        <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={1}>
                          <FormControl fullWidth>
                            <TextField
                              label="New Choice"
                              // fullWidth
                              name="newChoiceText"
                              value={values.newChoiceText}
                              onChange={handleChange}
                              // onBlur={validateForm}
                              size="small"
                              // error={touched.newChoiceText && !!errors.newChoiceText}
                              // helperText={touched.newChoiceText && errors.newChoiceText}
                            />
                            {newChoiceError && <FormHelperText error>Item name is required</FormHelperText>}
                          </FormControl>
                            <Button
                              size="small"
                              onClick={() => {
                                if(!values?.newChoiceText) {
                                  setNewChoiceError(true)
                                } else {
                                  handleAddChoice(values, push, setFieldValue) 
                                }
                              }}
                            >
                              Add Item
                            </Button>
                          </Box>
                          {values?.choices?.map((choice, index) => (
                            <Box key={index} display="flex" alignItems="center" mb={2} gap={1}>
                              <TextField
                                label={`Choice ${index + 1}`}
                                fullWidth
                                name={`choices[${index}]`}
                                value={choice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                error={touched.choices && touched.choices[index] && !!errors.choices?.[index]}
                                helperText={touched.choices && touched.choices[index] && errors.choices?.[index]}
                              />
                              <Button
                                onClick={() => handleRemoveChoice(index, remove)}
                                size="small"
                                sx={{
                                  color: theme.palette.custom.red.light
                                }}
                              >
                                Remove
                              </Button>
                            </Box>
                          ))}
                        </>
                      )}
                    </FieldArray>
                    </Grid>

                  </>
                  : null
                }

                {/* Default Value */}
                <Grid item xs={12}>
                      <FormControl fullWidth>
                          <InputLabel htmlFor="type">Default</InputLabel>
                          <Field
                            as={Select}
                            name="defaultValue"
                            variant="outlined"
                            fullWidth
                            error={errors.defaultValue && touched.defaultValue}
                            helperText={touched.defaultValue && errors.defaultValue}
                          >
                            {values?.choices?.map((choice, index) => (
                              <MenuItem ket={index} value={choice}>{choice}</MenuItem>
                            ))}
                          </Field>
                      </FormControl>
                    </Grid>

              {/* Show None Item */}
              <Grid item xs={12} md={6}>
                <Field name="showNoneItem">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Include None Item"
                      />
                      {form.errors.showNoneItem && form.touched.showNoneItem && (
                        <FormHelperText error>{form.errors.showNoneItem}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

              {/* Show Other item */}
              <Grid item xs={12} md={6}>
                <Field name="showOtherItem">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Include Other Item"
                      />
                      {form.errors.showOtherItem && form.touched.showOtherItem && (
                        <FormHelperText error>{form.errors.showOtherItem}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

              {/* Show Other item */}
              <Grid item xs={12} md={6}>
                <Field name="addDescriptionBoxes">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="Add Description Box"
                      />
                      {form.errors.addDescriptionBoxes && form.touched.addDescriptionBoxes && (
                        <FormHelperText error>{form.errors.addDescriptionBoxes}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

              {/* New line */}
              <Grid item xs={12} md={6}>
                <Field name="startWithNewLine">
                  {({ field, form }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field?.value} />}
                        label="New Line"
                      />
                      {form.errors.startWithNewLine && form.touched.startWithNewLine && (
                        <FormHelperText error>{form.errors.startWithNewLine}</FormHelperText>
                      )}
                    </>
                  )}
                </Field>
              </Grid>

                {/* Required */}
               <Grid item xs={12} md={6}>
                <Field name="isRequired">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Required"
                        />
                        {form.errors.isRequired && form.touched.isRequired && (
                          <FormHelperText error>{form.errors.isRequired}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

              </Grid>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Add"
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <PlaylistAddCheckIcon color="custom.gray" />
              <Typography>Multi Select</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Add Multi Select Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default MultiSelect