import { Box, Typography } from '@mui/material';
import React from 'react'

const PageHeading = ({ heading, text="" }) => {
  return (
    <Box>
        <Typography variant='h5' color="secondary" fontWeight="bold" mb={0.5}>
            {heading}
        </Typography>
        <Typography variant='body1' color="secondary.light">
            {text}
        </Typography>
    </Box>
  )
}

export default PageHeading