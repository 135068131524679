import DashboardOutlined from '@mui/icons-material/DashboardOutlined'
import GroupIcon from '@mui/icons-material/Group'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import EventNoteIcon from '@mui/icons-material/EventNote'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ApartmentIcon from '@mui/icons-material/Apartment'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const doctorNavList =[
    {    
      text: 'Dashboard', 
      icon: DashboardOutlined, 
      path: '/doctor/dashboard',
      badge: 0 ,
      isActive: true
    },
    { 
      text: 'Registration', 
      icon: PersonAddIcon, 
      path: '/patients/registration', 
      isActive: false
    },
    { 
      text: 'Patients', 
      icon: GroupIcon, 
      path: '/patients', 
      isActive: false
    }
]

const coordinatorNavList = [
      { 
        text: 'Registration', 
        icon: PersonAddIcon, 
        path: '/patients/registration', 
        isActive: false
      },
      { 
        text: 'Patients', 
        icon: GroupIcon, 
        path: '/patients', 
        isActive: false
      },
      { 
        text: 'Doctors', 
        icon: MedicalInformationIcon, 
        path: '/coordinator/doctors', 
        isActive: false
      },
  ]

  const adminNavList = [
      {    
        text: 'Dashboard', 
        icon: DashboardOutlined, 
        path: '/admin/dashboard',
        badge: 0, 
        isActive: true
      },
      { 
        text: 'Patients', 
        icon: GroupIcon, 
        path: '/patients', 
        isActive: false
      },
      { 
        text: 'Hospitals', 
        icon: ApartmentIcon, 
        path: '/admin/hospitals', 
        isActive: false
      },
      { 
        text: 'Clinics', 
        icon: LocalHospitalIcon, 
        path: '/admin/clinics', 
        isActive: false
      },
      { 
        text: 'Doctors', 
        icon: MedicalInformationIcon, 
        path: '/admin/doctors', 
        isActive: false
      },
      { 
        text: 'Coordinators', 
        icon: ContactMailIcon, 
        path: '/admin/coordinators', 
        isActive: false
      },
      { 
        text: 'Form Templates', 
        icon: EventNoteIcon, 
        path: '/admin/form-templates' 
      },
      { 
        text: 'Manage Lists', 
        icon: FormatListBulletedIcon, 
        path: '/admin/lists' 
      }
]

export {
  doctorNavList,
  coordinatorNavList,
  adminNavList
}