import React from 'react'
import { Container, Paper } from '@mui/material'

const FormContainer = ({children}) => {
  return (
    <Container maxWidth="xs">
      <Paper elevation={3} 
          sx={{ 
              padding: 3.5,
              width: '100%',
              position: 'relative',
              marginTop: "48vh",
              borderRadius: 3
          }}
        >
          {children}  
      </Paper> 
    </Container>
  )
}

export default FormContainer