import {Button} from '@mui/material'

const TableButton = ({ type="success", disabled = false, children}) => {
  let textColor="#1C8C6E", bgColor="#ECF8F0"

    switch (type) {
        case "warning": 
            textColor = "#DBAA00"
            bgColor = "#FBF4E4"
            break
        case "info":
            textColor = "#3D81DB"
            bgColor = "#DCF3FF"
            break
        case "disabled":
            textColor = "#6C7278"
            bgColor = "#EDF1F3"
            break
        default:
            textColor = "#1C8C6E"
            bgColor = "#ECF8F0"
            break
    }
  
  return (
    <Button 
      variant="contained"
      size="small"
      disabled={disabled}
      disableElevation
      sx={{
        width: 86,
        color: textColor,
        background: bgColor,
        padding: "2px 0",
        fontWeight: 600,
        fontSize: 13,
        textTransform: 'capitalize',
        letterSpacing: 0.5,
        "&:hover": { backgroundColor: "transparent"}
      }}
    >
      {children}
    </Button>
  )
}

export default TableButton