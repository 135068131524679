import React from 'react'
import { Box, Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PageHeader from '../../../components/ui/PageHeader'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'

import { ClinicProfileData } from '../../../features/admin'
import { selectClinicById } from '../../../redux/slices/clinicSlice'
import { ToastContainer } from 'react-toastify';

const ManageClinic = () => {
  const { clinicId } = useParams()
  const clinic = useSelector((state) => selectClinicById(state, clinicId))

  return (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            <PageHeader>
              <PageHeading heading="Clinic Management" text="Update clinic details" />
            </PageHeader>

            <Box display="flex" flexDirection="column" gap={3}>
              <ContentContainer padding={4}>
                <ClinicProfileData clinic={clinic} />
              </ContentContainer>
              
              {/* <ContentContainer padding={2}>
                <ClinicsTable hospital={hospital} />
              </ContentContainer> */}
            </Box>
            {/* <ToastContainer /> */}
          </Container>
  )
}

export default ManageClinic