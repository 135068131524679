import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import GroupIcon from '@mui/icons-material/Group'
import ApartmentIcon from '@mui/icons-material/Apartment'

import DataWidget from '../../components/ui/DataWidget';
import PageHeading from '../../components/ui/PageHeading';
import ContentContainer from '../../components/ui/ContentContainer';
import BackdropLoader from '../../components/ui/BackdropLoader';
import YearSelect from '../../components/ui/YearSelect';

import { ResponsiveBarChart } from '../../features/doctor';

import { selectUser, logoutUser, getUserStatus, selectActiveClinicById, getUserActiveClinic } from '../../redux/slices/authSlice'
import { fetchDoctorDashboardGraphsData, fetchDoctorDashboardStats, getGraphDataStatus, getStatusDataStatus, selectDoctorDashboardGraphsData, selectDoctorDashboardStats } from '../../redux/slices/doctorDashboardSlice';
import ChartHeading from '../../components/ui/ChartHeading';
import { SIZES } from '../../constants/theme';

const Dashboard = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const user = useSelector(selectUser)
    const activeClinicId = useSelector(getUserActiveClinic)
    const userStatus = useSelector(getUserStatus)

    const dashboardStatsData = useSelector(selectDoctorDashboardStats)
    const dashboardStatsDataStatus = useSelector(getStatusDataStatus)

    const dashboardGraphData = useSelector(selectDoctorDashboardGraphsData)
    const dashboardGraphDataStatus = useSelector(getGraphDataStatus)

    const activeClinic = useSelector((state) => selectActiveClinicById(state, activeClinicId))

    useEffect(() => {
      if(activeClinicId) {
        if (dashboardStatsDataStatus !== "loading") 
            dispatch(fetchDoctorDashboardStats(activeClinicId))

        if (dashboardGraphDataStatus !== "loading")
            dispatch(fetchDoctorDashboardGraphsData({activeClinicId, selectedYear}))
      }
    }, [dispatch, activeClinicId, selectedYear])

    const handleYearChange = (year) => {
      setSelectedYear(year);
    };

    return (
    <>
      {
        (userStatus === "loading")
          ? <BackdropLoader />
          :
          <Container maxWidth="xl" >
              {/* Cards */}
              <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} md={6}>
                    <Box 
                      height={160}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      bgcolor="primary.main"
                      borderRadius={1.2}
                      p={3}
                      gap={1.5}
                    >
                      <Box display="flex" gap={1}>
                        <Typography 
                          variant='h6' 
                          color="custom.ashWhite.light"
                          fontSize={{
                            md: SIZES.xLarge,
                            xs: SIZES.medium,
                          }} 
                        >
                          Welcome back, <b>Dr. {user?.firstName} {user?.lastName}</b>
                        </Typography>
                      </Box>
                      <Typography 
                         fontSize={{
                          md: SIZES.medium,
                          xs: SIZES.small,
                        }} 
                        variant='body1' 
                        color="custom.ashWhite.dark"
                      >
                        You are currently logged in to <em>{activeClinic?.clinic?.name}, {activeClinic?.clinic?.hospital?.name}</em>.
                        </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DataWidget 
                      title="Total Patients" 
                      value1={dashboardStatsData?.totalPatientsInActiveClinic || 0}
                      value2={dashboardStatsData?.totalUserPatientsInClinic || 0}
                      value3='Your Patients' 
                      icon={<GroupIcon />} 
                      color={theme.palette.primary.light} 
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DataWidget 
                      title="Total Hospitals" 
                      value1={dashboardStatsData?.totalUniqueHospitals || 0}
                      value2={dashboardStatsData?.totalClinics || 0}
                      value3='Total Clinics' 
                      icon={<ApartmentIcon />} 
                      color={theme.palette.custom.lightBlue.main}
                    />
                  </Grid>
    
                  {/* Charts */}
                  <Grid item xs={12} mt={2}>
                    <ContentContainer padding={2}>
                          <Box sx={{display: 'flex', flexDirection: 'column', height: 340 }}>
                            <Box display="flex" justifyContent="space-between" mb={4}>
                              <ChartHeading text='Patients Added per Month' />
                              <YearSelect selectedYear={selectedYear} onYearChange={handleYearChange} />
                            </Box>
                            <ResponsiveBarChart data={dashboardGraphData?.patientsDataPerMonth} />
                          </Box>
                    </ContentContainer>
                  </Grid>
              </Grid>
          </Container>
      }
    </>
  )
}

export default Dashboard