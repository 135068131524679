import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, Container, IconButton, Typography, useTheme  } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'
import PageHeader from '../../../components/ui/PageHeader'
import { ToastContainer, toast } from 'react-toastify'

import { fetchDoctors, getDoctorsError, getDoctorsStatus, selectAllDoctors } from '../../../redux/slices/doctorSlice';
import { getUserActiveClinic } from '../../../redux/slices/authSlice';

const DoctorsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
   const [rowId, setRowId] = useState(null)

   const doctors = useSelector(selectAllDoctors)
   const doctorsStatus = useSelector(getDoctorsStatus)
   const doctorsError = useSelector(getDoctorsError)

  useEffect(() => {
      if (doctorsStatus === "idle" && doctors.length === 0)
          dispatch(fetchDoctors())
  }, [])

  const getStatusType = (doctorStatus) => {
    return doctorStatus === "active" ? "success" : "disabled"
}

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      minWidth: 140,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      valueGetter: (params) => params.row?.emailDetails?.email
    },
    {
      field: 'phone',
      headerName: 'Phone',
      minWidth: 140,
      valueGetter: (params) => params.row?.phone
    },
    {
      field: 'gender',
      headerName: 'Gender',
      minWidth: 60,
      valueGetter: (params) => params.row.gender
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 140,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.substring(0, 10)
      // moment(params.row.appointmentDate).format('YYYY-MM-DD')
      },       
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return (
            <TableButton 
              type={getStatusType(params.row.status)}
            >
                {params.row.status}
            </TableButton>
          )
        }
      }
    ],
    [rowId]
  );

  return (
    <>
      { doctorsStatus === 'loading'
        ? <BackdropLoader /> 
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
            <PageHeader>
              <PageHeading heading="Doctors List" text="List of your clinic doctors" />
            </PageHeader>
            
            <ContentContainer>
              {/* Doctors table */}
              <Table 
                // heightPrecentage="40vh"
                columns={columns} 
                rows={doctors}
                setRowId={setRowId}
                basePathForRedirect={"/coordinator/doctors/profile"}
              />
            </ContentContainer>
          </Container>
        )
      } 
    <ToastContainer />
    </>
   
  )
}

export default DoctorsList
