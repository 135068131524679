import ROLES from "../constants/roles"
import { doctorNavList, coordinatorNavList, adminNavList } from '../components/layout/dashboard/navList'

const roleBasedRedirect = (roles) => {
    const role = roles[0]
    if (role === ROLES.ADMIN) return adminNavList[0]?.path
    else if (role === ROLES.DOCTOR) return doctorNavList[0]?.path
    else return coordinatorNavList[0]?.path
  }

export default roleBasedRedirect