import {  useState } from 'react'
import { useNavigate  } from 'react-router-dom'
import { AppBar, Toolbar, Box, Typography, Avatar, Menu, MenuItem,  IconButton, useTheme, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LogoutIcon from '@mui/icons-material/Logout';

import { styled } from "@mui/material/styles"
import { selectUser } from '../../../redux/slices/authSlice'
import { ICONS } from '../../../assets';

const StyledAppBar = styled(AppBar)(({ theme })=> ({
    position: "sticky",
    background: "#FFF",
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px 0px"
}))

const StyledToolbar = styled(Toolbar)(({ theme })=> ({
    display: "flex",
    justifyContent: "space-between"
    // [theme.breakpoints.down('md')] : {
    //     justifyContent: "space-between",
    // },
    // [theme.breakpoints.up('md')] : {
    //     justifyContent: "flex-end",
    // },
}))

const Navbar = ({ open, setOpen, handleClick, handleLogout }) => {
     const theme = useTheme()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMd = useMediaQuery(theme.breakpoints.down('md'))

    const [anchorEl, setAnchorEl] = useState(null)
    const openDropDown = Boolean(anchorEl)

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
  
      const handleDropdownClose = () => {
        setAnchorEl(null);
      };

    const user = useSelector(selectUser)

    return (
        <StyledAppBar elevation={0}>
            <StyledToolbar open={open}>
                    {/* <Badge badgeContent={0} color="error">
                        <MailIcon />
                    </Badge> */}
                    <Box display="flex">
                        <IconButton 
                            IconButton 
                            sx={{ display: { xs: "block", md: "none"}, mt: 1.5, pt: 0}}
                            onClick={() => setOpen(!open)}
                        >
                            <MenuIcon color="primary"  />
                        </IconButton> 
                        {(!open || isMd) 
                            ?      
                                <Box display="flex" alignItems="center" gap={1} ml={2} >
                                    <img
                                        alt="Logo"
                                        src={ICONS.logo}
                                        style={{
                                            height: "3.5rem",
                                            width: "7rem"
                                        }}
                                        loading='lazy'
                                    />
                                </Box>
                            : null
                        } 
                    </Box>

                    <Box display="flex" alignItems="center" gap={1}>
                        <Avatar 
                            sx={{width: 30, height: 30}} 
                            src={user?.picture} 
                        />
                        <Typography
                            sx={{ display: { xs: "none", md: "block"} }}    
                            fontWeight="bold"
                            color="secondary.main"
                        >
                            {user?.firstName + " " + user?.lastName}
                        </Typography>
                        <IconButton 
                            onClick={handleLogout}
                            size="small"
                            // onClick={handleDropdownClick}
                            // sx={{ ml: 2 }}
                            // aria-controls={openDropDown ? 'account-menu' : undefined}
                            // aria-haspopup="true"
                            // aria-expanded={openDropDown ? 'true' : undefined}
                        >
                            {/* <ArrowDropDownIcon color="secondary"  /> */}
                            <LogoutIcon color="secondary"  />
                        </IconButton>
                    </Box>                    

            </StyledToolbar>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openDropDown}
                onClose={handleDropdownClose}
                onClick={handleDropdownClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 5,
                      '& .MuiAvatar-root': {
                        // width: 32,
                        // height: 32,
                        ml: -0.5,
                        // mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem onClick={() => handleClick("/profile")}>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </StyledAppBar>
    )
    }

export default Navbar