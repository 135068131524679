import React, { useState } from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangePicker = ({ fromDate, setFromDate, toDate, setToDate, inputValue, setInputValue }) => {
  const dateFormat = "DD/MM/YYYY";

  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onPopoverClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = "";
    if (startDate) inputValue += moment(startDate).format(dateFormat);
    if (endDate) inputValue += " - " + moment(endDate).format(dateFormat);
    console.log({startDate, endDate})
    setFromDate(startDate);
    setToDate(endDate);
    setInputValue(inputValue);
  };


  return (
    <Box>
      <TextField
        label="Date Range"
        size="small"
        fullWidth
        value={inputValue}
        onClick={onAdornmentClick}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <Box sx={{ padding: "16px" }}>
          <DateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
              },
            ]}
            onChange={onSelectDateRanges}
            staticRanges={undefined}
            inputRanges={undefined}
            maxDate={new Date()}
            showMonthAndYearPickers
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default DateRangePicker;
