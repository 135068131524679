import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getToken } from "./authSlice"
import { LISTS_URL } from "../../constants/apis"

const initialState = {
    lists: [],
    categories: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchLists = createAsyncThunk('lists/fetchLists', async (_, { getState }) => {
    const authToken = getToken(getState())
    
    const response = await axios.get(LISTS_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
})

export const fetchListsCategories = createAsyncThunk('lists/fetchListsCategories', async (_, { getState }) => {
    const authToken = getToken(getState())
    
    const response = await axios.get(`${LISTS_URL}/categories`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        return response?.data
})

const listSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            // Fetch Lists  
            .addCase(fetchLists.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchLists.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.lists = action.payload
            })
            .addCase(fetchLists.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            // Fetch Lists Categories
            .addCase(fetchListsCategories.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchListsCategories.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.categories = action.payload
            })
            .addCase(fetchListsCategories.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getAllLists = (state) => state.lists.lists
export const getListCategories = (state) => state.lists.categories
export const getListsStatus = (state) => state.lists.status
export const getListsError = (state) => state.lists.error

export const selectListById = (state, listId) =>
    state.lists.lists.find(list => list._id == listId)

export const { } = listSlice.actions

export default listSlice.reducer