import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import CustomButton from '../../../components/ui/CustomButton'
import BasicModal from '../../../components/ui/modal/BasicModal'

import { PATIENTS_URL } from '../../../constants/apis'

import { getToken } from '../../../redux/slices/authSlice'
import { fetchClinics } from '../../../redux/slices/clinicSlice'
import { fetchAdminDashboardStatsData } from '../../../redux/slices/adminDashboardSlice';
import { removePatientFromList } from '../../../redux/slices/patientSlice';

const DeleteHospitalModal = ({ patientId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle')
    const accessToken = useSelector(getToken)

    const canDelete = [patientId].every(Boolean) && deleteRequestStatus === 'idle'

    const onDeletePatientClicked = async () => {

        if(!canDelete) {
          toast.info("Please wait, another request is in progress.")
          return
        }

        try {
          setDeleteRequestStatus('pending')
          const res = await axios.delete(`${PATIENTS_URL}/${patientId}`, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
          }) 
          if(res.status === 200) {
            toast.success("Patient deleted successfully.")
            closeModal()
            // dispatch(fetchAllPatients())
            // dispatch(fetchPatients())
            dispatch(removePatientFromList(res?.data?.id))
            dispatch(fetchAdminDashboardStatsData())
            dispatch(fetchClinics())
          } else {
            toast.error("Something went wrong, please try again later.")
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setDeleteRequestStatus('idle')
        }
      }

    const DeleteModalContent = () => (
        <Box>
          <Typography variant='subtitle1' fontWeight="bold"  mb={3}>
            Are you sure you want to remove this patient?
          </Typography>
          <Typography variant='subtitle2' mb={3}>
            This will remove all data of patient. You cannot undo this action.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
           <CustomButton 
            title="Cancel" 
            onClickHandler={closeModal}
            variant="outlined"  
            color={theme.palette.custom.ashWhite.light}
            disabled={deleteRequestStatus === "pending"}
          />
           <CustomButton 
            title="Delete" 
            onClickHandler={onDeletePatientClicked}
            color={theme.palette.custom.red.light}
            disabled={deleteRequestStatus === "pending"}
          />
          </Box>
        </Box>
      )


    return (
        <BasicModal 
            open={open}
            onClose={closeModal}
            content={DeleteModalContent}
        />
    )
}

export default DeleteHospitalModal