import React, { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, useTheme, OutlinedInput, FormHelperText } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

import ContentContainer from '../../../../components/ui/ContentContainer'
import PageHeading from '../../../../components/ui/PageHeading'
import PageHeader from '../../../../components/ui/PageHeader'

import { PATIENTS_URL } from '../../../../constants/apis'
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getUserActiveClinic } from '../../../../redux/slices/authSlice'
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton'
import CitiesMenuList from '../../../../components/ui/form/CitiesMenuList';
import { fetchPatients } from '../../../../redux/slices/patientSlice';
import { cities } from '../../../../constants/data';
import { fetchDoctors, getDoctorsError, getDoctorsStatus, selectAllDoctors } from '../../../../redux/slices/doctorSlice';

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  firstName: Yup.string().required('First Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid first name'),
  lastName: Yup.string().required('Last Name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid last name'),
  nationalId: Yup.string()
  .matches(/^[a-zA-Z0-9\s]*$/, 'Invalid national Id')
  .min(10, 'Invalid National ID')
  .max(10, 'Invalid National ID'),
  mrn: Yup.string()
    .matches(/^[0-9,-]+$/, 'Invalid MRN')
    .min(6, 'Invalid MRN')
    .max(20, 'Invalid MRN')
    .required('MRN is required'),
  gender: Yup.string().required('Gender is required'),
  age: Yup.mixed()
    .required("Age is required")
    .test('isNumber', 'Age must be a number', (value) => !isNaN(value))
    .test('isPositive', 'Age must be a positive number and greater than 0', (value) => value > 0)
    .test('max', 'Age must below 120', (value) => value < 120),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
  street: Yup.string()
    .max(100, 'Max 100 characters are allowed.'),
  city: Yup.string(),
  otherCity: Yup.string(),
  doctor: Yup.string(),
  diagnosis: Yup.string()
  .matches(/^[a-zA-Z,\s]*$/, 'Invalid Diagnosis')
  .max(64, 'Invalid Diagnosis')
})

const UpdatePatientDetails = ({ patient }) => {
  const dispatch = useDispatch()
  const accessToken = useSelector(getToken) 
  const userActiveClinic = useSelector(getUserActiveClinic) 
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const initialValues = {
    firstName: patient?.firstName || "",
    lastName: patient?.lastName || "",
    gender: patient?.gender || "",
    age: patient?.age || "",
    email: patient?.email || "",
    street: patient?.address?.street || "",
    city: cities?.includes(patient?.address?.city) ? patient?.address?.city : "Other",
    otherCity: !cities?.includes(patient?.address?.city) ? patient?.address?.city : "",
    phone: patient?.phone || "",
    nationalId: patient?.nationalId || "",
    mrn: patient?.mrn || "",
    doctor: patient?.doctor?._id || "",
    diagnosis: patient?.diagnosis || "",
  };

  const doctors = useSelector(selectAllDoctors)
  const doctorsStatus = useSelector(getDoctorsStatus)
  const doctorsError = useSelector(getDoctorsError)

  useEffect(() => {
    if (doctorsStatus === "idle")
        dispatch(fetchDoctors())
}, [])

  const submitHandler = async (values, { resetForm }) => {
    try {
      setUpdateRequestStatus('pending')
      const res = await axios.patch(`${PATIENTS_URL}/${patient?._id}`, 
        {
          ...values,
         }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      }) 
      if(res.status === 200) {
        toast.success("Patient updated successfully.")
        dispatch(fetchPatients())
      } else {
        toast.error("Something went wrong, please try again later.")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
    } finally {
      setUpdateRequestStatus('idle')
    }
  }

  return (
    <>
      <ContentContainer padding={2}>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First name"
                  variant="outlined"
                  inputProps={{
                    maxLength: 24
                  }}
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last name"
                  variant="outlined"
                  inputProps={{
                    maxLength: 24
                  }}
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="mrn"
                  label="MRN (Medical Record No.)"
                  variant="outlined"
                  inputProps={{
                    minLength: 6,
                    maxLength: 20
                  }}
                  fullWidth
                  error={errors.mrn && touched.mrn}
                  helperText={touched.mrn && errors.mrn}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="nationalId"
                  label="National ID"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.nationalId && touched.nationalId}
                  helperText={touched.nationalId && errors.nationalId}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="age"
                  label="Age"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    min: 0
                  }}
                  fullWidth
                  error={errors.age && touched.age}
                  helperText={touched.age && errors.age}
                />
              </Grid>
              <Grid item xs={12} md={4}>
               <FormControl fullWidth>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Gender" />} 
                  name="gender"
                  variant="outlined"
                  fullWidth
                  error={
                    Boolean(touched.gender && errors.gender)
                 }
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Field>
                  {touched.gender && errors.gender ? (
                  <FormHelperText
                      sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                  >
                      {touched.gender && errors.gender}
                  </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{
                    maxLength: 30
                  }}
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              {(values?.city !== "Other") 
                ?
                  <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="city">City</InputLabel>
                    <Field
                      as={Select}
                      input={<OutlinedInput label="City" />} 
                      name="city"
                      variant="outlined"
                      fullWidth
                      error={
                        Boolean(touched.city && errors.city)
                      }
                    >
                        {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>{city}</MenuItem>
                        ))}
                    </Field>
                    {touched.city && errors.city ? (
                      <FormHelperText
                          sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                      >
                          {touched.city && errors.city}
                      </FormHelperText>
                      ) : null
                    }
                    </FormControl>
                  </Grid> 
                : 
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="City"
                      variant="outlined"
                      inputProps={{
                        maxLength: 30
                      }}
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid>
              }
              
               <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="street"
                  label="Address"
                  variant="outlined"
                  inputProps={{
                    maxLength: 100
                  }}
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
             
              <Grid item xs={12} md={4}>
               <FormControl fullWidth>
                <InputLabel htmlFor="doctor">Doctor</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Doctor" />} 
                  name="doctor"
                  variant="outlined"
                  fullWidth
                  error={errors.doctor && touched.doctor}
                  helperText={touched.doctor && errors.doctor}
                >
                  {doctors?.length === 0
                    ? <MenuItem value="">No doctors available.</MenuItem>
                    :
                      doctors?.map((doctor) => (
                        <MenuItem key={doctor?._id} value={doctor?._id}>{doctor?.firstName} {doctor?.lastName}</MenuItem>
                      ))
                  }
                </Field>
                </FormControl>
              </Grid>  
              <Grid item xs={12} md={8}>
                <Field
                  as={TextField}
                  name="diagnosis"
                  label="Diagnosis"
                  variant="outlined"
                  inputProps={{
                    maxLength: 64
                  }}
                  fullWidth
                  error={errors.diagnosis && touched.diagnosis}
                  helperText={touched.diagnosis && errors.diagnosis}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Update"
                    disabled={updateRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </ContentContainer>
      <ToastContainer />
    </>
  )
}

export default UpdatePatientDetails