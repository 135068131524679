import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import usersReducer from './slices/userSlice'
import hospitalsReducer from './slices/hospitalSlice'
import clinicsReducer from './slices/clinicSlice'
import doctorsSlice from './slices/doctorSlice'
import coordinatorSlice from './slices/coordinatorSlice'
import adminDashboardSlice from './slices/adminDashboardSlice'
import patientSlice from './slices/patientSlice'
import formSlice from './slices/formSlice';
import listSlice from './slices/listSlice';
import doctorDashboardSlice from './slices/doctorDashboardSlice'


export const store = configureStore({
  reducer: {
    adminDashboardData: adminDashboardSlice,
    user: authReducer,
    users: usersReducer,
    hospitals: hospitalsReducer,
    clinics: clinicsReducer,
    doctors: doctorsSlice,
    doctorDashboardData: doctorDashboardSlice,
    coordinators: coordinatorSlice,
    patients: patientSlice,
    forms: formSlice,
    lists: listSlice
  },
})