import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Container } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  maxHeight: "90vh",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 1.5,
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  scrollbarWidth: "thin"
};

const BasicModal = ({ open, onClose, title="", content, maxSize="sm" }) => {
 
  return (
      <div>
        <Modal open={open} onClose={onClose}>
          <Container maxWidth={maxSize} sx={style}>
            { title &&
              <Typography variant="h6" fontWeight="bold" mb={4}>
                  {title}
              </Typography>
            }
            {content()}
          </Container>
        </Modal>
      </div>
  )
}

export default BasicModal