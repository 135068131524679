import icon from './icon.png'
import logo from './logo1.png'
import filter from './filter.svg'
import people from './people.svg'
import people2 from './people2.svg'
import profiletick from './profile-tick.svg'
export default {
    logo,
    icon,
    filter,

    // Admin Dashboard
    // people,
    // people2,
    // profiletick
    
}