import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { SIZES } from '../../../constants/theme';

const InvoiceDetail = ({ data, COLORS }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{display: 'flex', flexDirection: isSm ? 'row' : 'column', gap: isSm ? 3: 0 }}>

      {/* First */}
      {data?.map((item, index) => (
        <Box>
          <Typography 
            sx={{mt:3, color: 'secondary.light', textTransform: 'capitalize'}}
            fontSize={{
              md: SIZES.small,
              xs: SIZES.xSmall,
            }}   
          >
            {item?.name}
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', mt: isSm ? 2 : 0}}>
            <FiberManualRecordIcon sx={{ color: COLORS[index]}} />
            <Typography
               fontSize={{
                md: SIZES.small,
                xs: SIZES.xSmall,
              }} 
            >
              {item?.value}
            </Typography>
          </Box>
        </Box>
      ))}

    </Box>
  )
}

export default InvoiceDetail