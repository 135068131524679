import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Box, Button, Container, IconButton, Typography, useTheme  } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'
import PageHeader from '../../../components/ui/PageHeader'
import { ToastContainer, toast } from 'react-toastify'
import BasicModal from '../../../components/ui/modal/BasicModal'

import { fetchDoctors, getDoctorsError, getDoctorsStatus, selectAllDoctors } from '../../../redux/slices/doctorSlice';
import { DOCTORS_URL } from '../../../constants/apis';
import { getToken } from '../../../redux/slices/authSlice'
import { fetchClinics } from '../../../redux/slices/clinicSlice';

const DoctorsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle')

  const accessToken = useSelector(getToken)
   const doctors = useSelector(selectAllDoctors)
   const doctorsStatus = useSelector(getDoctorsStatus)
   const doctorsError = useSelector(getDoctorsError)
  
  useEffect(() => {
      if (doctorsStatus !== "loading" && doctors.length === 0)
          dispatch(fetchDoctors())
  }, [])

  useEffect(() => {
    if(doctorsError)
      toast.error(doctorsError)
  }, [doctorsError])

  const getStatusType = (doctorStatus) => {
    return doctorStatus === "active" ? "success" : "disabled"
}

const closeDeleteModal = () => {
  setOpenDeleteModal(false)
  setDeleteItemId(null)
}

const onDeleteHandler = (id) => {
  setDeleteItemId(id)
  setOpenDeleteModal(true)
}

const deleteDoctor = async () => {
  setDeleteRequestStatus('pending')
    const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
      },
  }
  try {
    const res = await axios.delete(`${DOCTORS_URL}/${deleteItemId}`, config) 
    if(res.status === 200) {
      toast.success("Doctor deleted successfully.")
      closeDeleteModal()
      dispatch(fetchClinics())
      dispatch(fetchDoctors())
    } else {
      toast.error("Something went wrong, please try again later.")
    }
  } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
  } finally {
    setDeleteRequestStatus('idle')
  }
}

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      minWidth: 240,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar 
            sx={{ width: 32, height: 32 }}
            src={params.row?.picture} 
          />
          <Typography variant="body2" fontWeight="bold">
            {params.row?.firstName + " " + params.row?.lastName}
          </Typography>
        </Box>
      )
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row.username
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      valueGetter: (params) => params.row.emailDetails.email
    },
    {
      field: 'phone',
      headerName: 'Phone',
      minWidth: 140,
      valueGetter: (params) => params.row.phone
    },
    {
      field: 'gender',
      headerName: 'Gender',
      minWidth: 20,
      valueGetter: (params) => params.row.gender
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 140,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
      },       
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return (
            <TableButton 
              type={getStatusType(params.row.status)}
            >
                {params.row.status}
            </TableButton>
          )
        }
      },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          minWidth: 160,
          type: 'actions',
          renderCell: (params) => (
            <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                {/* <TableIconButton type="view" onClick={() => navigate(`/admin/doctors/profile/${params.row._id}`)} /> */}
                <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
            </Box>
          ),
        },
    ],
    [rowId]
  );

  const DeleteModalContent = () => (
    <Box>
      <Typography variant='subtitle1' fontWeight="bold"  mb={3}>
        Are you sure you want to delete this doctor?
      </Typography>
      <Typography variant='subtitle2' mb={3}>
        This will delete this doctor permanently. You cannot undo this action.
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap={1}>
       <CustomButton 
        title="Cancel" 
        onClickHandler={closeDeleteModal}
        variant="outlined"  
        color={theme.palette.custom.ashWhite.light}
        disabled={deleteRequestStatus === "pending"}
      />
       <CustomButton 
        title="Delete" 
        onClickHandler={() => deleteDoctor() }
        color={theme.palette.custom.red.light}
        disabled={deleteRequestStatus === "pending"}
      />

      </Box>
    </Box>
  )

  return (
    <>
      { doctorsStatus === 'loading'
        ? <BackdropLoader /> 
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
            <PageHeader>
              <PageHeading heading="Doctors List" text="List of all Doctors" />
              <CustomButton title="Add Doctor" onClickHandler={() => navigate("/admin/doctors/registration") } />
            </PageHeader>
            
            <ContentContainer>
              {/* Patients table */}
              <Table 
                // heightPrecentage="40vh"
                columns={columns} 
                rows={doctors}
                setRowId={setRowId}
                basePathForRedirect={"/admin/doctors/profile"}
              />
            </ContentContainer>
          </Container>
        )
      } 
      <BasicModal 
        open={openDeleteModal}
        onClose={closeDeleteModal}
        content={DeleteModalContent}
      />
      <ToastContainer />
    </>
   
  )
}

export default DoctorsList
