import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

import { getToken } from "./authSlice"
import { ADMIN_URL, DOCTORS_URL } from "../../constants/apis"

const initialState = {
    statsData: {},
    statsDataStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    graphsData: {},
    graphsDataStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchDoctorDashboardStats = createAsyncThunk('doctorDashboardData/fetchDoctorDashboardStats', async (activeClinicId, { getState }) => {
    const authToken = getToken(getState())
        
    const response = await axios.get(`${DOCTORS_URL}/dashboard/stats?activeClinic=${activeClinicId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        return response.data
})

export const fetchDoctorDashboardGraphsData = createAsyncThunk('doctorDashboardData/fetchDoctorDashboardGraphsData', async (values, { getState }) => {
    const authToken = getToken(getState())

    const { activeClinicId, selectedYear } = values
        
    const response = await axios.get(`${DOCTORS_URL}/dashboard/graphs-data?activeClinic=${activeClinicId}&year=${selectedYear}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        return response.data
})

const doctorDashboardSlice = createSlice({
    name: 'doctorDashboardData',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // Fetch Dashboard Stats
            .addCase(fetchDoctorDashboardStats.pending, (state, action) => {
                state.statsDataStatus = 'loading'
                state.error = null
            })
            .addCase(fetchDoctorDashboardStats.fulfilled, (state, action) => {
                state.statsDataStatus = 'succeeded'
                state.statsData = action.payload
            })
            .addCase(fetchDoctorDashboardStats.rejected, (state, action) => {
                state.statsDataStatus = 'failed'
                state.error = action.error.message
            })
            // Fetch Dashboard Graphs Data
            .addCase(fetchDoctorDashboardGraphsData.pending, (state, action) => {
                state.graphsDataStatus = 'loading'
                state.error = null
            })
            .addCase(fetchDoctorDashboardGraphsData.fulfilled, (state, action) => {
                state.graphsDataStatus = 'succeeded'
                state.graphsData = action.payload
            })
            .addCase(fetchDoctorDashboardGraphsData.rejected, (state, action) => {
                state.graphsDataStatus = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectDoctorDashboardStats = (state) => state.doctorDashboardData.statsData
export const getStatusDataStatus = (state) => state.doctorDashboardData.statsDataStatus

export const selectDoctorDashboardGraphsData = (state) => state.doctorDashboardData.graphsData
export const getGraphDataStatus = (state) => state.doctorDashboardData.graphsDataStatus

export const getAdminDashboardDataError = (state) => state.doctorDashboardData.error

export default doctorDashboardSlice.reducer