import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import PageHeader from '../../../components/ui/PageHeader'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import { UserProfileDetails, UserProfileOverview } from '../../../features/admin'
import { useSelector } from 'react-redux'
import { selectDoctorById } from '../../../redux/slices/doctorSlice';
import ROLES from '../../../constants/roles';

const ManageDoctor = () => {
  const { doctorId } = useParams()
  const doctor = useSelector((state) => selectDoctorById(state, doctorId))

  return (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <UserProfileOverview user={doctor} type={ROLES.DOCTOR} />
            </Grid>
            <Grid item xs={12} lg={9}>
              <UserProfileDetails user={doctor} />
            </Grid>
            </Grid>
          </Container>
  )
}

export default ManageDoctor