import { Box, useTheme } from '@mui/material';
import React from 'react'

const Field = ({ disabled=false, children }) => {
  const theme = useTheme()
  return (
    <Box 
      sx={{
        p: 1,
        border: `1px solid ${theme.palette.custom.gray.light}`,
        borderRadius: 2,
        backgroundColor: disabled ? theme.palette.custom.gray.main : ""
      }}
    >
      {children}
    </Box>
  )
}

export default Field