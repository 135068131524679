import React, { useState } from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'

import ContentContainer from '../../../../components/ui/ContentContainer'
import TableButton from '../../../../components/ui/table/TableButton'
import Avatar from '../../../../components/ui/Avatar'

import { UPLOADS_URL } from '../../../../constants/apis'
import { fetchPatients } from '../../../../redux/slices/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../../../redux/slices/authSlice';

const Field = ({ label, value }) => {
  return (
    <Box display="flex" justifyContent="space-between" my={2}>
      <Typography>
          {label}
      </Typography>
      <Typography maxWidth="60%" textAlign="end" fontWeight="bold" noWrap>
          {value}
      </Typography>
    </Box>
  )
}

const PatientProfileOverview = ({ patient }) => {
  const dispatch = useDispatch()
  const [userImageUrl, setUserImageUrl] = useState(patient?.picture)
  const accessToken = useSelector(getToken) 

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const handleImageUpload = async (file) => {
    try {
      setUpdateRequestStatus("pending")
      const formData = new FormData();
      formData.append('image', file);

      const res = await axios.post(`${UPLOADS_URL}/patient/picture/${patient?._id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`
        }
      })
      if(res.status === 200) {
        if (res?.data?.path) {
          toast.success("Profile picture updated successfully.")
          setUserImageUrl(`${res?.data?.path}?${Math.random()}`)
          // dispatch(fetchPatients())
          // setUploadedPicturePath(res?.data?.path)
          setTimeout(() => dispatch(fetchPatients(), 2000))
        } else {
          toast.error("Something went wrong, please try again later.")
        }
        } else {
          toast.error("Something went wrong, please try again later.")
        }

    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong.")
    } finally {
      setUpdateRequestStatus('idle')
    }
  }
  
  const getStatusType = (patientStatus) => {
    return patientStatus === "new" ? "success" : "info"
  }

  return (
    <ContentContainer>
        <Box px={1}>
          <Box display="flex" justifyContent="center" my={2}>
            <Avatar 
              name="Patient Image"
              imageUrl={userImageUrl} 
              onImageUpload={handleImageUpload}
              loading={updateRequestStatus === "pending"}
            />
          </Box>
          <Typography fontWeight="bold" variant='h6' textAlign="center" gutterBottom>
            {patient?.firstName} {patient?.lastName}
          </Typography>
          <Field label="Patient ID" value={`P-${patient?.patientId}`} />
          <Field label="MRN" value={patient?.mrn} />
          <Field label="National ID" value={patient?.nationalId} />
          <Field label="Gender" value={patient?.gender !== "n/a" ? patient?.gender: ""} />
          <Field label="Date of Birth" value={patient?.dob?.substring(0, 10)} />
          <Field label="Age" value={patient?.age} />
          <Field label="Email" value={patient?.email} />
          <Field label="Phone" value={patient?.phone} />
          <Field label="Address" value={`${patient?.address?.street ? patient?.address?.street + "," : ""} ${patient?.address?.city}`} />
          <Field label="Hospital" value={patient?.clinic?.hospital?.name} />
          <Field label="Clinic" value={patient?.clinic?.name} />
          <Box display="flex" justifyContent="space-between" my={1.5}>
            <Typography>
                Status
            </Typography>
            <TableButton 
                type={getStatusType(patient?.status)}
            >
              {patient?.status}
            </TableButton>
          </Box>
        </Box>
        <ToastContainer />
    </ContentContainer>
  )
}

export default PatientProfileOverview