import React, { useEffect, useState } from 'react'
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Card, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui';

import 'survey-core/defaultV2.min.css'

import { fetchLists, getAllLists, getListsError, getListsStatus } from '../../../../redux/slices/listSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';
import Text from './sentence/Text';
import TextInput from './sentence/TextInput';
import SingleSelect from './sentence/SingleSelect';

const Sentence = ({ addSentenceField }) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [json, setJson] = useState([])

    const lists = useSelector(getAllLists)
    const listsStatus = useSelector(getListsStatus)
    const listsError = useSelector(getListsError)

    useEffect(() => {
        if (lists?.length === 0 && listsStatus !== "loading")
            dispatch(fetchLists())
    }, [])

    const survey = new Model({
      pages: [
        {
          elements: [
            {
            type: "panel",
              questionTitleLocation: "hidden",
              elements: json
           }
         ]
        }
      ]
    })

    // Add properties
    survey.emptySurveyText = "Please add components to get started."
    survey.validationEnabled = false
    survey.showNavigationButtons = false
    survey.showCompletedPage = false
    survey.showQuestionNumbers = false

    survey.onUpdateQuestionCssClasses.add(function(_, options) {
      const classes = options.cssClasses;
      if (options.question.getType() === "html") {
          classes.root += " survey-html-question"
      }
  });
  
  survey.onUpdatePanelCssClasses.add(function(_, options) {
    const classes = options.cssClasses;
    if(options.panel.getQuestionTitleLocation() === "hidden") {
      classes.panel.content = "survey-html-panel";
    }
  });

  const addFieldData = (data) => {
    setJson((prev) => [...prev, data])
  }

    const submitHandler = async (e) => {
      e.preventDefault()
      addSentenceField(
        {
          // name: uuidv4(),
          type: "panel",
          title: "Panel",
          questionTitleLocation: "hidden",
          panelType: "html",
          elements: json
         }
      )
      setOpenModal(false)
      setJson([])
    }

    const AddFormContent = () => (
      <Box height={600}>
        <Box mb={4} height={100}>
          {json.length === 0 
            ? 
              <Card sx={{ backgroundColor: theme.palette.custom.gray.light, padding: 4}}>
                <Typography textAlign="center">Please add components to get started</Typography>
              </Card>
            :
              <Survey 
                model={survey}
              />
          }
          <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
            <CustomButton 
              title="Cancel" 
              onClickHandler={() => setOpenModal(false)}
              variant="outlined"  
              color={theme.palette.custom.ashWhite.light}
            />
            <CustomButton 
                title="Save sentence" 
                onClickHandler={submitHandler}
              />
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} my={4}>
              <Text addFieldData={addFieldData} />
            </Grid>
            <Grid item xs={12} md={4} my={4}>
              <TextInput addFieldData={addFieldData} />
            </Grid>
            <Grid item xs={12} md={4} my={4}>
              <SingleSelect addFieldData={addFieldData} />
            </Grid>
          </Grid>
          
        </Box>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <CodeIcon color="custom.gray" />
              <Typography>Sentence</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Add Sentence Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
          maxSize="lg"
      />
    </>

  )
}

export default Sentence