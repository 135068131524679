import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Box, Button, Container, IconButton, Typography, useTheme  } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'
import PageHeader from '../../../components/ui/PageHeader'
import { ToastContainer, toast } from 'react-toastify'
import BasicModal from '../../../components/ui/modal/BasicModal'

import { COORDINATORS_URL, LISTS_URL } from '../../../constants/apis';
import { getToken } from '../../../redux/slices/authSlice'
import { fetchClinics } from '../../../redux/slices/clinicSlice';
import { fetchCoordinators, getCoordinatorsError, getCoordinatorsStatus, selectAllCoordinators } from '../../../redux/slices/coordinatorSlice';
import { fetchLists, getAllLists, getListsError, getListsStatus } from '../../../redux/slices/listSlice';

const ListsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle')

  const accessToken = useSelector(getToken)

   const lists = useSelector(getAllLists)
   const listsStatus = useSelector(getListsStatus)
   const listsError = useSelector(getListsError)

  useEffect(() => {
      if (listsStatus !== "loading" && lists.length === 0)
          dispatch(fetchLists())
  }, [])

  useEffect(() => {
    if(listsError)
      toast.error(listsError)
  }, [listsError])

  const getStatusType = (listsStatus) => {
    return listsStatus === "active" ? "success" : "disabled"
}

const closeDeleteModal = () => {
  setOpenDeleteModal(false)
  setDeleteItemId(null)
}

const onDeleteHandler = (id) => {
  setDeleteItemId(id)
  setOpenDeleteModal(true)
}

const deleteList = async () => {
  setDeleteRequestStatus('pending')
    const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
      },
  }
  try {
    const res = await axios.delete(`${LISTS_URL}/delete/${deleteItemId}`, config) 
    if(res.status === 200) {
      toast.success("List deleted successfully.")
      closeDeleteModal()
      dispatch(fetchLists())
    } else {
      toast.error("Something went wrong!")
    }
  } catch (err) {
    toast.error(err.response?.data?.message)
  } finally {
    setDeleteRequestStatus('idle')
  }
}

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => params.row?.name
    },
    {
      field: 'itemsLength',
      headerName: 'Items',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row?.items?.length
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>  params.row?.category
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 140,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString()?.substring(0, 10)
    },       
    {
      field: 'updatedAt',
      headerName: 'Updated on',
      flex: 1,
      minWidth: 140,
      filterable: false,
      valueGetter: (params) => params.row.updatedAt?.toString()?.substring(0, 10)
    },       

        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          minWidth: 160,
          type: 'actions',
          renderCell: (params) => (
            <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
            </Box>
          ),
        },
    ],
    [rowId]
  );

  const DeleteModalContent = () => (
    <Box>
      <Typography variant='subtitle1' fontWeight="bold"  mb={3}>
        Are you sure you want to delete this list?
      </Typography>
      <Typography variant='subtitle2' mb={3}>
        This will delete this list permanently. You cannot undo this action.
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap={1}>
       <CustomButton 
        title="Cancel" 
        onClickHandler={closeDeleteModal}
        variant="outlined"  
        color={theme.palette.custom.ashWhite.light}
        disabled={deleteRequestStatus === "pending"}
      />
       <CustomButton 
        title="Delete" 
        onClickHandler={() => deleteList() }
        color={theme.palette.custom.red.light}
        disabled={deleteRequestStatus === "pending"}
      />

      </Box>
    </Box>
  )

  return (
    <>
      { listsStatus === 'loading'
        ? <BackdropLoader /> 
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
            <PageHeader>
              <PageHeading heading="Lists" text="Lists of various categories" />
              <CustomButton title="Add List" onClickHandler={() => navigate("/admin/lists/add-list") } />
            </PageHeader>
            
            <ContentContainer>
              {/* Lists table */}
              
                <Table 
                  columns={columns} 
                  rows={lists}
                  setRowId={setRowId}
                  basePathForRedirect={"/admin/lists/edit-list"}
                />
             
            </ContentContainer>
          </Container>
        )
      } 
      <BasicModal 
        open={openDeleteModal}
        onClose={closeDeleteModal}
        content={DeleteModalContent}
      />
      <ToastContainer />
    </>
   
  )
}

export default ListsList
