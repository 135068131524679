import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    const theme = useTheme()

    return (
        <Container sx={{ mt: 2, pb: 1 }} maxWidth="md">
            <Typography 
                sx={{ 
                    color: theme.palette.secondary.light
                }}
                textAlign="center" 
            >
                @2024 Enayahub. All rights reserved
            </Typography>
        </Container>
    )
}

export default Footer