import React, { useState } from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import CustomButton from '../../components/ui/CustomButton'
import BasicModal from '../../components/ui/modal/BasicModal'

import { SUBFORM_TEMPLATES_URL } from '../../constants/apis'

import { getToken } from '../../redux/slices/authSlice'
import CustomSubmitButton from '../../components/ui/CustomSubmitButton';
import { useNavigate } from 'react-router-dom';
import { fetchSubformTemplates } from '../../redux/slices/formSlice';
import { formCategories } from '../../constants/data';

const initialValues = {
  title: '',
  category: '',
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .matches(/^[a-zA-Z\s]*$/, 'Invalid title')
    .min(2, 'Title should be min 2 characters long')
    .max(100, 'Title should be max 100 characters long'),
  category: Yup.string().required("Category is required."),
})


const AddSubformModal = ({ open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const accessToken = useSelector(getToken)

    const submitHandler = async (values, { resetForm }) => {
      try {
        setAddRequestStatus('pending')
        const res = await axios.post(SUBFORM_TEMPLATES_URL, 
          {
            ...values
           }, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        }) 
        if(res.status === 201) {
          resetForm({ values: '' })
          const { _id } = res?.data
          toast.success("Subform template added successfully.")
          dispatch(fetchSubformTemplates())
          closeModal("add_modal")
          setTimeout(() => 
            navigate(`/admin/form-templates/design/${_id}`)
          , 1500)
        } else {
          toast.error("Something went wrong, please try again later.")
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
      } finally {
        setAddRequestStatus('idle')
      }
    }

    const AddFormContent = () => (
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2}>

                  {/* Title  */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="title"
                      label="Title"
                      variant="outlined"
                      fullWidth
                      error={errors.title && touched.title}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>  

                {/* Category */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                      <InputLabel htmlFor="category">Category</InputLabel>
                      <Field
                        as={Select}
                        input={<OutlinedInput label="Category" />} 

                        name="category"
                        variant="outlined"
                        fullWidth
                        error={errors.category && touched.category}
                        helperText={touched.category && errors.category}
                      >
                        {formCategories?.map((category, index) => (
                          <MenuItem key={index} value={category}>{category}</MenuItem>
                        ))}
                      </Field>
                  </FormControl>
                </Grid>
                

                  {/* Actions */}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                      <CustomButton 
                          title="Cancel" 
                          onClickHandler={() => closeModal("add_modal")}
                          variant="outlined"  
                          color={theme.palette.custom.ashWhite.light}
                          disabled={addRequestStatus === "pending"}
                        />
                        <CustomSubmitButton 
                          title="Add Form"
                          disabled={addRequestStatus === "pending"}
                        />
                    </Box>
                  </Grid>
                </ Grid>
              </Form>
            )}
          </Formik>
        </Box>
      )


    return (
        <BasicModal 
            title="Add Subform Template"
            open={open}
            onClose={closeModal}
            content={AddFormContent}
        />
    )
}

export default AddSubformModal