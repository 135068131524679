import React from 'react';
import { Select, MenuItem, Paper } from '@mui/material';

const YearSelect = ({ selectedYear, onYearChange, disabled=false }) => {
  const years = [];
  const currentYear = new Date().getFullYear();

  // Generate a list of years, change the range as per your requirements
  for (let year = currentYear; year >= currentYear - 10; year--) {
    years.push(year);
  }

  const handleYearChange = (event) => {
    onYearChange(event.target.value);
  };

  return (
      <Select
          value={selectedYear}
          onChange={handleYearChange}
          disabled={disabled}
          sx={{
            height: 46,
            width: 120,
            borderRadius: 1.5,
            boxShadow: 0.8,
            bgcolor: "#FFF"
          }}
        >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
  );
};

export default YearSelect;
