import { Box, Typography } from '@mui/material';
import React from 'react'
import { SIZES } from '../../constants/theme';


const ChartHeading = ({ text="" }) => {
  return (
    <Box>
        <Typography
          fontSize={{
            md: SIZES.small,
            xs: SIZES.xSmall,
          }} 
          variant='body1' 
          fontWeight="bold" 
          color="secondary.light"
        >
            {text}
        </Typography>
    </Box>
  )
}

export default ChartHeading