import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useLocation} from 'react-router-dom'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'

import { ICONS } from '../../../assets'
import UpdateClinicModal from '../../../features/common/UpdateClinicModal';
import { useSelector } from 'react-redux';
import { getUserActiveRole, selectUser } from '../../../redux/slices/authSlice';
import { Toggle } from '../../../features/admin';
import ROLES from '../../../constants/roles';

const drawerWidthOpen = 240
const paddingIconButton = 10
const marginIconButton = 14
const iconFontSize = 20
const drawerWidthClose =
  (paddingIconButton + marginIconButton) * 2 + iconFontSize

const SideNavbar = ({ open, setOpen, sidebarMenu, handleClick, handleLogout })  => {
  const theme = useTheme()
  const location = useLocation()
  const [openModal, setOpenModal] = useState(false)

  const user = useSelector(selectUser)
  const userActiveRole = useSelector(getUserActiveRole)

  const MenuList = ({ list={list} }) => {
    return (
      <List dense={true}>
        {list?.map((key, index) => (
          <>
              <Tooltip
                title={open ? '' : key.text }
                placement={'right'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'gray',
                      color: 'white',
                      marginLeft: '8px !important',
                      boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)',
                    },
                  },
                }}
              >
                <ListItemButton
                  button
                  onClick={() => handleClick(key.path)}
                  sx={{
                    padding: '14px 0',
                    paddingLeft: 3,
                    borderRight: key.isActive ? `5px solid ${theme.palette.primary.light}` : '',
                    backgroundColor: key.isActive ? theme.palette.custom.ashWhite.main : '',
                    '&:hover': {
                      backgroundColor: open ? theme.palette.custom.ashWhite.main : "transparent",
                    },
                  }}

                >
                  <ListItemIcon sx={{ minWidth: '46px' }}>
                    <Badge
                      badgeContent={key.badge}
                      color="primary"
                    >
                      <key.icon sx={{ fontSize: '24px', color: key.isActive ? theme.palette.primary.main : theme.palette.secondary.light }} />
                    </Badge>
                  </ListItemIcon>

                  <ListItemText
                    primary={key.text}
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    sx={{
                      display: 'inline',
                      margin: '0px',
                      overflowX: 'hidden',
                      color: key.isActive ? theme.palette.primary.main : theme.palette.secondary.light,
                      whiteSpace: 'nowrap',
                      minWidth: '126px',
                    }}
                  />
                </ListItemButton>
              </Tooltip>
          </>
        ))}
      </List>
    )
  }

const SecondaryMenu = () => {
  return (
  <List>
    {/* Show for doctors and coordinators only */}
    { userActiveRole !== ROLES.ADMIN  
      ? ( 
        <Tooltip
          title={open ?  "" : 'Change Clinic'}
          placement={'right'}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'gray',
                color: 'white',
                marginLeft: '8px !important',
                boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)',
                
              },
            },
          }}
        >
          <ListItemButton
            button
            onClick={() =>  {
              handleClick("#")
              setOpenModal(true)
            }}
            sx={{
              padding: '14px 0',
              paddingLeft: 3,
              borderRight: location.pathname == "/profile" ? `5px solid ${theme.palette.primary.light}` : '',
                backgroundColor: location.pathname == "/profile" ? theme.palette.custom.ashWhite.main : '',
              '&:hover': {
                backgroundColor: open ? theme.palette.custom.ashWhite.main : "transparent",
              },
            }}

          >
            <ListItemIcon sx={{ minWidth: '46px' }}>
                <LocalHospitalIcon sx={{ fontSize: '24px', color: theme.palette.secondary.light}} />
            </ListItemIcon>

            <ListItemText
              primary="Change Clinic"
              primaryTypographyProps={{
                variant: 'body1',
              }}
              sx={{
                display: 'inline',
                fontSize: "16px",
                margin: '0px',
                overflowX: 'hidden',
                color: theme.palette.secondary.light,
                whiteSpace: 'nowrap',
                minWidth: '126px',
              }}
            />
          </ListItemButton>
        </Tooltip>
      ) : null
    }
    <Tooltip
      title={open ? '' : 'Logout'}
      placement={'right'}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'gray',
            color: 'white',
            marginLeft: '22px !important',
            boxShadow: '0px 0px 22px -2px rgba(0,0,0,0.20)',
          },
        },
      }}
    >
      <ListItemButton
        button
        onClick={handleLogout}
        sx={{
          padding: '14px 0',
          paddingLeft: 3,
          '&:hover': {
            backgroundColor: open ? theme.palette.custom.ashWhite.main : "transparent",
          },
        }}

      >
        <ListItemIcon sx={{ minWidth: '46px' }}>
            <LogoutIcon sx={{ fontSize: '24px', color: theme.palette.secondary.light}} />
        </ListItemIcon>

        <ListItemText
          primary="Logout"
          primaryTypographyProps={{
            variant: 'body1',
          }}
          sx={{
            display: 'inline',
            fontSize: "16px",
            margin: '0px',
            overflowX: 'hidden',
            color: theme.palette.secondary.light,
            whiteSpace: 'nowrap',
            minWidth: '126px',
          }}
        />
      </ListItemButton>
    </Tooltip>
  </List>
  )
}

  const drawerContent = (
    <Box mt={2} display="flex" flexDirection="column" justifyContent="space-between" height="100vh" zIndex={1}>
      <Box>
        <Box 
          display="flex" 
          justifyContent={open ? "space-between" : "flex-end" } 
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" }, marginRight: "10px" }}
        >
          {open 
            ?
              <Box display="flex" alignItems="center" gap={1} ml={2} >
                <img
                  alt="Logo"
                  src={ICONS.logo}
                  style={{
                    height: "3.5rem",
                    width: "7rem"
                  }}
                  loading='lazy'
                />
                {/* <Typography variant="h5" fontWeight="bold"> 
                  Medicare
                </Typography> */}
              </Box>
            : null
          }
          <IconButton sx={{ mt: 1.5, pt: 0 }}  onClick={() => setOpen(!open)}>
            <MenuIcon color="primary"  />
          </IconButton>  
        </Box>

        {/* List */}
        <Box mt={2.5}>
            {open && <Typography variant='body2' color="text.disabled" pl={3}>Main Menu</Typography>}
            <MenuList list={sidebarMenu} />
            {open && <Typography variant='body2' color="text.disabled" mt={1} pl={3}>Account & Settings</Typography>}
            <SecondaryMenu />
        </Box>
      </Box>
      {user?.roles?.includes(ROLES.ADMIN) && 
        (
          <Box mb={2}>
            <Toggle sidebarOpen={open} />
          </Box>
        ) 
      }
    </Box>
  );



  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: open
          ? { xs: '0px', md: drawerWidthOpen }
          : { xs: drawerWidthOpen, md: drawerWidthClose },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
          }),
          '& .MuiDrawer-paper': {
            justifyContent: 'start',
            overflowX: 'hidden',
            overflowY: open ? 'scroll' : 'hidden',
            scrollbarWidth: 'thin',
            width: open
            ? { xs: '0px', md: drawerWidthOpen }
            : { xs: drawerWidthOpen, md: drawerWidthClose },
            borderRight: '0px',
            borderRadius: '0px 0px 0px 0px',
            boxShadow: theme.shadows[1],
            backgroundColor: "FFF",
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen ,
            }),
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <UpdateClinicModal 
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Box>
  );
}

export default SideNavbar