import React, { useState } from 'react'
import { Box, Grid, TextField, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../../redux/slices/authSlice'
import { fetchHospitals } from '../../../../redux/slices/hospitalSlice'
import { fetchClinics } from '../../../../redux/slices/clinicSlice'
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton'
import CustomButton from '../../../../components/ui/CustomButton'
import BasicModal from '../../../../components/ui/modal/BasicModal'
import { CLINICS_URL } from '../../../../constants/apis';

const initialValues = {
    name: '',
    phone: "",
    email: ""
  }
  
  const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Invalid clinic name')
        .required('Name is required'),
    email: Yup.string().email('Invalid email address'),
    phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number')
  })


const AddClinicModal = ({ hospitalId, open, closeModal }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const accessToken = useSelector(getToken)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    
    const submitHandler = async (values, { resetForm }) => {
        try {
          setAddRequestStatus('pending')
          const res = await axios.post(CLINICS_URL, {
                                            ...values, 
                                            hospital: hospitalId
                                        }, {
                                            headers: {
                                            'Content-type': 'application/json',
                                            Authorization: `Bearer ${accessToken}`
                                            },
                                        }
                                    ) 
          if(res.status === 201) {
            closeModal()
            dispatch(fetchHospitals())
            dispatch(fetchClinics())
            toast.success("Clinic added successfully.")
            resetForm({ values: '' })
          } else {
            toast.error("Something went wrong, please try again later.")
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
        } finally {
          setAddRequestStatus('idle')
        }
      }

    const AddModalContent = () => {
        return (
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                >
                {({ errors, touched }) => (
                <Form>
                    <Grid container spacing={3}>    
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="name"
                                label="Name"
                                variant="outlined"
                                fullWidth
                                error={errors.name && touched.name}
                                helperText={touched.name && errors.name}
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                error={errors.email && touched.email}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="phone"
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                error={errors.phone && touched.phone}
                                helperText={touched.phone && errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                                <CustomButton 
                                    title="Cancel" 
                                    onClickHandler={closeModal}
                                    variant="outlined"  
                                    color={theme.palette.custom.ashWhite.light}
                                    disabled={addRequestStatus === "pending"}
                                />
                                <CustomSubmitButton 
                                    title="Add" 
                                    disabled={addRequestStatus === "pending"}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
                )}
                </Formik>
            </Box>
        )
    }


    return (
        <BasicModal 
            title="Add clinic to hospital"
            open={open}
            onClose={closeModal}
            content={AddModalContent}
    />
    )
}

export default AddClinicModal