import { Box, Button, Container, Paper, Typography, gridClasses, useTheme } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import EmptyTableContent from "./EmptyTableContent"
import { useState } from "react"
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

const Table = ({ 
    heightPercentage="68vh", 
    columns, 
    rows,
    setRowId, 
    showToolbar=false,
    isLoading=false,
    basePathForRedirect=null
 }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState(5)
    const [searchText, setSearchText] = useState('')
    const [filteredRows, setFilteredRows] = useState([])

    const handleRowClick = (params) => {
        if(basePathForRedirect) {
            navigate(`${basePathForRedirect}/${params?.row?._id}`)
        }
      };

    return (
      <Box>
           {/* Table Content */}
            <Box 
                sx={{
                        height: heightPercentage,
                        // scrollbarWidth: 'thin'
                    }}
            >
                { isLoading 
                    ? <Loader />
                    :
                        <DataGrid
                            checkboxSelection 
                            autoPageSize
                            columns={columns}
                            rows={rows}
                            checkboxSelection={false}
                            onRowClick={handleRowClick}
                            getRowId={(row) => row._id}
                            slots={{ 
                                noRowsOverlay: EmptyTableContent,
                                toolbar: showToolbar ? GridToolbar : null
                            }}
                            getRowSpacing={(params) => ({
                                top: params.isFirstVisible ? 0 : 2,
                                bottom: params.isLastVisible ? 0 : 2,
                            })}
                            sx={{
                                overflow: 'hidden',
                                '&, [class^=MuiDataGrid]': { border: 'none' },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: theme.palette.custom.gray.main,
                                },
                                '& .MuiDataGrid-row': {
                                    cursor: "pointer",
                                },
                                '& .MuiDataGrid-cellContent': {
                                    color: theme.palette.secondary.dark,
                                    letterSpacing: 0.5,
                                    fontWeight: 600
                                },
                                '& .MuiDataGrid-virtualScrollerContent': {
                                    paddingBottom: 0,
                                scrollbarWidth: 'thin'
                                },
                                [`& .${gridClasses.row}`]: {
                                    cursor: "pointer",
                                  },  
                            }}
                            onCellEditCommit={(params) => setRowId(params.id)}
                        />
                }
            </Box>

        </Box>
    )
}

  export default Table