import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Box, TextField, Select, Container, FormControl,
   Grid, InputLabel, MenuItem, Typography, OutlinedInput, useTheme
  } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import CustomSubmitButton from '../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../components/ui/CustomButton';
import { fetchHospitals, selectAllHospitals } from '../../../redux/slices/hospitalSlice';
import { fetchClinics, selectAllClinics } from '../../../redux/slices/clinicSlice';
import { fetchPatients, getPatientsStatus } from '../../../redux/slices/patientSlice';
import { getUserActiveRole } from '../../../redux/slices/authSlice';
import ROLES from '../../../constants/roles';

import DateRangePicker from '../../../components/ui/DateRangePicker'

const initialValues = {
    name: '',
    mrn: '',
    nationalId: '',
    phone: '',
    diagnosis: '',
    hospital: '', 
    clinic: '',
    fromDate: '',
    toDate: ''
  }
  
  
  const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    name: Yup.string(),
    mrn: Yup.string(),
    nationalId: Yup.string(),
    phone: Yup.string(),
    diagnosis: Yup.string(),
    hospital: Yup.string(),
    clinic: Yup.string(),
    fromDate: Yup.string(),
    toDate: Yup.string()
  })
  
  
  const PatientSearchForm = ({ hospitalClinics, setHospitalClinics }) => {
    const theme = useTheme()
    const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState("");
  
    const userActiveRole = useSelector(getUserActiveRole) 

    const hospitals = useSelector(selectAllHospitals)
    const clinics = useSelector(selectAllClinics)
  
    const patientsStatus = useSelector(getPatientsStatus)

    useEffect(() => {
      if(userActiveRole === ROLES.ADMIN && hospitals?.length === 0 && clinics?.length === 0) {
        dispatch(fetchHospitals())
        dispatch(fetchClinics())
      }
    }, [])
  
    const onHospitalChange = (hospital) => {
      const filteredClinics = clinics?.filter((item) => item.hospital._id == hospital)
      setHospitalClinics((prev) => filteredClinics)
    }
  
    const filterSubmitHandler = async (values) => {
        dispatch(fetchPatients(values))
    }
  
    const resetFilters = async () => {
        setInputValue("")
        dispatch(fetchPatients({}))
    }

    return (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={filterSubmitHandler}
        >
        {({ values, errors, touched, setFieldValue, resetForm }) => (
          <Form>
            <Grid 
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.custom.white.main,
                borderRadius: 3,
                px: 1.5,
                pt: 1.5,
                pb: 2,
                mb: 2.5,
                mx: 0,
                gap: 2
              }}
              container 
              spacing={1}
            >
              <Grid container xs={12} md={10} spacing={2} >
                <Grid item xs={12} md={3}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.name && touched.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>     
                <Grid item xs={12} md={3}>
                  <Field
                    as={TextField}
                    name="mrn"
                    label="MRN"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.mrn && touched.mrn}
                    helperText={touched.mrn && errors.mrn}
                  />
                </Grid>     
                <Grid item xs={12} md={3}>
                  <Field
                    as={TextField}
                    name="nationalId"
                    label="National ID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.nationalId && touched.nationalId}
                    helperText={touched.nationalId && errors.nationalId}
                  />
                </Grid>     
                <Grid item xs={12} md={3}>
                  <Field
                    as={TextField}
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.phone && touched.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>     
                <Grid item xs={12} md={3}>
                  <Field
                    as={TextField}
                    name="diagnosis"
                    label="Diagnosis"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.diagnosis && touched.diagnosis}
                    helperText={touched.diagnosis && errors.diagnosis}
                  />
                </Grid>  
                <Grid item xs={12} md={3}>
                  <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="hospital">Hospital</InputLabel>
                    <Field
                      as={Select}
                      input={<OutlinedInput label="Hospital" />} 
                      name="hospital"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={errors.hospital && touched.hospital}
                      helperText={touched.hospital && errors.hospital}
                      // onChange={(e) => { onHospitalChange(e.target.value) }}
                    >
                      {
                        hospitals?.map((item) => (
                          <MenuItem
                            key={item._id} 
                            value={item.name}
                            onClick={() => {
                              onHospitalChange(item._id)
                              setFieldValue("clinic", "")
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    </FormControl>
                </Grid>  
                <Grid item xs={12} md={3}>
                  <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="clinic">Clinic</InputLabel>
                    <Field
                      as={Select}
                      input={<OutlinedInput label="Clinic" />} 
                      name="clinic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={errors.clinic && touched.clinic}
                      helperText={touched.clinic && errors.clinic}
                    >
                      {
                        !values?.hospital 
                          ? <MenuItem size="small">Please select hospital first.</MenuItem>
                          :
                            hospitalClinics?.length === 0
                              ? <MenuItem size="small">No clinic available.</MenuItem>
                              :
                              hospitalClinics?.map((item) => (
                                <MenuItem key={item._id} value={item.name}>{item.name}</MenuItem>
                              ))
                        }
                      
                    </Field>
                  </FormControl>
                </Grid> 
                <Grid item xs={12} md={3}>
                  <DateRangePicker 
                    fromDate={values?.fromDate}
                    setFromDate={(date) => setFieldValue("fromDate", date)}
                    toDate={values?.toDate}
                    setToDate={(date) => setFieldValue("toDate", date)}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                  />
                </Grid> 
              </Grid>
              <Grid container xs={12} md={2} spacing={2}>
                <Grid item xs={6} md={12}>
                    <CustomSubmitButton 
                      title="Search"
                      disabled={patientsStatus === "pending"}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={6} md={12}>
                    <CustomButton 
                      title="Reset"
                      disabled={patientsStatus === "pending"}
                      variant="outlined"
                      onClickHandler={() => {
                        resetForm({ values: ''})
                        resetFilters(values)
                      }}
                      fullWidth
                    />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
        </Formik>
    )
  }

export default PatientSearchForm