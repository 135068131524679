import React, { useState } from 'react'
import { Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';

import { CLINICS_URL } from '../../../../constants/apis'

import { fetchClinics } from '../../../../redux/slices/clinicSlice'
import { getToken } from '../../../../redux/slices/authSlice'


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string(),
  status: Yup.string().required('Status is required'),
})


const ProfileData = ({ clinic }) => {
  const dispatch = useDispatch()
  
  const initialValues = {
    name: clinic?.name || "",
    email: clinic?.email || "",
    phone: clinic?.phone || "",
    status: clinic?.status || "",
  }
  
  const accessToken = useSelector(getToken)
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const submitHandler = async (values, { resetForm }) => {
    try {
      setUpdateRequestStatus("pending")
      const res = await axios.patch(`${CLINICS_URL}/${clinic?._id}`, {...values }, {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
      })
      if(res.status === 200) {
        dispatch(fetchClinics())
        toast.success("Clinic updated successfully.")
        // resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      } 
    } catch (err) {
      toast.error(err.response.data.message)
    } finally {
      setUpdateRequestStatus("idle")
    }
  }

  return (
    <>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid> 
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Status" />} 
                  name="status"
                  variant="outlined"
                  fullWidth
                  error={errors.status && touched.status}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Field>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Update"
                    disabled={updateRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </>
  )
}

export default ProfileData