import React from 'react'
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';

const DataWidget = ({title, value1, value2, value3, icon, color}) => {
  const theme = useTheme();
    return (
        <Card sx={{ height: 160, width: 'auto', borderBottom:'3px solid', borderBottomColor: {color} }}>
          <CardContent>
            <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',}}>
            <Typography sx={{ fontSize: 14, fontWeight:600 }} color="secondary.light" gutterBottom>
              {title}
            </Typography>
            {icon}
            </Box>
            <Typography sx={{ fontSize: 26, fontWeight:700, marginTop:2 }} color="secondary.dark" gutterBottom>
              {value1}
            </Typography>
           
            <Typography sx={{ fontSize: 14, fontWeight:700, marginTop:2 }} color='primary.main'>
              {value2} <span style={{ color: theme.palette.custom.gray.main, marginLeft:4 }}>   {value3}</span>
            </Typography>
          </CardContent>
          
        </Card>
      );
}

export default DataWidget;