import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { useSelector } from "react-redux"
import { getToken } from "./authSlice"
import { CLINICS_URL, HOSPITALS_URL } from "../../constants/apis"

const initialState = {
    clinics: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchClinics = createAsyncThunk('clinics/fetchClinics', async (_, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(CLINICS_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
          },
    })
        const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
        return dataWithId
})

const clinicSlice = createSlice({
    name: 'clinics',
    initialState,
    reducers: {
        addClinic (state, action) {
            // state.clinics.push({
            //     ...action.payload,
            //     id: state.clinics.length + 1
            // })
        },
    },
    extraReducers(builder) {
        builder
            // Fetch Clinics  
            .addCase(fetchClinics.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(fetchClinics.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.clinics = action.payload
            })
            .addCase(fetchClinics.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllClinics = (state) => state.clinics.clinics
// export const selectHospitalClinics = (state) => state.clinics.clinics
export const getClinicsStatus = (state) => state.clinics.status
export const getClinicsError = (state) => state.clinics.error

export const selectClinicById = (state, clinicId) =>
    state.clinics.clinics.find(clinic => clinic._id == clinicId)

export const selectHospitalClinics = (state, hospitalId) =>
    state.clinics.clinics.filter(clinic => clinic?.hospital?._id == hospitalId)

export const { addClinic } = clinicSlice.actions

export default clinicSlice.reducer