import React, { useEffect, useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

import CustomButton from '../../components/ui/CustomButton'
import BasicModal from '../../components/ui/modal/BasicModal'
import CustomSubmitButton from '../../components/ui/CustomSubmitButton'

import { 
  fetchUserHospitalsAndClinics, 
  getUserClinics, 
  getUserHospitals, 
  getUserStatus, 
  logoutUser,
  selectUser,
  updateUserClinic
} from '../../redux/slices/authSlice'

const initialValues = {
  // hospital: '',
  // clinic: '',

  // Dummy data
  hospital: '',
  clinic: '',
}

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  hospital: Yup.string().required('Hospital is required'),
  clinic: Yup.string().required('Clinic is required'),
})

const SelectClinicModal = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(true)
    const [hospitalClinics, setHospitalClinics] = useState([])

    const userHospitals = useSelector(getUserHospitals)
    const userClinics = useSelector(getUserClinics)
    const user = useSelector(selectUser)

    useEffect(() => {
      if(user)
        dispatch(fetchUserHospitalsAndClinics())
  }, [])

  const onHospitalChange = (hospital) => {
    const filteredClinics = userClinics?.filter((item) => item.clinic?.hospital?._id == hospital)
    setHospitalClinics((prev) => filteredClinics)
  }


    const submitHandler = async (values, { resetForm }) => {
      try { 
        dispatch(updateUserClinic(values?.clinic))
      } catch (err) {
        toast.error("Something went wrong!")
      } finally {

      }
    }

    const SelectClinicFormContent = () => (
        <Box>
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
            >
              {({ errors, setFieldValue, touched }) => (
                <Form>
                  <Grid container spacing={3}>
                      <Grid item xs={12}>
                      <FormControl fullWidth>
                          <InputLabel htmlFor="hospital">Hospital</InputLabel>
                          <Field
                            as={Select}
                            input={<OutlinedInput label="Hospital" />} 
                            name="hospital"
                            variant="outlined"
                            fullWidth
                            error={
                              Boolean(touched.clinic && errors.clinic)
                            }
                          >
                          {
                              userHospitals?.map((item) => (
                              <MenuItem
                                key={item?._id} 
                                value={item?.name}
                                onClick={() => {
                                  onHospitalChange(item?._id)
                                  setFieldValue("clinic", "")
                                }}
                              >
                                  {item?.name}
                              </MenuItem>
                              ))
                          }
                          </Field>
                          {touched.hospital && errors.hospital ? (
                            <FormHelperText
                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                            >
                                {touched.hospital && errors.hospital}
                            </FormHelperText>
                            ) : null
                          }
                          </FormControl>
                      </Grid>  
                      <Grid item xs={12}>
                      <FormControl fullWidth>
                          <InputLabel htmlFor="clinic">Clinic</InputLabel>
                          <Field
                          as={Select}
                          input={<OutlinedInput label="Clinic" />} 
                          name="clinic"
                          variant="outlined"
                          fullWidth
                          error={
                            Boolean(touched.clinic && errors.clinic)
                          }
                          >
                          {
                              hospitalClinics?.map((item) => (
                              <MenuItem key={item.clinic?._id} value={item.clinic?._id}>{item.clinic?.name}</MenuItem>
                              ))
                          }
                          </Field>
                          {touched.clinic && errors.clinic ? (
                            <FormHelperText
                                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
                            >
                                {touched.clinic && errors.clinic}
                            </FormHelperText>
                            ) : null
                          }
                          </FormControl>
                      </Grid>                                         
                      <Grid item xs={12}>
                          <Box display="flex" justifyContent="flex-end" gap={2}>
                          <CustomButton
                            title="Logout"
                            variant="outlined"
                            onClickHandler={() => dispatch(logoutUser())}
                          />
                          <CustomSubmitButton 
                              title="Continue"
                              // disabled={userStatus === 'loading'}
                          />
                          </Box>
                      </Grid>
                  </Grid>
                </Form>
              )}
          </Formik> 
        </Box>
      )


    return (
        <BasicModal
            title="Select Hospital Clinic" 
            open={openModal}
            onClose={() => {}}
            content={SelectClinicFormContent}
        />
    )
}

export default SelectClinicModal