import React from 'react'
import { Paper, styled, useTheme } from '@mui/material'

const StyledPaper = styled(Paper)(({ theme, padding=1 }) => ({
  padding: theme.spacing(padding),
  height: "100%",
  width: '100%',
  position: 'relative',
  borderRadius: theme.spacing(1.5),
  [theme.breakpoints.down('md')] : {
    padding: theme.spacing(2),
  },
}))

const ContentContainer = ({ children, padding }) => {
  return (
      <StyledPaper elevation={0} padding={padding} >
          {children}  
      </StyledPaper> 
  )
}

export default ContentContainer