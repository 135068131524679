import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ReportButton } from '../../'
import { getPatientSystemReports } from '../../../../redux/slices/patientSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../../../redux/slices/authSlice';

const Reports = () => {
  const navigate = useNavigate()

  const accessToken = useSelector(getToken) 

  const patientSystemReports = useSelector(getPatientSystemReports)

  const handleFileClick = async (file) => {
    try {
      const response = await axios.get("/"+file?.urlPath, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Add other headers if needed
        },
        responseType: 'blob',
      });

      const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const fileURL = URL.createObjectURL(fileBlob);

      // Open the file in a new window
      const newWindow = window.open(fileURL);
      if (newWindow) {
        newWindow.onload = () => {
          URL.revokeObjectURL(fileURL); // Revoke the object URL after the window loads
        };
      }
    } catch (error) {
      navigate("/")
    }
  };

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
        {patientSystemReports?.length === 0 
          ? <Typography variant="display2" color="text.disabled" textAlign="center">No system reports available</Typography>
          : 
          <Grid container spacing={2}>
            {patientSystemReports?.map((report) => (
              <Grid item xs={12} md={6}>
                <button onClick={() => handleFileClick(report)}>
                  <ReportButton title={report?.name} />
                </button>
              </Grid>
            ))}
          </Grid>
        }
      </Box>
  )
}

export default Reports
