import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'


import { getFormDetails, updateForm } from '../../../../../redux/slices/formSlice';
import BasicModal from '../../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../../components/ui/CustomButton';

const initialValues = {
  text: ''
}


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  text: Yup.string().required("Text is required.")
})

const Text = ({ addFieldData }) => {
    const theme = useTheme()
    const submitHandler = async (values, { resetForm }) => {
      addFieldData({
        name: uuidv4(),
        "type": "html",
        "width": "max-content",
        "minWidth": "max-content",
        "maxWidth": "max-content",
        "html": `<p>${values?.text}</p>`,
        "startWithNewLine": false
      })
      resetForm({ values: '' })
    }
    
  return (
      <Card sx={{ padding: 2}}>
        <Box mb={3}>
          <Typography fontWeight="bold">Add Text</Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Field
                as={TextField}
                name="text"
                label="Text"
                variant="outlined"
                size="small"
                fullWidth
                error={errors.text && touched.text}
                helperText={touched.text && errors.text}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
               <CustomSubmitButton 
                  title="Add"
                  size="small"
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
  )
}

export default Text