import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getToken } from "./authSlice";
import { FORM_TEMPLATES_URL, SUBFORM_TEMPLATES_URL } from "../../constants/apis";

const initialState = {
    form: null,
    forms: [],
    subforms: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchFormTemplates = createAsyncThunk('forms/fetchFormTemplates', async (_,{ getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(FORM_TEMPLATES_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

export const fetchSubformTemplates = createAsyncThunk('forms/fetchSubformTemplates', async (_, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(SUBFORM_TEMPLATES_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

export const fetchActiveFormTemplates = createAsyncThunk('forms/fetchActiveFormTemplates', async (id, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${FORM_TEMPLATES_URL}/active`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    const dataWithId = response.data?.map((item, index) => ({...item, id: index + 1 })) || [] 
    return dataWithId
})

export const fetchFormDetails = createAsyncThunk('forms/fetchFormDetails', async (id, { getState }) => {
    const authToken = getToken(getState())
    const response = await axios.get(`${FORM_TEMPLATES_URL}/details/${id}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json", // Add other headers if needed
        }
    })
    return response.data
})

const formSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        updateForm: (state, action) => {
            state.form = action.payload
            // localStorage.setItem("form_temp", JSON.stringify(action.payload))
        },
        resetForm: (state, action) => {
            state.form = null
        }
    },
    extraReducers(builder) {
        builder
            // Fetch Form templates  
            .addCase(fetchFormTemplates.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchFormTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.forms = action.payload
            })
            .addCase(fetchFormTemplates.rejected, (state, action) => {
                state.status = 'failed'
            })
            // Fetch subform templates  
            .addCase(fetchSubformTemplates.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSubformTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.subforms = action.payload
            })
            .addCase(fetchSubformTemplates.rejected, (state, action) => {
                state.status = 'failed'
            })
             // Fetch active Form templates  
             .addCase(fetchActiveFormTemplates.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchActiveFormTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.forms = action.payload
            })
            .addCase(fetchActiveFormTemplates.rejected, (state, action) => {
                state.status = 'failed'
            })
            // Fetch form details  
            .addCase(fetchFormDetails.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchFormDetails.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.form = action.payload
            })
            .addCase(fetchFormDetails.rejected, (state, action) => {
                state.status = 'failed'
            })
    }
})

export const getFormDetails = (state) => state.forms.form
export const getAllForms = (state) => state.forms.forms
export const getAllSubforms = (state) => state.forms.subforms
export const getFormsStatus = (state) => state.forms.status
export const getFormsError = (state) => state.forms.error

// export const selectUserClinicById = (state, clinicId) => 
//         state.users.clinics.find(clinic => clinic._id == clinicId)

export const { updateForm, resetForm } = formSlice.actions;


export default formSlice.reducer