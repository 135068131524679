import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import { 
    Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, 
    OutlinedInput, 
    Select, TextField, Typography, useTheme
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray  } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { getFormDetails, updateForm } from '../../../../redux/slices/formSlice';
import BasicModal from '../../../../components/ui/modal/BasicModal';
import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';
import CustomButton from '../../../../components/ui/CustomButton';



const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    label: Yup.string().required("Label is required."),
    choices: Yup.array().of(
        Yup.object().shape({
      text: Yup.string().required('Text is required'),
      value: Yup.string().required('Value is required'),
    })
  ),
  // newChoiceText: Yup.string().required("Item name is required."),
  newChoiceText: Yup.string(),
  defaultValue: Yup.string(),
  startWithNewLine: Yup.boolean(),
  isRequired: Yup.boolean(),
})

const RadioGroup = ({ openModal, setOpenModal, data, updateRadioGroupField }) => {
    const theme = useTheme()
    const [newChoiceError, setNewChoiceError] = useState(false)
    
    const initialValues = {
      label: data?.title || '',
      choices: data?.choices || [],
      newChoiceText: '',
      defaultValue: data?.defaultValue || "",
      startWithNewLine: data?.startWithNewLine ?? true,
      isRequired: data?.isRequired || false
    }

    const submitHandler = async (values, { resetForm }) => {
      if(values?.choices?.length === 0) {
        setNewChoiceError(true)
        return
      }
      updateRadioGroupField([{
        name: data?.name || uuidv4(),
        "type": "radiogroup",
        "title": values?.label,
        "choices": values?.choices,
        "defaultValue": values?.defaultValue,
        "colCount": 0,
        "startWithNewLine": values?.startWithNewLine,
        "isRequired": values?.isRequired
      }])
      resetForm({ values: '' })
      setOpenModal(false)
    }

    const handleAddChoice = (values, push, setFieldValue) => {
      setFieldValue('newChoiceText', values.newChoiceText.trim());
      if(values?.newChoiceText?.trim() !== "") {
        const newChoice = {
                text: values.newChoiceText.trim(),
                // value: values.newChoiceText.trim().toLowerCase().replace(/\s+/g, '-'),
                value: uuidv4(),
              };
              push(newChoice);
              setNewChoiceError(false)
              setFieldValue('newChoiceText', '');
      }
    };
  
    const handleRemoveChoice = (index, remove) => {
      remove(index);
    };

    const AddFormContent = () => (
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, handleChange, handleBlur, errors, touched, setFieldValue, validateForm }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="label"
                    label="Label"
                    variant="outlined"
                    fullWidth
                    error={errors.label && touched.label}
                    helperText={touched.label && errors.label}
                  />
                </Grid>

                {/* Type */}
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Typography variant="display2" fontWeight="bold">
                      Add Items
                    </Typography>
                  </Box>
                <FieldArray name="choices">
                  {({ push, remove }) => (
                    <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} gap={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="New Choice"
                          // fullWidth
                          name="newChoiceText"
                          value={values.newChoiceText}
                          onChange={handleChange}
                          // onBlur={validateForm}
                          size="small"
                          // error={touched.newChoiceText && !!errors.newChoiceText}
                          // helperText={touched.newChoiceText && errors.newChoiceText}
                        />
                        {newChoiceError && <FormHelperText error>Item name is required</FormHelperText>}
                      </FormControl>
                        <Button
                          size="small"
                          onClick={() => {
                            if(!values?.newChoiceText) {
                              setNewChoiceError(true)
                            } else {
                              handleAddChoice(values, push, setFieldValue) 
                            }
                          }}
                        >
                          Add Item
                        </Button>
                      </Box>
                      {values.choices.map((choice, index) => (
                        <Box key={index} display="flex" alignItems="center" mb={2} gap={1}>
                          <TextField
                            label={`Choice ${index + 1}`}
                            fullWidth
                            name={`choices[${index}].text`}
                            value={choice.text}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            error={touched.choices && touched.choices[index]?.text && !!errors.choices?.[index]?.text}
                            helperText={touched.choices && touched.choices[index]?.text && errors.choices?.[index]?.text}
                          />
                          <Button
                            onClick={() => handleRemoveChoice(index, remove)}
                            size="small"
                            sx={{
                              color: theme.palette.custom.red.light
                            }}
                          >
                            Remove
                          </Button>
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
                </Grid>
                
            {/* Default Value */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                  <InputLabel htmlFor="Default">Default</InputLabel>
                  <Field
                    input={<OutlinedInput label="Default" />}
                    as={Select}
                    name="defaultValue"
                    variant="outlined"
                    fullWidth
                    error={errors.defaultValue && touched.defaultValue}
                    helperText={touched.defaultValue && errors.defaultValue}
                  >
                    <MenuItem value="">Remove default value</MenuItem>
                    {values?.choices?.map((choice, index) => (
                      <MenuItem key={index} value={choice?.value}>{choice?.text}</MenuItem>
                    ))}
                  </Field>
              </FormControl>
            </Grid>


                {/* New line */}
               <Grid item xs={12} md={6}>
                <Field name="startWithNewLine">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox checked={values.startWithNewLine} {...field} />}
                          label="New Line"
                        />
                        {form.errors.startWithNewLine && form.touched.startWithNewLine && (
                          <FormHelperText error>{form.errors.startWithNewLine}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

                {/* Required */}
               <Grid item xs={12} md={6}>
                <Field name="isRequired">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox  checked={values.isRequired} {...field} />}
                          label="Required"
                        />
                        {form.errors.isRequired && form.touched.isRequired && (
                          <FormHelperText error>{form.errors.isRequired}</FormHelperText>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

              </Grid>

              <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                <CustomButton 
                    title="Cancel" 
                    onClickHandler={() => setOpenModal(false)}
                    variant="outlined"  
                    color={theme.palette.custom.ashWhite.light}
                  />
                  <CustomSubmitButton 
                    title="Update"
                  />
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )

    
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1} ml={1}>
              <RadioButtonCheckedIcon color="custom.gray" />
              <Typography>Radio Group</Typography>
          </Box>
          <IconButton 
              // sx={{ backgroundColor: theme.palette.custom.gray.light, p: 0.5 }}
              onClick={() => setOpenModal(true)}
          >
              <AddIcon color="primary" />
          </IconButton>
      </Box>
      <BasicModal 
          title="Update Radio Group Properties"
          open={openModal}
          onClose={() => setOpenModal(false)}
          content={AddFormContent}
      />
    </>

  )
}

export default RadioGroup