import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'survey-core/defaultV2.min.css'
// import "survey-core/defaultV2.css";
import { Model, Serializer } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { v4 as uuidv4 } from 'uuid'


import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'

import { FieldsList } from '../../../features/forms/newForm'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormDetails, fetchFormTemplates, getFormDetails, getFormsStatus, updateForm } from '../../../redux/slices/formSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { FORM_TEMPLATES_URL } from '../../../constants/apis';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../../redux/slices/authSlice';
import axios from 'axios';
import CustomButton from '../../../components/ui/CustomButton';


const Preview = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    let form = useSelector(getFormDetails)
    const formStatus = useSelector(getFormsStatus) 
    const formError = useSelector(getFormsStatus) 

    const survey = new Model(form)
    const [pageNo, setPageNo] = React.useState(survey.currentPageNo);
    const [isRunning, setIsRunning] = React.useState(true);

    useEffect(() => {
        if(!form)
            dispatch(fetchFormDetails(id))
    }, [])

    // Add properties
    survey.emptySurveyText = "Please add components to get started."
    survey.completeText = "End Preview"
    survey.validationEnabled = false
    survey.showQuestionNumbers = false
    survey.showNavigationButtons = true
    survey.showCompletedPage = false
    survey.showTOC = true
    survey.tocLocation = "right"

    survey.onUpdateQuestionCssClasses.add(function(_, options) {
        const classes = options.cssClasses;
        if (options.question.getType() === "html") {
            classes.root += " survey-html-question"
        }
    });
    
    survey.onUpdatePanelCssClasses.add(function(_, options) {
      const classes = options.cssClasses;
      if(options.panel.getQuestionTitleLocation() === "hidden") {
          classes.panel.title = "survey-html-panel-title";
        classes.panel.content = "survey-html-panel";
      }
    });

    survey.onComplete.add(async function (sender, options) {
        navigate(`/admin/form-templates/design/${id}`)
    })

      // Navigations 
      survey.onCurrentPageChanged.add((_, options) => {
        setPageNo(options.newCurrentPage.visibleIndex);
    });
    survey.onStarted.add(() => { setIsRunning(true); } );
    survey.onComplete.add(() => { setIsRunning(false); });

    const prevPage = () => { survey.prevPage(); };
        const nextPage = () => { survey.nextPage(); };
        const endSurvey = () => { survey.completeLastPage(); };

    const renderButton = (text, func, canRender) => {
        if (!canRender) return undefined;
        return (
            <button className="navigation-button" onClick={func}>
                {text}
            </button>
        );
    };

    const renderExternalNavigation = () => {
        if (!isRunning) return undefined;
        // const progressText = "Page " + (pageNo + 1) + " of " + survey.visiblePages.length;
        // const progressSpan = <span className="navigation-text">{progressText}</span>;
        return (
            <div className="navigation-block">
                {/* {PageSelector} */}
                <div className="navigation-progress-container">
                    <div className="navigation-buttons-container">
                        {renderButton('Previous Page', prevPage, pageNo !== 0 || survey.currentPageNo !== 0)}
                        {renderButton('Next Page', nextPage, pageNo !== survey.visiblePages.length - 1)}
                        {/* {renderButton('Next Page', nextPage, true)} */}
                        {/* {renderButton('Complete', endSurvey, pageNo === survey.visiblePages.length - 1)} */}
                    </div>
                    {/* <div className="navigation-text-container">
                        {progressSpan}
                    </div> */}
                </div>
            </div>
        );
    }

  return (
        <Container maxWidth="xl" sx={{ mt: 1, mb: 24 }}>
                <PageHeader>
                    <PageHeading heading="Form Preview" />
                </PageHeader>
                <Grid container>
                    <Grid item xs={12}>
                        <ContentContainer>
                                {/* {survey.visiblePages.length > 1 
                                  ? renderExternalNavigation()
                                  : null
                                } */}
                            <Survey model={survey}></Survey>
                             {/* Action Buttons */}
                             {/* <Box display="flex" justifyContent="flex-end" gap={2} mt={4} mr={2}>
                                <CustomButton 
                                    title="Close Preview" 
                                    onClickHandler={endSurvey} 
                                />
                            </Box> */}
                        </ContentContainer>
                    </Grid>
                </Grid>
                
                <ToastContainer />
        </Container>
  )
}

export default Preview
