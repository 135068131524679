import React, { useEffect, useRef, useState } from 'react'
import { Box, Avatar} from '@mui/material'
import CustomButton from '../../../components/ui/CustomButton'

const UploadImage = ({ title="Upload Picture", image,  onImageChange }) => {
  const fileInputRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    if(!image) {
      setSelectedFile(null)
    }
  }, [image])

  const handleButtonClick = () => {
    fileInputRef.current.click();
  }

  const uploadFileHandler = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]

    // Display the selected file as an Avatar
     if (file) {
      onImageChange(file)
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedFile(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  
  return (
    <Box display="flex" alignItems="center" gap={2} mb={4}>
      <Avatar src={selectedFile} />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={uploadFileHandler}
      />
      <CustomButton 
        title={title}
        onClickHandler={handleButtonClick}
        disabled={uploading}
      />
    </Box>
  )
}

export default UploadImage