import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'survey-core/defaultV2.min.css'
// import "survey-core/defaultV2.css";
import { Model, Serializer } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { v4 as uuidv4 } from 'uuid'


import ContentContainer from '../../components/ui/ContentContainer'
import PageHeader from '../../components/ui/PageHeader'
import PageHeading from '../../components/ui/PageHeading'
import BackdropLoader from '../../components/ui/BackdropLoader'

import { FieldsList } from '../../features/forms/newForm'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormDetails, fetchFormTemplates, getFormDetails, getFormsStatus, updateForm } from '../../redux/slices/formSlice';
import { fetchPatientFormReports } from '../../redux/slices/patientSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { FORM_TEMPLATES_URL, PATIENTS_URL } from '../../constants/apis';
import { ToastContainer, toast } from 'react-toastify';
import { getToken } from '../../redux/slices/authSlice';
import axios from 'axios';
import CustomButton from '../../components/ui/CustomButton';

const typesWithArrayDefaultValue = ["tagbox"]

const View = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [addRequestStatus, setAddRequestStatus] = useState("idle")
    
    // const [json, setJson] = useState(null)
    const accessToken = useSelector(getToken)
    
    let form = useSelector(getFormDetails)
    const formStatus = useSelector(getFormsStatus) 
    const formError = useSelector(getFormsStatus) 

    const [survey, setSurvey] = React.useState(new Model([]));
    const [pageNo, setPageNo] = React.useState(survey?.currentPageNo || 0);
    const [isRunning, setIsRunning] = React.useState(true);

    useEffect(() => {
      if(id)
          dispatch(fetchFormDetails(id))
  }, [id])

  useEffect(() => {
    if(form) {
       setSurvey(new Model(form))
    }
}, [form])

    // const survey = new Model(form)
    // Add properties
    survey.emptySurveyText = "Please add components to get started."
    // survey.completeText = "Complete"
    survey.questionErrorLocation = "bottom"
    survey.showCompletedPage = false
    survey.showNavigationButtons = false
    survey.showTOC = true
    survey.tocLocation = "right"
    survey.showQuestionNumbers = false

    survey.onStarted.add(() => { setIsRunning(true); } );
    survey.onComplete.add(() => { setIsRunning(false); });

    const prevPage = () => { survey.prevPage(); };
    const nextPage = () => { survey.nextPage(); };

  const renderButton = (text, func, canRender) => {
      if (!canRender) return undefined;
      return (
          <button className="navigation-button" onClick={func}>
              {text}
          </button>
      );
  };

  const renderExternalNavigation = () => {
    if (!isRunning) return undefined;
    // const progressText = "Page " + (pageNo + 1) + " of " + survey.visiblePages.length;
    // const progressSpan = <span className="navigation-text">{progressText}</span>;
    return (
        <div className="navigation-block">
            {/* {PageSelector} */}
            <div className="navigation-progress-container">
                <div className="navigation-buttons-container">
                    {renderButton('Previous Page', prevPage, pageNo !== 0 || survey.currentPageNo !== 0)}
                    {renderButton('Next Page', nextPage, pageNo !== survey.visiblePages.length - 1)}
                    {/* {renderButton('Next Page', nextPage, true)} */}
                    {/* {renderButton('Complete', endSurvey, pageNo === survey.visiblePages.length - 1)} */}
                </div>
            </div>
        </div>
    );
}

    survey.onUpdateQuestionCssClasses.add(function(_, options) {
      const classes = options.cssClasses;
      if (options.question.getType() === "html") {
          classes.root += " survey-html-question"
      }
  });
  
  survey.onUpdatePanelCssClasses.add(function(_, options) {
    const classes = options.cssClasses;
      if(options.panel.getQuestionTitleLocation() === "hidden") {
        classes.panel.title = "survey-html-panel-title";
        classes.panel.content = "survey-html-panel ";
      }
  });

    survey.onComplete.add(async function (sender, options) {
        // navigate(`/doctor/form-templates/`)

    })

      // Navigations 
      survey.onCurrentPageChanged.add((_, options) => {
        setPageNo(options.newCurrentPage.visibleIndex);
    });
    survey.onStarted.add(() => { setIsRunning(true); } );
    survey.onComplete.add(() => { setIsRunning(false); });


    const generateReport = async () => {
      for (const key in survey.data) {
        const currentQuestion = survey.getQuestionByName(key);
        if (!!currentQuestion) {
            // Access the specific question
            var question = survey.getQuestionByName(currentQuestion?.name);
        
            // Update the properties of the question
            question.defaultValue = currentQuestion?.displayValue
        }
    }

    const pages = survey.toJSON()?.pages
  
      try {
        setAddRequestStatus('pending')
        const res = await axios.put(`${PATIENTS_URL}/form-reports`, 
          {
            formId: id,
            pages
           }, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
        }) 
        if(res.status === 200) {
            toast.success(res?.data?.message)
            dispatch(fetchFormDetails(form?._id))
            dispatch(fetchPatientFormReports())
            setTimeout(() => navigate(`/patients/profile/${form?.patient}?activeTab=${2}`), 2000)
        } else {
          toast.error("Something went wrong, please try again later.")
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
      } finally {
        setAddRequestStatus('idle')
      }
    }

    const saveFormAsDraft = async () => {
      // Log the updated question to the console

      for (const key in survey.data) {
            const currentQuestion = survey.getQuestionByName(key);
            if (!!currentQuestion) {
                // Access the specific question
                var question = survey.getQuestionByName(currentQuestion?.name);
            
                // Update the properties of the question
                question.defaultValue = currentQuestion?.displayValue
            }
        }

      const pages = survey.toJSON()?.pages

      try {
      setAddRequestStatus('pending')
      const res = await axios.patch(`${FORM_TEMPLATES_URL}/update-form`, 
          {
              id,
              pages,
              status: "draft"
          }, {
          headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
          },
      }) 
      if(res.status === 200) {
          toast.success("Form saved successfully.")
      } else {
          toast.error("Something went wrong, please try again later.")
      }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong, please try again later.")
      } finally {
      setAddRequestStatus('idle')
      }
  }

  return (
        <Container maxWidth="xl" sx={{ mt: 1, mb: 24 }}>
                {/* <PageHeader>
                    <PageHeading heading="Form View" />
                </PageHeader> */}
                {(formStatus === "loading" || addRequestStatus === "pending") 
                    ? <BackdropLoader />
                    :
                        <Grid container>
                            <Grid item xs={12}>
                                    {survey.visiblePages.length > 1 
                                      ? renderExternalNavigation()
                                      : null
                                    }
                                    <Survey model={survey}></Survey>

                                    {survey.visiblePages.length > 1 
                                      ? renderExternalNavigation()
                                      : null
                                    }
                                     {/* Action Buttons */}
                                  <Box display="flex" justifyContent="flex-end" gap={2} mt={4} mr={2}>
                                      <CustomButton 
                                          title="Save Draft" 
                                          variant="outlined"
                                          onClickHandler={saveFormAsDraft} 
                                          disabled={addRequestStatus === "pending"}
                                      />
                                      <CustomButton 
                                          title="Generate Report" 
                                          onClickHandler={generateReport} 
                                          disabled={addRequestStatus === "pending"}
                                      />
                                    </Box>
                            </Grid>
                        </Grid>
                }
                <ToastContainer />
        </Container>
  )
}

export default View
