import React from 'react'
import { Avatar, Box, Divider, Typography } from '@mui/material'

import ContentContainer from '../../../../components/ui/ContentContainer'
import TableButton from '../../../../components/ui/table/TableButton'
import ProfileField from '../../../../components/ui/ProfileField'
import { IMAGES } from '../../../../assets'
import ROLES from '../../../../constants/roles';

const UserProfileOverview = ({ user, type="coordinator" }) => {
  const getStatusType = (userStatus) => {
    return userStatus === "active" ? "success" : "disabled"
  }

  return (
    <ContentContainer>
        <Box px={1}>
          <Box display="flex" justifyContent="center" my={2}>
            <Avatar 
              sx={{ width: 80, height: 80 }} 
              src={user?.picture} 
            />
          </Box>
          <Box mb={3}>
            <Typography fontWeight="bold" variant='h6' textAlign="center">
              {user?.firstName} {user?.lastName}
            </Typography>
            {type === ROLES.DOCTOR 
              ? (
                <Typography textAlign="center" color="secondary.light" gutterBottom>
                  {user?.credentials}
                </Typography>
              ) : null
            }
          </Box>
          <ProfileField 
            label={type === ROLES.DOCTOR ? "Doctor ID" : "Coordinator ID" } 
            value={`${type === ROLES.DOCTOR ? "D" : "C"}-${user?.userId}`} 
          />
          <ProfileField label="Username" value={user?.username} />
          <ProfileField label="Clinics" value={user?.clinics?.length} />
          <ProfileField label="Gender" value={user?.gender || "-"} />
          <ProfileField label="Email" value={user?.emailDetails?.email} />
          <ProfileField label="Phone" value={user?.phone} />
          <ProfileField label="Address" value={user?.address?.street} />
          <ProfileField label="City" value={user?.address?.city} />
          <ProfileField label="Joining Date" value={user?.createdAt.substring(0, 10)} />
          <Box display="flex" justifyContent="space-between" my={1.5}>
            <Typography>
                Status
            </Typography>
            <TableButton 
                type={getStatusType(user?.status)}
            >
              {user?.status}
            </TableButton>
          </Box>
        </Box>
    </ContentContainer>
  )
}

export default UserProfileOverview