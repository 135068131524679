import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Box, TextField, Select, Container, FormControl,
   Grid, InputLabel, MenuItem, Typography
  } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'


import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeader from '../../../components/ui/PageHeader'
import PageHeading from '../../../components/ui/PageHeading'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'
import BackDropLoader from '../../../components/ui/BackdropLoader'

import { AddSubformModal, DeleteFormModal } from '../../../features/forms';
import { fetchSubformTemplates, getAllSubforms, getFormsError, getFormsStatus } from '../../../redux/slices/formSlice';

import convertFirstCharToUpperCase from '../../../libs/convertFirstCharToUpperCase'
import DeleteSubformModal from '../../../features/forms/DeleteSubformModal';

const SubformTemplatesList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [openAddFormModal, setOpenAddFormModal] = useState(false)

   const subforms = useSelector(getAllSubforms)
   const subformsStatus = useSelector(getFormsStatus)
   const subformsError = useSelector(getFormsError)

   useEffect(() => {
    if(subformsStatus !== "loading" && subforms.length === 0) {
      dispatch(fetchSubformTemplates())
    }
}, [])

const onDeleteHandler = (id) => {
  setDeleteItemId(id)
  setOpenDeleteModal(true)
}

const onAddFormHandler = () => {
  setOpenAddFormModal(true)
}

const closeModal = (type) => {
  if (type === "add_modal") {
    setOpenAddFormModal(false)
  } else{
    setOpenDeleteModal(false)
    setDeleteItemId(null)
  }
}

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        maxWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'title',
      headerName: 'Title',
      maxWidth: 280,
      flex: 1,
      valueGetter: (params) => params.row.title
    },
    {
      field: 'category',
      headerName: 'Catgegory',
      // minWidth: 100,
      flex: 1,
      valueGetter: (params) => convertFirstCharToUpperCase(params.row?.category)
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 120,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
    },       
      {
        field: 'status',
        headerName: 'Status',
        width: 140,
        filterable: false,
        renderCell: (params) => {
          let status, disabled=false
          if (params.row.status === "active") 
              status = "success"
          else 
              status = "disabled"
          return (
            <TableButton 
              variant="outlined" 
              size="small"
              type={status}
              disabled={disabled}
            >
                {params?.row?.status}
            </TableButton>
          )
        }
      },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          minWidth: 160,
          type: 'actions',
          renderCell: (params) => (
            <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
            </Box>
          ),
        },
    ],
    [rowId]
  )

  return (
    <>
    { subformsStatus === 'loading' 
      ? <BackDropLoader /> 
      : (
        <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
            <PageHeader>
              <PageHeading heading="Subform Templates List" text="List of all subform templates" />
              <CustomButton title="Add Template" onClickHandler={onAddFormHandler} />
            </PageHeader>

          <ContentContainer>
            {/* Templates table */}
            <Table 
              heightPrecentage="40vh"
              columns={columns} 
              rows={subforms}
              setRowId={setRowId}
              basePathForRedirect={"/admin/form-templates/subforms/design"}
            />
          </ContentContainer>
        </Container>
      )
    }
      <AddSubformModal 
        open={openAddFormModal}
        closeModal={closeModal}
      />
      <DeleteSubformModal 
        formId={deleteItemId}
        open={openDeleteModal}
        closeModal={closeModal}
      />
      <ToastContainer />
    </>
  )
}

export default SubformTemplatesList
