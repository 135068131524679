import { Backdrop, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const BackdropLoader = () => {
  const theme = useTheme()
  return (
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
    <CircularProgress sx={{ color: theme.palette.primary.light }} />
   </Backdrop>
  )
}

export default BackdropLoader