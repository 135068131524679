import React, { useState } from 'react'
import { Box, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import CustomSubmitButton from '../../../../components/ui/CustomSubmitButton';

import { HOSPITALS_URL } from '../../../../constants/apis'

import { fetchHospitals } from '../../../../redux/slices/hospitalSlice'
import { getToken } from '../../../../redux/slices/authSlice'
import { cities } from '../../../../constants/data';


const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  name: Yup.string().required('Hospital name is required')
  .matches(/^[a-zA-Z0-9\s]*$/, 'Invalid hospital name'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .matches(/^[\d+]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
  street: Yup.string()
    .max(100, 'Max 100 characters are allowed'),
  city: Yup.string().required('City is required'),
  otherCity: Yup.string()
    .when("city", {
      is: (val) => val.includes("Other"),
      then: () => Yup.string()
                  .matches(/^[a-zA-Z\s]*$/, 'Invalid city name')
                  .required("City is required")
    }),
})



const ProfileData = ({ hospital }) => {
  const dispatch = useDispatch()
  const accessToken = useSelector(getToken)

  const initialValues = {
    name: hospital?.name || "",
    email: hospital?.email || "",
    phone: hospital?.phone || "",
    street: hospital?.address[0]?.street || "",
    city: cities.includes(hospital?.address[0]?.city) ? hospital?.address[0]?.city : "Other",
    otherCity: !cities.includes(hospital?.address[0]?.city) ? hospital?.address[0]?.city : "",
    status: hospital?.status || "",
  }

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const submitHandler = async (values, { resetForm }) => {
    try {
      setUpdateRequestStatus("pending")
      const res = await axios.patch(`${HOSPITALS_URL}/${hospital?._id}`, {...values }, {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
      })
      if(res.status === 200) {
        dispatch(fetchHospitals())
        toast.success("Hospital updated successfully.")
        // resetForm({ values: '' })
      } else {
        toast.error("Something went wrong, please try again later.")
      } 
    } catch (err) {
      toast.error(err.response.data.message)
    } finally {
      setUpdateRequestStatus("idle")
    }
  }

  return (
    <>
        {/* Form Content */}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="street"
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  error={errors.street && touched.street}
                  helperText={touched.street && errors.street}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="city">City</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="City" />} 
                  name="city"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("city", e.target.value)
                    setFieldValue("otherCity", "")
                  }}
                  error={errors.city && touched.city}
                  helperText={touched.city && errors.city}
                >
                    {cities?.map((city, index) => (
                        <MenuItem key={index} value={city}>{city}</MenuItem>
                    ))}
                </Field>
                </FormControl>
              </Grid> 
              {values?.city === "Other"
                ? (
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="otherCity"
                      label="Other City"
                      variant="outlined"
                      fullWidth
                      error={errors.otherCity && touched.otherCity}
                      helperText={touched.otherCity && errors.otherCity}
                    />
                  </Grid> 
                ) : null
              }     
              <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Field
                  as={Select}
                  input={<OutlinedInput label="Status" />} 
                  name="status"
                  variant="outlined"
                  fullWidth
                  error={errors.status && touched.status}
                  helperText={touched.status && errors.status}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Field>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <CustomSubmitButton 
                    title="Update"
                    disabled={updateRequestStatus === "pending"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </>
  )
}

export default ProfileData