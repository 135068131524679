import { Button, Typography, Card, CardContent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"

const StyledCard = styled(Card)({
    display: "flex",
    flexDirection: "column",
    width: "368px",
    height: "60vh",
    margin: "40px auto",
    justifyContent: "center"
})

const UnAuthorized = () => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
            <StyledCard elevation={3}>
                    <Typography 
                        variant="h5" 
                        align="center"
                    >
                        Error 404: Page Not Found
                    </Typography>
                    <CardContent>
                            <Button 
                                variant="contained" 
                                fullWidth 
                                onClick={goBack}
                            >
                                Go Back
                            </Button>
                    </CardContent>
            </StyledCard>       
    )
}

export default UnAuthorized