import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, Container, Grid, IconButton, Typography, useTheme  } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import ContentContainer from '../../../components/ui/ContentContainer'
import PageHeading from '../../../components/ui/PageHeading'
import BackdropLoader from '../../../components/ui/BackdropLoader'
import CustomButton from '../../../components/ui/CustomButton'
import Table from '../../../components/ui/table/Table'
import TableButton from '../../../components/ui/table/TableButton'
import TableIconButton from '../../../components/ui/table/TableIconButton'
import BasicModal from '../../../components/ui/modal/BasicModal'
import { fetchClinics, getClinicsError, getClinicsStatus, selectAllClinics } from '../../../redux/slices/clinicSlice';
import PageHeader from '../../../components/ui/PageHeader'
import { ToastContainer, toast } from 'react-toastify'
import DeleteClinicModal from '../../../features/admin/clinics/DeleteClinicModal';

const ClinicsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
   const [rowId, setRowId] = useState(null)
   const [deleteItemId, setDeleteItemId] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [addRequestStatus, setAddRequestStatus] = useState('idle')

   const clinics = useSelector(selectAllClinics)
   const clinicsStatus = useSelector(getClinicsStatus)
   const clinicsError = useSelector(getClinicsError)
  
  useEffect(() => {
      if (clinicsStatus !== "loading" && clinics.length === 0)
          dispatch(fetchClinics())
  }, [])

  useEffect(() => {
    if(clinicsError)
      toast.error(clinicsError)
  }, [clinicsError])

  const getStatusType = (clinicStatus) => {
    return clinicStatus === "active" ? "success" : "disabled"
  }

  const closeDeleteModal = () => {
    setOpenDeleteModal(false)
    setDeleteItemId(null)
  }

  const onDeleteHandler = (id) => {
    setDeleteItemId(id)
    setOpenDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        minWidth: 80,
        valueGetter: (params) => params.row.id,
        sortable: false,
      },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 140,
      valueGetter: (params) => params.row.name
    },
    {
      field: 'hospital',
      headerName: 'Hospital',
      flex: 1,
      minWidth: 160,
      valueGetter: (params) => params.row.hospital.name
    },
    {
      field: 'doctorCount',
      headerName: 'Doctors',
      flex: 1,
      minWidth: 20,
      valueGetter: (params) => params.row.doctorCount
    },
    {
      field: 'coordinatorCount',
      headerName: 'Coordinators',
      flex: 1,
      minWidth: 20,
      valueGetter: (params) => params.row.coordinatorCount
    },
    {
      field: 'patientCount',
      headerName: 'Patients',
      flex: 1,
      minWidth: 20,
      valueGetter: (params) => params.row.patientCount
    },
    {
      field: 'createdAt',
      headerName: 'Added on',
      flex: 1,
      minWidth: 140,
      // sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.createdAt?.toString().substring(0, 10)
      // moment(params.row.appointmentDate).format('YYYY-MM-DD')
      },       
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 100,
        filterable: false,
        renderCell: (params) => {
          return (
            <TableButton 
              type={getStatusType(params.row.status)}
            >
                {params.row.status}
            </TableButton>
          )
        }
      },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          minWidth: 160,
          type: 'actions',
          renderCell: (params) => (
            <Box display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                {/* <TableIconButton type="view" onClick={() => navigate(`/admin/clinics/profile/${params.row._id}`)} /> */}
                <TableIconButton type="delete" onClick={() => onDeleteHandler(params.row._id)} />
            </Box>
          ),
        },
    ],
    [rowId]
  );

  return (
    <>
      { clinicsStatus === 'loading' 
        ? <BackdropLoader /> 
        : (
          <Container sx={{ mt: 1 }} maxWidth="xl" >
            {/* Page Heading Bar */}
            <PageHeader>
              <PageHeading heading="Clinics List" text="List of all Clinics" />
              <CustomButton title="Add Clinic" onClickHandler={() => navigate("/admin/clinics/registration") } />
            </PageHeader>
            
            <ContentContainer>
              {/* Patients table */}
              <Table 
                heightPrecentage="40vh"
                columns={columns} 
                rows={clinics}
                setRowId={setRowId}
                basePathForRedirect={"/admin/clinics/profile"}
              />
            </ContentContainer>
          </Container>
        )
      } 
      <DeleteClinicModal 
        clinicId={deleteItemId}
        open={openDeleteModal}
        closeModal={closeDeleteModal}
      />
      <ToastContainer />
    </>
   
  )
}

export default ClinicsList
